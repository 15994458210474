import { StrictMode } from "react";
import ReactDOM from "react-dom";

/**
 * Imports the react redux provider
 */
import { Provider } from "react-redux";

/**
 * Imports App
 */
import { App } from "./App";

/**
 * Imports Sentry
 */
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

/**
 * Imports the redux store
 */
import { store } from "./redux";

/**
 * Gets env variables for sentry
 */
const {
  REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_SAMPLE_RATE,
  REACT_APP_SENTRY_DEBUG,
} = process.env;

/**
 * Initializes the error handling (Sentry)
 */
Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  maxBreadcrumbs: 25,
  debug: REACT_APP_SENTRY_DEBUG as unknown as boolean,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(REACT_APP_SENTRY_SAMPLE_RATE as string),
  environment: REACT_APP_SENTRY_ENVIRONMENT,
});

/**
 * Handles injecting the App into the dom
 */
ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById("root"),
);
