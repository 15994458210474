/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetWorkOrderApiCall,
  GetWorkOrderResponse
} from "./GetWorkOrder.types";

/**
 * Handles making the api call
 */
export const GetWorkOrder: GetWorkOrderApiCall =
  (api) => async (workOrderId, onSuccess, onError) => {
    try {
      const response = await api.get<GetWorkOrderResponse>(
        `/work-orders/${workOrderId}`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
