import { useState } from "react";

/**
 * Imports types
 */
import {
  FilterModel,
  RequestOnError,
  GenerateReport,
  UseReportsProps,
  CreateReportBody,
  CreateReportParams,
  CreateReportResponse,
  CreateReportOnSuccess,
} from "./useReports.types";

/**
 * Imports hooks
 */
import {
  useApi,
  useSearch,
  useActions,
  useDebounce,
  useFilterModelsUtils,
} from "../index";

/**
 * Handles the logic for the generating reports
 */
export const useReports = (props: UseReportsProps) => {
  const { format, type, order, orderBy, onSuccess } = props;

  /**
   * Initializes the trigger search flag
   */
  const [triggerSearch, setTriggerSearch] = useState(false);

  /**
   * Initializes the report
   */
  const [report, setReport] = useState<CreateReportResponse["data"]>();

  /**
   * Initializes the loading
   */
  const [loading, setLoading] = useState(true);

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the create report api call
   */
  const { createReport } = apiCalls;

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets the filter model utility hook
   */
  const { getFilterByField, addOrReplaceFilter, createFilter, removeFilter } =
    useFilterModelsUtils();

  /**
   * Gets the filter models from the search provider
   */
  const { defaultFilters, activeFilters, setActiveFilters } = useSearch();

  /**
   * Checks if the report is totalized
   */
  const isReportTotalized = (filters: FilterModel[]) => {
    const totalizedFilter = getFilterByField("totalization", filters);
    return totalizedFilter ? (totalizedFilter.selected as boolean) : false;
  };

  /**
   * Handles creating a report based on search value
   */
  const handleSearch = (searchValue: string, totalized?: boolean) => {
    /**
     * Defines the search filter model
     */
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });

    /**
     * Updates the active filters
     */
    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    generateReport(filterModels, {
      reportType: totalized ? "company-totaled" : type,
      reportFormat: "normal",
    });
    setActiveFilters(filterModels);
  };

  /**
   * Handles deleting a filter
   */
  const deleteFilter = (filter: FilterModel) => {
    const updatedFilters = removeFilter(filter, activeFilters);
    setActiveFilters(updatedFilters);

    debounce(() => {
      setTriggerSearch(true);
    }, 2000);
  };

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    setLoading(true);
    setActiveFilters(defaultFilters);
    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  /**
   * Handles generating a report
   */
  const generateReport: GenerateReport = async (filters, params) => {
    setLoading(true);

    /**
     * Defines the request params
     */
    const reqParams: CreateReportParams = {
      reportType: type,
      reportFormat: format,
    };

    /**
     * Defines the request body
     */
    const reqBody: CreateReportBody = {
      models: filters.filter((model) => !model.displayOnly),
      order_by: orderBy,
      order_dir: order,
    };

    /**
     * Defines the api call success callback
     */
    const onRequestSuccess: CreateReportOnSuccess = (response) => {
      if (response && response.data) {
        const { data } = response;

        setReport(data);
        setLoading(false);

        if (onSuccess) {
          onSuccess(
            Array.isArray(data.reportData) ? "company-totaled" : undefined,
          );
        }
      }
    };

    /**
     * Defines the api call error callback
     */
    const onError: RequestOnError = (error) => {
      setReport(undefined);
      setLoading(false);
      dispatchMessage({
        message: error.errorMessage ? error.errorMessage : "Unknown Error",
        severity: "error",
        autoClose: 10000,
      });
    };

    await createReport(reqBody, params || reqParams, onRequestSuccess, onError);
  };

  return {
    report,
    loading,
    triggerSearch,
    setReport,
    setLoading,
    generateReport,
    setTriggerSearch,
    isReportTotalized,
    handleSearch,
    deleteFilter,
    resetFilters,
  };
};
