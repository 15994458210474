/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import { GetClientApiCall, GetClientResponse } from "./GetClient.types";

/**
 * Handles making the api call
 */
export const GetClient: GetClientApiCall =
  (api) => async (clientId, onSuccess, onError) => {
    try {
      const response = await api.get<GetClientResponse>(`/clients/${clientId}`);

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
