/**
 * Imports the logos
 */
import volubilemLogo from "./imgs/autostop.png";
import dragosLogo from "./imgs/dragos.jpeg";
import imparLogo from "./imgs/impar.png";

/**
 * Imports hooks
 */
import { useAccountSettings } from "../../hooks";

/**
 * Handles the logic for displaying the custom logo
 */
export const useCustomLogo = () => {
  /**
   * Gets user utils
   */
  const { customLogo } = useAccountSettings();

  /**
   * Checks if the account has a custom logo
   */
  const withLogo = !!customLogo?.value;
  const withWatermark = !!customLogo?.options?.watermark;

  /**
   * Gets the logo src
   */
  const getLogoSrc = () => {
    if (withLogo && customLogo) {
      switch (customLogo.value) {
        case "volubilem-logo":
          return "/assets/autostop.png";
        case "dragos-logo":
          return "/assets/dragos.jpeg";
        case "impar-logo":
          return "/assets/impar.png";
        default:
          return "";
      }
    }
  };

  /**
   * Returns the logo class name
   */
  const getLogoClassName = () => {
    if (withLogo && customLogo) {
      switch (customLogo.value) {
        case "volubilem-logo":
          return "VolubilemLogo";
        case "dragos-logo":
          return "DragosLogo";
        case "impar-logo":
          return "ImparLogo";
        default:
          return "";
      }
    }
  };

  return {
    customLogo,
    withLogo,
    withWatermark,
    getLogoSrc,
    getLogoClassName,
  };
};
