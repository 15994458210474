/**
 * External imports
 */
import lodash from "lodash";

/**
 * Imports Material UI components
 */
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";

/**
 * Imports components
 */
import { Typography } from "../Typography";

/**
 * Imports styled components
 */
import { Container } from "./PaymentTypeChip.styles";

/**
 * Imports types
 */
import { PaymentTypeChipProps } from "./PaymentTypeChip.types";
import { PaymentTypeName } from "../../types";

/**
 * Displays the component
 */
export const PaymentTypeChip: React.FC<PaymentTypeChipProps> = (props) => {
  const { paymentType } = props;

  /**
   * Returns the icon for each payment type
   */
  const getPaymentTypeIcon = () => {
    switch (paymentType) {
      case "CARD":
        return <PaymentOutlinedIcon />;
      case "CASH":
        return <AccountBalanceWalletOutlinedIcon />;
      case "FACT":
      case "FACT TERMENI":
        return <ReceiptLongOutlinedIcon />;
      case "FISA":
      case "FISA TERMENI":
        return <ReceiptOutlinedIcon />;
      default:
        return <PaymentsOutlinedIcon />;
    }
  };

  /**
   * Handles formatting the payment type name
   */
  const formatPaymentTypeName = (paymentType: PaymentTypeName | "OTHER") => {
    if (!paymentType) return "";

    const words = paymentType.split(" ");
    if (words.length === 1 && words[0].length > 2) {
      return lodash.upperFirst(lodash.toLower(words[0]));
    }

    if (words.length > 1) {
      let finalWord = "";

      words.forEach((word, index) => {
        finalWord += lodash.upperFirst(lodash.toLower(word));
        if (index !== words.length - 1) {
          finalWord += " ";
        }
      });

      return finalWord;
    }

    return paymentType;
  };

  return (
    <Container className="PaymentTypeChip-root">
      {getPaymentTypeIcon()}
      <Typography variant="caption">
        {formatPaymentTypeName(paymentType)}
      </Typography>
    </Container>
  );
};
