/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetDraftWorkOrderApiCall,
  GetDraftWorkOrderResponse
} from "./GetDraftWorkOrder.types";

/**
 * Handles making the api call
 */
export const GetDraftWorkOrder: GetDraftWorkOrderApiCall =
  (api) => async (draftId, onSuccess, onError) => {
    try {
      const response = await api.get<GetDraftWorkOrderResponse>(
        `/work-orders/${draftId}/draft`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
