/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  SearchAppointmentsApiCall,
  SearchAppointmentsResponse,
} from "./SearchAppointments.types";
import {AxiosRequestConfig} from "axios";

/**
 * Handles making the api call
 */
export const SearchAppointments: SearchAppointmentsApiCall =
  (api) => async (currentYear, weekNumber, onSuccess, onError) => {
    try {
        const config: AxiosRequestConfig = {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
        } as AxiosRequestConfig;

      const response = await api.get<SearchAppointmentsResponse>(
        `/appointments/search/calendar/year/${currentYear}/week/${weekNumber}`,
          config
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
