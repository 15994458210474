import { createSlice } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { ReduxSlice } from "../../features";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { TReportsState } from "./reports.types";
import type { DetailedReportsConfig } from "../../../types";

/**
 * Imports utils
 */
import { preloadDetailsReportsConfig } from "./reports.utils";

const initialState: TReportsState = {
  config: preloadDetailsReportsConfig(),
};

export const reportsSlice = createSlice({
  name: ReduxSlice.Reports,
  initialState,
  reducers: {
    updateReportsConfig: (
      state,
      action: PayloadAction<DetailedReportsConfig>,
    ) => {
      state.config = action.payload;
    },
  },
});

export const reportsActionCreators = reportsSlice.actions;
export const reportsReducer = reportsSlice.reducer;
