import { Fragment } from "react";

/**
 * Imports components
 */
import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";
import { PaymentTypeChip } from "../PaymentTypeChip";

/**
 * Imports styled components
 */
import {
  Text,
  TableCell,
  TextContainer,
  FlexContainer,
  NotFoundWrapper
} from "./SmartTableCell.styles";

/**
 * Imports hooks
 */
import { useTranslation, useUserUtils, useUtils } from "../../hooks";

/**
 * Imports types
 */
import { PaymentTypeName } from "../../types";
import { SmartTableCellProps } from "./SmartTableCell.types";

/**
 * Displays the component
 */
export const SmartTableCell: React.FC<SmartTableCellProps> = (props) => {
  const {
    p,
    pl,
    pr,
    pt,
    pb,
    jsx,
    time,
    text,
    date,
    count,
    align,
    index,
    color,
    manual,
    weight,
    carType,
    children,
    pageSize,
    quantity,
    currency,
    minWidth,
    fontSize,
    pageCount,
    textLimit,
    className,
    dateFormat,
    percentage,
    paymentType,
    placeholder,
    containerClassName
  } = props;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets general utils
   */
  const { toCurrency, toPercentage, toQuantity, formatDate, truncateString } =
    useUtils();

  /**
   * Gets user utils
   */
  const { getCarTypeById } = useUserUtils();

  /**
   * Returns the table cell className
   */
  const getTableCellClassName = () => {
    const baseClass = "SmartTableCell-root";

    return className ? `${baseClass} ${className}` : baseClass;
  };

  /**
   * Returns the table cell align
   */
  const getTableCellAlign = () => align || "left";

  /**
   * Handles formatting the cell text based on flags
   */
  const formatCellText = (text?: string | number) => {
    if (text === "null") return "-";

    /**
     * Manual count - backend pagination
     */
    if (count && manual && pageSize && pageCount && index !== undefined) {
      return pageSize * (pageCount - 1) + index + 1;
    }

    /**
     * Normal count - automatic pagination
     */
    if (count && index !== undefined) return index + 1;

    /**
     * Currency
     */
    if (currency) return toCurrency(Number(text));

    /**
     * Quantity
     */
    if (quantity) return toQuantity(Number(text));

    /**
     * Percentage
     */
    if (percentage) return toPercentage(Number(text));

    /**
     * Empty
     */
    if (!text) return placeholder || "";

    /**
     * Time
     */
    if (time && typeof text === "number") {
      return new Date(text * 1000).toISOString().slice(11, 19);
    }

    /**
     * Date
     */
    if (date && typeof text !== "number") return formatDate(text, dateFormat);

    /**
     * Payment type
     */
    if (paymentType)
      return <PaymentTypeChip paymentType={text as PaymentTypeName} />;

    /**
     * Car type
     */
    if (carType) {
      if (!text) return "-";

      const carTypeData = getCarTypeById(text);
      return carTypeData ? (
        carTypeData.name
      ) : (
        <NotFoundWrapper>{t("NotFound")}</NotFoundWrapper>
      );
    }

    /**
     * Truncated string
     */
    if (textLimit && text && typeof text === "string") {
      if (text.toString().length < textLimit) return text;

      return (
        <TextContainer>
          <Tooltip
            placement="top"
            trigger="onHover"
            content={<Typography variant="caption">{text}</Typography>}
          >
            <Text>{truncateString(text, textLimit)}</Text>
          </Tooltip>
        </TextContainer>
      );
    }

    return text;
  };

  /**
   * Handles getting the table cell content
   */
  const getTableCellContent = () => {
    if (jsx) {
      const className = containerClassName || "SmartTableCell-flexContainer";

      return (
        <Fragment>
          <FlexContainer className={className}>
            {formatCellText(text)}
            {children}
          </FlexContainer>
        </Fragment>
      );
    }

    if (children) return children;

    return formatCellText(text);
  };

  return (
    <TableCell
      align={getTableCellAlign()}
      className={getTableCellClassName()}
      minWidth={minWidth}
      fontSize={fontSize}
      weight={weight}
      color={color}
      p={p}
      pl={pl}
      pr={pr}
      pt={pt}
      pb={pb}
    >
      {getTableCellContent()}
    </TableCell>
  );
};
