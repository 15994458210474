import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import { Product } from "../../types";

/**
 * Defines the Provider values interface
 */
export interface ProviderValues {
  loading: boolean;
  products: Product[];
}

/**
 * Defines the Provider props interface
 */
export interface ProviderProps {
  enableAdmin?: boolean;
  exclude?: string[];
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  loading: false,
  products: [],
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
