/**
 * Imports redux utils
 */
import { useSelector as useReduxSelector } from "react-redux";

/**
 * Imports types
 */
import type { RootState } from "../../redux";
import type { TypedUseSelectorHook } from "react-redux";

/**
 * Defines the Redux state selector hook
 */
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
