import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import { UserTimesheet, FilterModel, TableData, SortOrder } from "../../types";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  orderBy: string;
  loading: boolean;
  pageCount: number;
  orderDir: SortOrder;
  totalTimesheets: number;
  tableRowsLoading: boolean;
  modelsInitialized: boolean;
  timesheets: UserTimesheet[];
  activeFilters: FilterModel[];
  setPageCount: Dispatch<SetStateAction<number>>;
  setTotalTimesheets: Dispatch<SetStateAction<number>>;
  setTimesheets: Dispatch<SetStateAction<UserTimesheet[]>>;
  resetFilters: () => void;
  deleteFilter: (filter: FilterModel) => void;
  handleSearch: (searchValue: string) => void;
  handleSubmit: (filters: FilterModel[]) => void;
  updateTimesheet: (timesheet: UserTimesheet) => void;
  formatActiveFilters: (filters: FilterModel[]) => FilterModel[];
  handleSort: (e: React.MouseEvent<any>, property: keyof TableData) => void;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  timesheets: [],
  pageCount: 0,
  loading: false,
  totalTimesheets: 0,
  orderDir: "desc",
  activeFilters: [],
  orderBy: "created_at",
  tableRowsLoading: false,
  modelsInitialized: false,
  setTimesheets: () => {},
  setPageCount: () => {},
  setTotalTimesheets: () => {},
  handleSort: () => {},
  handleSubmit: () => {},
  resetFilters: () => {},
  deleteFilter: () => {},
  handleSearch: () => {},
  updateTimesheet: () => {},
  handlePageChange: () => {},
  formatActiveFilters: () => []
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
