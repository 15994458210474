/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetGuestOrganizationAppointmentsApiCall,
  GetGuestOrganizationAppointmentsResponse,
} from "./GetGuestOrganizationAppointments.types";

/**
 * Handles making the api call
 */
export const GetGuestOrganizationAppointments: GetGuestOrganizationAppointmentsApiCall =
  (api) => async (params, onSuccess, onError) => {
    const { organizationId, year, weekNumber } = params;

    try {
      const response = await api.get<GetGuestOrganizationAppointmentsResponse>(
        `/guest/organizations/${organizationId}/appointments/${year}/${weekNumber}`,
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
