import { useState, useEffect } from "react";

/**
 * External imports
 */
import { findIndex, cloneDeep } from "lodash";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports hooks
 */
import {
  useApi,
  useSearch,
  useActions,
  useSelector,
  useDebounce,
  useTranslation,
  useFilterModelsUtils,
} from "..";

/**
 * Imports types
 */
import { FilterModel, UserTimesheet, TableData } from "../../types";
import {
  SearchTimesheetsBody,
  SearchTimesheetsOnSuccess,
  RequestOnError,
} from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const TimesheetsProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Initializes the loading
   */
  const [loading, setLoading] = useState(true);

  /**
   * Initializes the table rows loading
   */
  const [tableRowsLoading, setTableRowsLoading] = useState(false);

  /**
   * Initializes the trigger search flag
   */
  const [triggerSearch, setTriggerSearch] = useState(false);

  /**
   * Initializes the timesheets state
   */
  const [timesheets, setTimesheets] = useState<UserTimesheet[]>([]);

  /**
   * Initializes the total workers state
   */
  const [totalTimesheets, setTotalTimesheets] = useState(0);

  /**
   * Initializes the models initialized flag
   */
  const [modelsInitialized, setModelsInitialized] = useState(false);

  /**
   * Initializes the order by state
   */
  const [orderBy, setOrderBy] = useState("start_date");

  /**
   * Initializes the order dir state
   */
  const [orderDir, setOrderDir] = useState<"asc" | "desc">("desc");

  /**
   * Initializes the page count
   */
  const [pageCount, setPageCount] = useState(1);

  /**
   * Gets the account state
   */
  const { userInitialized, accountInformation } = useSelector(
    (state) => state.account,
  );

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the filter models from the search provider
   */
  const { defaultFilters, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  /**
   * Gets the filter model utility hook
   */
  const {
    removeFilter,
    createFilter,
    addOrReplaceFilter,
    formatActiveFilters,
  } = useFilterModelsUtils();

  /**
   * Defines the api call error callback
   */
  const onRequestError: RequestOnError = (error) => {
    /**
     * Updates loading state
     */
    setLoading(false);
    setTableRowsLoading(false);

    dispatchMessage({
      message: error.errorMessage ? error.errorMessage : "Unknown Error",
      severity: "error",
      autoClose: 10000,
    });
  };

  /**
   * Handles initializing the filter models
   */
  const initializeFilterModels = () => {
    /**
     * Initializes the filter models
     */
    const models: FilterModel[] = [];

    /**
     * Creates the filter models
     */
    if (accountInformation) {
      /**
       * Defines the organization filter model
       */
      const userIdModel = createFilter({
        field: "account_id",
        selected: accountInformation.id,
        type: "dropdown",
      });

      models.push(userIdModel);
    }

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  /**
   * Handles searching for timesheets
   */
  const searchTimesheets = async (filters: FilterModel[], page?: number) => {
    /**
     * Defines the request body
     */
    const reqBody: SearchTimesheetsBody = {
      models: filters.filter((model) => !model.displayOnly),
      order_by: orderBy,
      order_dir: orderDir,
      page_count: page || pageCount,
      page_size: 15,
    };

    /**
     * Defines the api call success callback
     */
    const onRequestSuccess: SearchTimesheetsOnSuccess = (response) => {
      if (response && response.data) {
        const { data } = response;
        const { items, pageCount, orderBy, orderDir, total } = data;

        setTimesheets(items);
        setPageCount(pageCount);
        setOrderBy(orderBy);
        setOrderDir(orderDir);
        setTotalTimesheets(total);
        setLoading(false);
        setTableRowsLoading(false);
      }
    };

    await apiCalls.searchTimesheets(reqBody, onRequestSuccess, onRequestError);
  };

  /**
   * Handles creating a report based on search value
   */
  const handleSearch = (searchValue: string) => {
    /**
     * Defines the search filter model
     */
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });

    /**
     * Updates the active filters
     */
    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    setLoading(true);
    searchTimesheets(filterModels, 1);
    setActiveFilters(filterModels);
  };

  /**
   * Handles deleting a filter
   */
  const deleteFilter = (filter: FilterModel) => {
    const updatedFilters = removeFilter(filter, activeFilters);
    setActiveFilters(updatedFilters);

    debounce(() => {
      setPageCount(1);
      setLoading(true);
      setTriggerSearch(true);
    }, 1500);
  };

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    setLoading(true);
    setActiveFilters(defaultFilters);
    setPageCount(1);

    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  /**
   * Handles submitting the filters modal form
   */
  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      searchTimesheets(filters, 1);
    }, 500);
  };

  /**
   * Handles sorting, updates states
   */
  const handleSort = (e: React.MouseEvent<any>, property: keyof TableData) => {
    const isAsc = orderBy === property && orderDir === "asc";

    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property as string);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Handles updating the timesheet in the table
   */
  const updateTimesheet = (timesheet: UserTimesheet) => {
    const index = findIndex(timesheets, { id: timesheet.id });
    const clonedTimesheets = cloneDeep(timesheets);
    clonedTimesheets[index] = timesheet;

    setTimesheets(clonedTimesheets);
  };

  /**
   * Handles updating the current page state
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPageCount(page);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Initializes the filter models
   */
  useEffect(() => {
    if (userInitialized) {
      initializeFilterModels();
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles searching for workers once filter models are set
   */
  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        searchTimesheets(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  /**
   * Handles triggering a search after filter deletion
   */
  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);

      if (activeFilters.length < 1) {
        setLoading(false);
        setTableRowsLoading(false);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
        initializeFilterModels();
      } else {
        searchTimesheets(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    loading,
    orderBy,
    orderDir,
    pageCount,
    timesheets,
    activeFilters,
    totalTimesheets,
    tableRowsLoading,
    modelsInitialized,
    handleSort,
    setPageCount,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    setTimesheets,
    updateTimesheet,
    handlePageChange,
    setTotalTimesheets,
    formatActiveFilters,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
