/**
 * External imports
 */
import { orderBy, groupBy } from "lodash";

/**
 * Imports hooks
 */
import { useUserUtils } from "../../hooks";

/**
 * Imports types
 */
import { IKanbanColumn } from "../../hooks";
import { Appointment, CustomerOrder } from "../../types";
import { CustomerOrderGroup } from "./useCustomerOrderUtils.types";

/**
 * Defines the utility hook
 */
export const useCustomerOrderUtils = () => {
  /**
   * Gets user utils
   */
  const { getUserOrganization, getAppointmentGroupByOrgOptions } =
    useUserUtils();

  /**
   * Handles grouping the customer orders by appointment group
   */
  const groupByAppointmentGroup = (customerOrders: CustomerOrder[]) => {
    const appointmentGroups = groupBy(customerOrders, "appointmentGroupId");
    const groups: CustomerOrderGroup[] = Object.keys(appointmentGroups).map(
      (key) => {
        return {
          groupId: key,
          customerOrders: appointmentGroups[key],
        };
      }
    );

    return groups;
  };

  /**
   * Handles getting a random color
   */
  const getRandomColor = (index: number) => {
    const colors = [
      "primary",
      "error",
      "info",
      "scheduled",
      "success",
      "default",
    ];
    return colors[index % colors.length];
  };

  /**
   * Handles filtering the customer orders by appointment group
   */
  const filterCustomerOrdersByAppointmentGroup = (
    customerOrders: CustomerOrder[],
    appointmentGroupId: number | null
  ) => {
    return customerOrders.filter((customerOrder) => {
      return customerOrder.appointmentGroupId === appointmentGroupId;
    });
  };

  /**
   * Handles building the default kanban column
   */
  const buildDefaultKanbanColumn = (customerOrders: CustomerOrder[]) => {
    /**
     * Filters the customer orders by appointment group
     */
    const filteredOrders = filterCustomerOrdersByAppointmentGroup(
      customerOrders,
      null
    );

    /**
     * Maps the customer orders to rows
     */
    const rows = filteredOrders.map((customerOrder) => {
      return {
        ...customerOrder,
        badgeColor: getRandomColor(0),
      };
    });

    /**
     * Orders the rows
     */
    const orderedRows = orderBy(rows, "order", ["asc"]);

    return [
      {
        title: "Groupless",
        group: "null",
        badgeColor: "primary",
        rows: orderedRows,
      },
    ] as IKanbanColumn[];
  };

  /**
   * Handles building the groupless kanban columns
   */
  const buildGrouplessKanbanColumn = (customerOrders: CustomerOrder[]) => {
    /**
     * Maps the customer orders to rows
     */
    const rows = customerOrders.map((customerOrder) => {
      return {
        ...customerOrder,
        badgeColor: "primary",
      };
    });

    /**
     * Orders the rows
     */
    const orderedRows = orderBy(rows, "order", ["asc"]);

    return [
      {
        title: "All",
        group: "-1",
        badgeColor: "primary",
        rows: orderedRows,
      },
    ] as IKanbanColumn[];
  };

  /**
   * Handles building the kanban columns
   */
  const buildKanbanColumns = (
    customerOrders: CustomerOrder[],
    viewPerGroup?: boolean
  ) => {
    /**
     * Gets the organization
     */
    const organization = getUserOrganization();

    /**
     * Builds the options based on appointment groups
     */
    const options = organization
      ? getAppointmentGroupByOrgOptions(organization.id)
      : [];

    if (options.length < 1) return buildDefaultKanbanColumn(customerOrders);

    /**
     * Adds the groupless option
     */
    options.push({ label: "Groupless", value: "null" });

    if (!viewPerGroup) return buildGrouplessKanbanColumn(customerOrders);

    return options.map((option, index) => {
      const title = option.label;
      const badgeColor = getRandomColor(index);

      /**
       * Filters the customer orders by appointment group
       */
      const filteredOrders = filterCustomerOrdersByAppointmentGroup(
        customerOrders,
        option.value === "null" ? null : option.value
      );

      /**
       * Maps the customer orders to rows
       */
      const rows = filteredOrders.map((customerOrder) => {
        return {
          ...customerOrder,
          badgeColor,
        };
      });

      /**
       * Orders the rows
       */
      const orderedRows = orderBy(rows, "order", ["asc"]);

      return {
        title: title,
        group: option.value.toString(),
        badgeColor,
        rows: orderedRows,
      } as IKanbanColumn;
    });
  };

  /**
   * Handles filtering the appointments
   */
  const filterAppointments = (
    appointments: Appointment[],
    customerOrders: CustomerOrder[]
  ) => {
    const today = new Date();
    const todayDate = today.getUTCDate();
    return appointments.filter((appointment) => {
      const foundPlateNumber = customerOrders.find((customerOrder) => {
        return customerOrder.carPlateNumber === appointment.carPlateNumber;
      });

      /**
       * Checks if the appointment is for today
       */
      const fromDate = new Date(appointment.from);
      const appointmentDate = fromDate.getUTCDate();

      if (appointmentDate !== todayDate) return false;

      /**
       * Checks if the end time of the appointment is past the current time
       */
      if (new Date(appointment.to).getTime() < today.getTime()) {
        return false;
      }

      return foundPlateNumber ? false : true;
    });
  };

  /**
   * Returns the max order value
   */
  const getMaxOrderValue = (customerOrders: CustomerOrder[]) => {
    if (customerOrders.length < 1) return 100;

    return Math.max(
      ...customerOrders.map((customerOrder) => customerOrder.order)
    );
  };

  /**
   * Handles formatting the date to ISO
   */
  const formatDateToISO = (dateStr: string) => {
    const [day, month, year, hour, minute] = dateStr.split(/[/ :]/);
    const localDate = new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
    const offset = localDate.getTimezoneOffset();
    const utcTimestamp = localDate.getTime() - offset * 60 * 1000;
    const utcDate = new Date(utcTimestamp);

    return utcDate.toISOString().slice(0, -1);
  };

  /**
   * Returns the default date
   */
  const getDefaultFormDate = () => {
    const date = new Date();
    const hours = date.getHours();

    // if it's past 6 pm, set the time to 6 pm
    if (hours >= 18) {
      date.setHours(18);
      date.setMinutes(0);
      date.setSeconds(0);
    }
    // if it's before 7 am, set the time to 7 am
    else if (hours < 7) {
      date.setHours(7);
      date.setMinutes(0);
      date.setSeconds(0);
    }

    return date;
  };

  return {
    formatDateToISO,
    getMaxOrderValue,
    getDefaultFormDate,
    buildKanbanColumns,
    filterAppointments,
    groupByAppointmentGroup,
  };
};
