import { createContext } from "react";

/**
 * Imports types
 */
import {
  BaseSyntheticEvent,
  SyntheticEvent,
  Dispatch,
  SetStateAction,
} from "react";
import { UseFormReturn, FieldArrayWithId } from "react-hook-form";
import {
  BreadcrumbPath,
  Tab,
  SelectOption,
  WorkOrderFormBody,
  WorkOrder,
} from "../../types";

/**
 * Defines the work order route params interface
 */
export interface DraftWorkOrderRouteParams {
  draftId: string;
}

/**
 * Defines the Update draft props interface
 */
export interface UpdateDraftProps {
  callback?: () => Promise<void>;
}

/**
 * Defines the form body interface
 */
export type { WorkOrderFormBody as FormBody } from "../../types";

/**
 * Defines the work order product interface
 */
export type WorkOrderProduct = FieldArrayWithId<
  WorkOrderFormBody,
  "products",
  "id"
>;

/**
 * Defines the work order products interface
 */
export type WorkOrderProducts = WorkOrderProduct[];

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  startDate: Date;
  loading: boolean;
  draftLoading: boolean;
  activeTab: number;
  errorTabs: number[];
  disabledTabs: number[];
  validatedTabs: number[];
  products: WorkOrderProduct[];
  productsList: SelectOption[];
  servicesList: SelectOption[];
  methods?: UseFormReturn<WorkOrderFormBody, object>;
  breadcrumbPaths: BreadcrumbPath[];
  draftRemoved: boolean;
  draftNotFound: boolean;
  draftWorkOrder?: WorkOrder;
  addNewProductRow: () => void;
  getWorkOrderTabs: Tab[];
  validateTab: (index: number) => void;
  removeProductRow: (index: number) => void;
  setDraftLoading: Dispatch<SetStateAction<boolean>>;
  setActiveTab: Dispatch<SetStateAction<number>>;
  setErrorTabs: Dispatch<SetStateAction<number[]>>;
  setDisabledTabs: Dispatch<SetStateAction<number[]>>;
  setValidatedTabs: Dispatch<SetStateAction<number[]>>;
  updateRow: (index: number, row: WorkOrderProduct) => void;
  updateProductRows: (products: WorkOrderProduct[]) => void;
  activateTab: (
    event: SyntheticEvent<Element, Event>,
    newValue: number,
  ) => void;
  handleTabChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: number,
  ) => void;
  updateDraft: () => Promise<void>;
  deleteDraft: () => void;
  onFormSubmit: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  activeTab: 0,
  products: [],
  errorTabs: [],
  loading: false,
  disabledTabs: [],
  productsList: [],
  servicesList: [],
  validatedTabs: [],
  methods: undefined,
  startDate: new Date(),
  draftLoading: false,
  breadcrumbPaths: [],
  draftRemoved: false,
  draftNotFound: false,
  setDraftLoading: () => {},
  updateRow: () => {},
  activateTab: () => {},
  handleTabChange: () => {},
  validateTab: () => {},
  setErrorTabs: () => {},
  setActiveTab: () => {},
  setDisabledTabs: () => {},
  addNewProductRow: () => {},
  removeProductRow: () => {},
  setValidatedTabs: () => {},
  getWorkOrderTabs: [],
  updateProductRows: () => {},
  onFormSubmit: () => new Promise((res) => res()),
  updateDraft: () => new Promise((res) => res()),
  deleteDraft: () => {},
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
