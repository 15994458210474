/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  DeleteContactApiCall,
  DeleteContactResponse
} from "./DeleteContact.types";

/**
 * Handles making the api call
 */
export const DeleteContact: DeleteContactApiCall =
  (api) => async (contactId, onSuccess, onError) => {
    try {
      const response = await api.delete<DeleteContactResponse>(
        `contacts/${contactId}`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
