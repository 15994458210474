/**
 * Imports Material UI Components
 */
import MuiSnackbar from "@mui/material/Snackbar";

/**
 * Imports styled
 */
import { styled } from "@mui/material";

/**
 * Styles the Snackbar
 */
export const Snackbar = styled(MuiSnackbar)(() => {
  return {
    padding: 0,
    margin: 0,
    background: "transparent",
    boxShadow: "none",
    minWidth: 350,
    maxWidth: 800,
    zIndex: 9999,
    "&.MuiSnackbar-root": {
      top: 115
    },
    "& .MuiSnackbarContent-root": {
      boxShadow: "none",
      background: "transparent",
      width: "100%",
      padding: 0
    },
    "& .MuiSnackbarContent-message": {
      padding: 0,
      width: "100%"
    }
  };
});
