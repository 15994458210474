/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  UpdatePaymentTypeApiCall,
  UpdatePaymentTypeResponse
} from "./UpdatePaymentType.types";

/**
 * Handles making the api call
 */
export const UpdatePaymentType: UpdatePaymentTypeApiCall =
  (api) => async (paymentTypeId, body, onSuccess, onError) => {
    try {
      const response = await api.put<UpdatePaymentTypeResponse>(
        `/payment-types/${paymentTypeId}`,
        fromCamelToSnake(body)
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
