/**
 * Imports hooks
 */
import {  toNumber } from "lodash";
import { useUtils, useTranslation } from "..";

/**
 * Imports types
 */
import { Statistics, StatsCardItem, StatsItem } from "../../types";

/**
 * Provides state management for the dashboard page statistics
 */
export const useStatisticsUtils = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets utils
   */
  const { addCommaSeparator } = useUtils();

  /**
   * Returns the card title
   */
  const getTitle = (stats?: StatsItem) => {
    if (!stats) return "";

    switch (stats.type) {
      case "TOTAL_WEEKLY":
        return t("WeeklyTotalOrders");
      case "TOTAL_MONTH":
        return t("MonthlyTotalOrders");
      case "TOTAL_YEAR":
        return t("YearlyTotalOrders");
      default:
        return t("UnknownTranslation");
    }
  };

  /**
   * Returns the display value
   */
  const getDisplayValue = (stats?: StatsItem) => {
    if (!stats) return "";

    return addCommaSeparator(stats.total!.toString());
  };

  /**
   * Checks if the trending is up
   */
  const isTrendingUp = (stats?: StatsItem) => {
    if (!stats) return false;

    return stats.progress > 0;
  };

  /**
   * Checks if the trending is up
   */
  const isTrendingFlat = (stats?: StatsItem) => {
    if (!stats) return false;

    return stats.progress === 0;
  };

  /**
   * Checks if the trending is up
   */
  const isTrendingDown = (stats?: StatsItem) => {
    if (!stats) return false;

    return stats.progress < 0;
  };

  /**
   * Returns the progress value
   */
  const getProgressValue = (stats?: StatsItem) => {
    if (!stats) return 0;

    return stats.progress;
  };

  /**
   * Returns the stats values
   */
  const getStatsValues = (stats?: StatsItem) => {
    if (!stats) return [];

    return stats.values;
  };

  /**
   * Returns the card progress label
   */
  const getProgressLabel = (selected: string, stats?: StatsItem) => {
    if (!stats) return "";

    if (selected === "past") {
      return t("VsThisYear");
    }

    return t("VsLastYear");
  };

  /**
   * Returns the default values for the statistics
   */
  const getDefaultValues = () => {
    /**
     * Weekly orders
     */
    const weeklyOrders: StatsCardItem = {
      current: {
        progress: 0,
        timeInterval: "WEEK",
        type: "TOTAL_WEEKLY",
        total: 0,
        values: [0, 0, 0, 0, 0, 0, 0]
      },
      past: {
        progress: 0,
        timeInterval: "WEEK",
        type: "TOTAL_WEEKLY",
        total: 0,
        values: [0, 0, 0, 0, 0, 0, 0]
      },
      active: "current"
    };

    /**
     * Monthly orders
     */
    const monthlyOrders: StatsCardItem = {
      current: {
        progress: 0,
        timeInterval: "MONTH",
        type: "TOTAL_MONTH",
        total: 0,
        values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      past: {
        progress: 0,
        timeInterval: "MONTH",
        type: "TOTAL_MONTH",
        total: 0,
        values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      active: "current"
    };

    return [weeklyOrders, monthlyOrders];
  };

  /**
   * Returns the stats interval options
   */
  const getStatsIntervalOptions = (data?: StatsItem) => {
    if (!data) return [];

    const current = new Date().getFullYear();
    const past = current - 1;

    return [
      { label: current.toString(), value: "current" },
      { label: past.toString(), value: "past" }
    ];
  };

  /**
   * Gets the relative difference between the provided values
   */
  const getRelativeDifference = (a: number, b: number) => {
    const value = ((a - b) / a) * 100;
    return toNumber(value.toFixed(2));
  };

  /**
   * Returns the past weekly progress calculated
   */
  const getPastProgress = (current: number, past: number) => {
    if (!past) return -100;
    if (!current) return 100;

    return toNumber((((past - current) / current) * 100.0).toFixed(2));
  };

  /**
   * Returns the current progress
   */
  const getCurrentProgress = (current: number, past: number) => {
    if (!past) return 100;
    if (!current) return -100;

    return getRelativeDifference(current, past);
  };

  /**
   * Returns the weekly total value for current and past
   */
  const getWeeklyTotal = (current: Statistics, past: Statistics) => {
    return {
      current: current.week.progress,
      past: past.week.progress
    };
  };

  /**
   * Returns the monthly total value for current and past
   */
  const getMonthlyTotal = (current: Statistics, past: Statistics) => {
    return {
      current: current.month.progress,
      past: past.month.progress
    };
  };

  return {
    getTitle,
    getProgressLabel,
    getDefaultValues,
    getStatsIntervalOptions,
    getWeeklyTotal,
    getMonthlyTotal,
    getCurrentProgress,
    getPastProgress,
    getRelativeDifference,
    getStatsValues,
    getProgressValue,
    isTrendingDown,
    isTrendingUp,
    isTrendingFlat,
    getDisplayValue
  };
};
