/**
 * Imports types
 */
import { MessageState, MessageConfig } from "./message.types";

/**
 * Handles sanitizing the message
 */
const sanitizeMessage = (message: any) => {
  if (Array.isArray(message) || typeof message === "object") {
    return "Unable to show error message. Invalid format";
  }

  return message;
};

/**
 * Handles building the message config on dispatch
 */
export const buildMessageConfig = (
  payload: MessageConfig,
  initialState: MessageState,
) => {
  const { config } = initialState;
  const { title, message, permanent, autoClose, severity, origin } = payload;

  return {
    title: title || config.title,
    origin: origin || config.origin,
    message: sanitizeMessage(message),
    severity: severity || config.severity,
    autoClose: autoClose || config.autoClose,
    permanent: permanent || config.permanent,
  };
};
