/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetWorkersOldApiCall,
  GetWorkersOldResponse
} from "./GetWorkersOld.types";

/**
 * Handles making the api call
 */
export const GetWorkersOld: GetWorkersOldApiCall =
  (api) => async (onSuccess, onError) => {
    try {
      const response = await api.get<GetWorkersOldResponse>("/search/workers");

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
