import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import { Auth } from "../../types";

/**
 * Imports the graphQL client type
 */
// import { ApolloInstance } from "../useApi";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  // apollo?: ApolloInstance;
  auth: Auth;
  isAuthenticated: boolean;
  isLoggedOut: boolean;
  loading: boolean;
  resetUserState: boolean;
  logout: (message?: string) => void;
  updateAuth: (auth: Auth) => void;
  setResetUserState: Dispatch<SetStateAction<boolean>>;
  setIsLoggedOut: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  // apollo: undefined,
  loading: false,
  auth: {
    accessToken: "",
    expiresIn: 0,
    tokenType: "",
    user: undefined,
  },
  isAuthenticated: false,
  isLoggedOut: false,
  resetUserState: false,
  setResetUserState: () => {},
  logout: () => {},
  updateAuth: () => {},
  setIsLoggedOut: () => {},
  setLoading: () => {},
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
