import { useState } from "react";

/**
 * Provides state management for the pint input <InputPin />
 */
export const usePin = () => {
  /**
   * Initializes the pin
   */
  const [pin, setPin] = useState("");

  /**
   * Handles adding a new digit to the pin string
   */
  const addDigit = (pinDigit: string) => {
    if (pin.length === 4) return;

    setPin((prevPin) => (prevPin += pinDigit));
  };

  /**
   * Handles removing the last digit from the pin string
   */
  const removeDigit = () => {
    if (pin.length === 0) return;

    setPin(pin.substring(0, pin.length - 1));
  };

  /**
   * Handles resetting the pin
   */
  const resetPin = () => setPin("");

  return {
    pin,
    setPin,
    addDigit,
    resetPin,
    removeDigit
  };
};
