/**
 * Imports Material UI components
 */
import TableCellMui from "@mui/material/TableCell";

/**
 * Imports types
 */
import { TableCellProps, PaddingStyle } from "./SmartTableCell.types";

/**
 * Imports styled
 */
import { styled } from "@mui/material";

/**
 * Styles the container
 */
export const TableCell = styled(TableCellMui, {
  shouldForwardProp: (propName) =>
    propName !== "minWidth" && propName !== "weight" && propName !== "color" && propName !== "fontSize"
})<TableCellProps>((props) => {
  const { weight, color, minWidth, p, pt, pb, pl, pr, fontSize, theme } = props;

  let textColor = "inherit";

  if (color) {
    if (color.includes(".")) {
      const palette = color.split(".")[0];
      const subPalette = color.split(".")[1];

      textColor = (theme.palette as any)[palette][subPalette];
    } else {
      switch (color) {
        case "inherit":
          textColor = "inherit";
          break;
        case "secondary":
          textColor = theme.palette.secondary.main;
          break;
        case "success":
          textColor = theme.palette.success.main;
          break;
        case "error":
          textColor = theme.palette.error.main;
          break;
        default:
          textColor = theme.palette.primary.main;
          break;
      }
    }
  }

  /**
   * Returns the padding
   */
  const getPadding = () => {
    const defaultPadding: PaddingStyle = { padding: "21.5px 0" };

    if (pl) defaultPadding["paddingLeft"] = pl;
    if (pr) defaultPadding["paddingRight"] = pr;
    if (pt) defaultPadding["paddingTop"] = pt;
    if (pb) defaultPadding["paddingBottom"] = pb;
    if (p) defaultPadding["padding"] = p;

    return defaultPadding;
  };

  return {
    ...getPadding(),
    minWidth: minWidth ? minWidth : "fit-content",
    fontWeight: weight || 400,
    color: textColor,
    ...(fontSize && {
      fontSize
    }),
  };
});

/**
 * Styles the flex container
 */
export const FlexContainer = styled("div")(() => {
  return {
    display: "flex",
    alignItems: "center"
  };
});

/**
 * Styles the text container
 */
export const TextContainer = styled("span")(() => {
  return {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  };
});

/**
 * Styles the text
 */
export const Text = styled("span")(() => {
  return {
    cursor: "pointer"
  };
});

/**
 * Styles the NotFound Wrapper
 */
export const NotFoundWrapper = styled("span")((props) => {
  const { theme } = props;

  return {
    color: theme.palette.error.main
  };
});
