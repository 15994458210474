import { useRef, useState } from "react";

/**
 * Imports hooks
 */
import { useReactToPrint } from "react-to-print";

/**
 * Provides utility hook to support printing react components
 */
export const usePrint = () => {
  /**
   * Initializes the component ref
   */
  const componentRef = useRef();

  /**
   * Initializes the printing done state
   */
  const [isDone, setIsDone] = useState(false);

  /**
   * Triggers the print action
   */
  const printComponent = useReactToPrint({
    content: () => {
      if (componentRef) return componentRef.current || null;
      return null;
    },
    onAfterPrint: () => {
      setIsDone(true);
    },
    onBeforePrint: () => {
      setIsDone(false);
    }
  });

  return {
    isDone,
    ref: componentRef,
    print: printComponent
  };
};
