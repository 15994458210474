/**
 * Imports types
 */
import { HashMap } from "../../types";

/**
 * Converts array to hash map
 */
export const toHashMap = (array: any[], indexKey: string) => {
  /**
   * Initializes the map
   */
  const map: HashMap<any> = {};

  array.forEach((item) => (map[item[indexKey]] = item));

  return map;
};
