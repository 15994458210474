import { useState, useEffect } from "react";

/**
 * Imports hooks
 */
import { useDebounce } from "../useDebounce";

/**
 * Imports types
 */
import { ControllerFieldState, FormState } from "react-hook-form";

/**
 * Defines the useInputError hook
 */
export const useInputError = (
  fieldState: ControllerFieldState,
  formState: FormState<any>,
  debounceTime?: number
) => {
  /**
   * Gets the form state
   */
  const { isSubmitting, isSubmitted } = formState;

  /**
   * Gets the field states
   */
  const { error, isDirty, invalid, isTouched } = fieldState;

  /**
   * Initializes the has error flag
   */
  const [hasError, setHasError] = useState(false);

  /**
   * Initializes the debounced flag
   */
  const [debounced, setDebounced] = useState(false);

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Handles getting the error message
   */
  const getErrorMessage = () =>
    error && error.message ? error.message : undefined;

  /**
   * Handles getting the error message
   */
  const getErrorType = () => (error && error.type ? error.type : undefined);

  /**
   * Handles getting the error message
   */
  const getErrorTypes = () => (error && error.types ? error.types : undefined);

  /**
   * Handles getting the error message
   */
  const getFieldRef = () => (error && error.ref ? error.ref : undefined);

  /**
   * Handles debouncing the error message until the user stops typing
   * Only happens the very first time you type
   */
  useEffect(() => {
    if (isDirty && invalid && !hasError) {
      if (!debounced) {
        debounce(() => {
          setHasError(true);
          setDebounced(true);
        }, debounceTime || 800);
      }
    }
    // eslint-disable-next-line
  }, [invalid, error, debounced, isDirty]);

  /**
   * Handles showing the error message if there are errors
   */
  useEffect(() => {
    if (invalid && error?.message && !hasError && debounced) {
      debounce(() => {
        setHasError(true);
        setDebounced(true);
      }, debounceTime || 800);
    }
    if (!error && hasError) setHasError(false);
    // eslint-disable-next-line
  }, [invalid, error, hasError, debounced]);

  /**
   * Handles showing the error message if submitting and field are invalid
   */
  useEffect(() => {
    if (isSubmitting && invalid && error?.message && !hasError) {
      setHasError(true);
      setDebounced(true);
    }
  }, [isSubmitting, invalid, error, hasError]);

  /**
   * Handles showing the error message in case the error is being set externally
   */
  useEffect(() => {
    if (invalid && isTouched && error?.message) {
      setHasError(true);
    }
  }, [invalid, isTouched, error]);

  /**
   * Handles showing any errors when the form is submitted (no delays or waiting for user input)
   */
  useEffect(() => {
    if (isSubmitted && invalid) {
      setHasError(true);
      setDebounced(true);
    }
  }, [invalid, isSubmitted]);

  /**
   * Clean up state
   */
  useEffect(() => {
    return () => {
      setHasError(false);
      setDebounced(false);
    };
  }, []);

  return {
    errorMessage: getErrorMessage(),
    fieldRef: getFieldRef(),
    errorType: getErrorType(),
    errorTypes: getErrorTypes(),
    error,
    hasError,
    isDirty,
    invalid
  };
};
