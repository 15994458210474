import { createSlice } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { ReduxSlice } from "..";
import type { WorkOrder, CustomerOrder } from "../../../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { WorkOrderState } from "./workOrder.types";
import type { AppointmentModel } from "@devexpress/dx-react-scheduler-material-ui";

/**
 * Imports utils
 */
import {
  // preloadWorkOrderDraft,
  preloadDefaultWorkOrderType,
} from "./workOrder.utils";

/**
 * Defines the auth initial state
 */
const initialState: WorkOrderState = {
  // draft: preloadWorkOrderDraft(),
  defaultWorkOrderType: preloadDefaultWorkOrderType(),
  data: undefined,
  viewMode: "edit",
  appointmentData: undefined,
  customerOrderData: undefined,
};

/**
 * Handles creating the a redux state slice
 *
 * - This will automatically generate action-creators and action-types that correspond
 * to the reducers and state.
 * - The internal usage of Immer allows for writing 'mutating' update logic that becomes
 * correct immutable updates.
 */
export const workOrderSlice = createSlice({
  name: ReduxSlice.WorkOrder,
  initialState,
  reducers: {
    updateViewMode: (state, action: PayloadAction<"edit" | "view">) => {
      state.viewMode = action.payload;
    },
    updateWorkOrder: (state, action: PayloadAction<WorkOrder>) => {
      state.data = action.payload;
    },
    removeWorkOrder: (state) => {
      state.data = undefined;
    },
    updateDefaultWorkOrderType: (state, action: PayloadAction<number>) => {
      state.defaultWorkOrderType = action.payload;
    },
    updateAppointmentData: (
      state,
      action: PayloadAction<AppointmentModel | undefined>,
    ) => {
      state.appointmentData = action.payload;
    },
    updateCustomerData: (
      state,
      action: PayloadAction<CustomerOrder | undefined>,
    ) => {
      state.customerOrderData = action.payload;
    },
    resetWorkOrderState: (state) => {
      state.data = undefined;
      state.viewMode = "edit";
      state.appointmentData = undefined;
      state.customerOrderData = undefined;
      state.defaultWorkOrderType = 0;
    },
    // updateWorkOrderDraft: (
    //   state,
    //   action: PayloadAction<WorkOrderFormBody | undefined>
    // ) => {
    //   state.draft = action.payload;
    // }
  },
});

/**
 * Exports the action-creators
 */
export const workOrderActionCreators = workOrderSlice.actions;

/**
 * Exports the reducer
 */
export const workOrderReducer = workOrderSlice.reducer;
