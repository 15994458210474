import { Fragment } from "react";

/**
 * Imports Material UI Components
 */
import Zoom from "@mui/material/Zoom";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";

/**
 * Imports types
 */
import { TransitionProps } from "./Transition.types";

/**
 * Defines the default props
 */
const defaultProps: TransitionProps = {
  type: "zoom",
  timeout: 350,
  in: false,
  mountOnEnter: true,
  unmountOnExit: true
};

/**
 * Displays the component
 */
export const Transition: React.FC<TransitionProps> = (props) => {
  const {
    type,
    timeout,
    in: open,
    mountOnEnter,
    unmountOnExit,
    direction,
    collapsedSize,
    className,
    skip,
    delay,
    children
  } = props;

  /**
   * Handles rendering the transition
   */
  const renderTransition = () => {
    const baseTransitionProps = {
      in: open,
      timeout,
      mountOnEnter,
      unmountOnExit,
      sx: { transitionDelay: delay || "0ms" },
      className
    };

    /**
     * Defines the transition children
     */
    const transitionChildren = <Box className={className}>{children}</Box>;

    if (skip) return <Fragment> {children} </Fragment>;
    switch (type) {
      case "collapse":
        return (
          <Collapse {...baseTransitionProps} collapsedSize={collapsedSize}>
            {transitionChildren}
          </Collapse>
        );
      case "fade":
        return <Fade {...baseTransitionProps}>{transitionChildren}</Fade>;
      case "slide":
        return (
          <Slide {...baseTransitionProps} direction={direction}>
            {transitionChildren}
          </Slide>
        );
      default:
        return <Zoom {...baseTransitionProps}>{transitionChildren}</Zoom>;
    }
  };

  return renderTransition();
};

Transition.defaultProps = defaultProps;
