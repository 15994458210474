/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetTimesheetReportsApiCall
  //  GetTimesheetReportsResponse
} from "./GetTimesheetReports.types";

/**
 * Handles making the api call
 */
export const GetTimesheetReports: GetTimesheetReportsApiCall =
  (api) => async (body, onSuccess, onError) => {
    try {
      const response = await api.post<any>("/reports/time-sheet", body);

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
