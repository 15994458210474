import { useState } from "react";

/**
 * External imports
 */
import { clone } from "lodash";

/**
 * Imports types
 */
import { UseTabsProps } from "./useTabs.types";

/**
 * Provides state management for tabs
 */
export const useTabs = (props: UseTabsProps) => {
  const { defaults } = props;

  /**
   * Initializes the current tab
   */
  const [activeTab, setActiveTab] = useState(defaults?.activeTab || 0);

  /**
   * Initializes the validated tabs
   */
  const [validatedTabs, setValidatedTabs] = useState<number[]>(
    defaults?.validatedTabs || []
  );

  /**
   * Initializes the disabled tabs
   */
  const [disabledTabs, setDisabledTabs] = useState<number[]>(
    defaults?.disabledTabs || []
  );

  /**
   * Initializes the error tabs
   */
  const [errorTabs, setErrorTabs] = useState<number[]>(
    defaults?.errorTabs || []
  );

  /**
   * Handles updating the current tab
   */
  const activateTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  /**
   * Handles validating a tab
   */
  const validateTab = (index: number) => {
    if (validatedTabs.includes(index)) return;
    setValidatedTabs((prevState) => [...prevState, index]);
  };

  /**
   * Handles disabling a tab
   */
  const disableTab = (index: number) => {
    if (disabledTabs.includes(index)) return;
    setDisabledTabs((prevState) => [...prevState, index]);

    if (validatedTabs.includes(index)) {
      const validated = clone(validatedTabs);
      validated.splice(index, 1);

      setValidatedTabs(validated);
    }
  };

  return {
    activeTab,
    validatedTabs,
    disabledTabs,
    errorTabs,
    setErrorTabs,
    setValidatedTabs,
    setDisabledTabs,
    setActiveTab,
    activateTab,
    validateTab,
    disableTab
  };
};
