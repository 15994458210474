/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetAssetManifestApiCall,
  GetAssetManifestResponse,
} from "./GetAssetManifest";
import { AxiosRequestConfig } from "axios";

/**
 * Handles making the api call
 */
export const GetAssetManifest: GetAssetManifestApiCall =
  (api) => async (onSuccess, onError) => {
    try {
      const config: AxiosRequestConfig = {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
        baseURL: "/",
      } as AxiosRequestConfig;

      const response = await api.get<GetAssetManifestResponse>(
        "/asset-manifest.json",
        config,
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
