import { useState } from "react";

/**
 * Import types
 */
import { FilterModel } from "../../types";

/**
 * Returns utility functions to easily build filter models
 * Provides state management for filters
 */
export const useFilterModels = () => {
  /**
   * Initializes the active filters
   */
  const [activeFilters, setActiveFilters] = useState<FilterModel[]>([]);

  /**
   * Initializes the default filters
   */
  const [defaultFilters, setDefaultFilters] = useState<FilterModel[]>([]);

  return {
    activeFilters,
    defaultFilters,
    setActiveFilters,
    setDefaultFilters
  };
};
