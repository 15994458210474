import { isValidElement, useMemo } from "react";

/**
 * Imports Material UI components
 */
import Stack from "@mui/material/Stack";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Imports components
 */
import { Icon } from "../Icon";
import { MessageBoxList } from "../MessageBoxList";
import { Transition } from "../Transition";
import { RenderController } from "../RenderController";

/**
 * Imports hooks
 */
import { useTranslation } from "../../hooks";

/**
 * Imports types
 */
import { MessageBoxProps } from "./MessageBox.types";

/**
 * Imports styled components
 */
import {
  MessageBoxContainer,
  MessageBoxHeader,
  MessageBoxIcon,
  MessageBoxTitle,
  MessageBoxContent,
} from "./MessageBox.styles";

/**
 * Displays the component
 */
export const MessageBox: React.FC<MessageBoxProps> = (props) => {
  const {
    variant,
    label,
    className,
    show,
    permanent,
    fullHeight,
    message,
    list,
    delay,
    valueKey,
    skipAnimation,
    onClose,
  } = props;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Handles getting the message icon
   */
  const getMessageIcon = useMemo(() => {
    switch (variant) {
      case "error":
        return <ErrorOutlineOutlinedIcon />;
      case "success":
        return <CheckCircleOutlinedIcon />;
      case "warning":
        return <ReportProblemOutlinedIcon />;
      default:
        return <InfoOutlinedIcon />;
    }
  }, [variant]);

  /**
   * Handles getting the message title
   */
  const getMessageTitle = useMemo(() => {
    if (label) return label;

    switch (variant) {
      case "error":
        return t("MessageBoxError");
      case "success":
        return t("MessageBoxSuccess");
      case "warning":
        return t("MessageBoxWarning");
      default:
        return t("MessageBoxInfo");
    }
    // eslint-disable-next-line
  }, [label, variant]);

  /**
   * Handles getting the message content
   */
  const getMessageContent = () => {
    /**
     * Renders custom react node
     */
    if (isValidElement(message)) {
      return message;
    }

    /**
     * Converts single string message into list
     */
    if (typeof message === "string" || !list) {
      const list = [message];
      return <MessageBoxList list={list} />;
    }

    /**
     * Renders list
     */
    if (Array.isArray(message) && typeof message !== "number") {
      const items = [...message];
      const key = variant === "error" ? "message" : valueKey;
      return <MessageBoxList list={items} valueKey={key} />;
    }

    return null;
  };

  return (
    <Transition
      className={className}
      timeout={350}
      type="zoom"
      in={show}
      delay={delay}
      skip={skipAnimation}
    >
      <MessageBoxContainer variant={variant}>
        <MessageBoxHeader className="MessageBox-header">
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Icon icon={getMessageIcon} />
            <MessageBoxTitle className="MessageBox-title">
              {getMessageTitle}
            </MessageBoxTitle>
          </Stack>
          <RenderController mountOn={!permanent}>
            <MessageBoxIcon
              className="MessageBox-icon"
              onClick={onClose}
              size="small"
            >
              <Icon icon={<CloseIcon />} />
            </MessageBoxIcon>
          </RenderController>
        </MessageBoxHeader>
        <MessageBoxContent
          className="MessageBox-content"
          fullHeight={fullHeight}
        >
          {getMessageContent()}
        </MessageBoxContent>
      </MessageBoxContainer>
    </Transition>
  );
};
