import { isAnyOf } from "@reduxjs/toolkit";

/**
 * Imports queries
 */
import { loginUser, logoutUser, switchUser } from "./auth.api";

/**
 * Imports action creators
 */
import {
  accountActionCreators,
  clientsActionCreators,
  workOrderActionCreators,
  workOverviewActionCreators,
} from "../..";
import { authActionCreators } from "./auth.slice";

/**
 * Imports types
 */
import { AppStartListening } from "../../store";

/**
 * Imports utils
 */
import { saveState, removeState } from "../../utils";

/**
 * Imports constants
 */
import {
  AUTH_KEY,
  STATISTICS_KEY,
  NOTIFICATIONS_KEY,
  ACCOUNT_DELETED_KEY,
  NOTIFICATION_VIEW_KEY,
  APPOINTMENTS_VIEW_KEY,
  NOTIFICATION_SETTINGS_KEY,
  DEFAULT_WORKORDER_TYPE_KEY,
  APPOINTMENTS_RESOURCES_KEY,
  APPOINTMENTS_ACTIVE_ORG_KEY,
  APPOINTMENTS_ACTIVE_GROUPS_KEY,
  APPOINTMENTS_WITH_GROUPING_KEY,
  APPOINTMENTS_FILTER_MODELS_KEY,
  WORKOVERVIEW_LIVE_WO_SEARCH_KEY,
  APPOINTMENTS_INCLUDE_GROUPLESS_KEY,
  WORKOVERVIEW_LIVE_WO_FILTER_MODELS_KEY,
  WORKOVERVIEW_LIVE_WO_STATUS_FILTERS_KEY,
} from "../../../constants";

const loginMatcher = isAnyOf(
  loginUser.matchFulfilled,
  switchUser.matchFulfilled,
);

export const addLoginListener = (startListening: AppStartListening) => {
  startListening({
    matcher: loginMatcher,
    effect: (action, _listenerApi) => {
      const auth = action.payload.data;

      saveState(AUTH_KEY, auth);
    },
  });
};

export const addSwitchUserListener = (startListening: AppStartListening) => {
  startListening({
    matcher: switchUser.matchFulfilled,
    effect: (action, listenerApi) => {
      removeState("liveWorkOrderFilters");
      removeState("liveWorkOrderFilterModels");
      removeState("appointmentsActiveOrg");
      removeState("appointmentsActiveGroups");

      removeState(STATISTICS_KEY);

      removeState(WORKOVERVIEW_LIVE_WO_FILTER_MODELS_KEY);
      removeState(WORKOVERVIEW_LIVE_WO_STATUS_FILTERS_KEY);
      removeState(WORKOVERVIEW_LIVE_WO_SEARCH_KEY);
      removeState(DEFAULT_WORKORDER_TYPE_KEY);

      removeState(NOTIFICATIONS_KEY);
      removeState(NOTIFICATION_SETTINGS_KEY);
      removeState(NOTIFICATION_VIEW_KEY);

      removeState(APPOINTMENTS_VIEW_KEY);
      removeState(APPOINTMENTS_RESOURCES_KEY);
      removeState(APPOINTMENTS_ACTIVE_ORG_KEY);
      removeState(APPOINTMENTS_ACTIVE_GROUPS_KEY);
      removeState(APPOINTMENTS_WITH_GROUPING_KEY);
      removeState(APPOINTMENTS_INCLUDE_GROUPLESS_KEY);
      removeState(APPOINTMENTS_FILTER_MODELS_KEY);

      // window.location.reload();
    },
  });
};

export const addLogutListener = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(logoutUser.matchFulfilled, logoutUser.matchRejected),
    effect: (action, listenerApi) => {
      listenerApi.dispatch(authActionCreators.logout());
      listenerApi.dispatch(accountActionCreators.resetAccountState());
      listenerApi.dispatch(workOrderActionCreators.resetWorkOrderState());
      listenerApi.dispatch(workOverviewActionCreators.resetWorkOverviewState());
      listenerApi.dispatch(clientsActionCreators.resetClientsState());

      removeState("liveWorkOrderFilters");
      removeState("liveWorkOrderFilterModels");
      removeState("appointmentsActiveOrg");
      removeState("appointmentsActiveGroups");
      removeState("i18nextLng");

      removeState(STATISTICS_KEY);

      removeState(WORKOVERVIEW_LIVE_WO_FILTER_MODELS_KEY);
      removeState(WORKOVERVIEW_LIVE_WO_STATUS_FILTERS_KEY);
      removeState(WORKOVERVIEW_LIVE_WO_SEARCH_KEY);

      removeState(ACCOUNT_DELETED_KEY);
      removeState(DEFAULT_WORKORDER_TYPE_KEY);

      removeState(AUTH_KEY);

      removeState(NOTIFICATIONS_KEY);
      removeState(NOTIFICATION_SETTINGS_KEY);
      removeState(NOTIFICATION_VIEW_KEY);

      removeState(APPOINTMENTS_VIEW_KEY);
      removeState(APPOINTMENTS_RESOURCES_KEY);
      removeState(APPOINTMENTS_ACTIVE_ORG_KEY);
      removeState(APPOINTMENTS_ACTIVE_GROUPS_KEY);
      removeState(APPOINTMENTS_WITH_GROUPING_KEY);
      removeState(APPOINTMENTS_INCLUDE_GROUPLESS_KEY);
      removeState(APPOINTMENTS_FILTER_MODELS_KEY);
    },
  });
};
