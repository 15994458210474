/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  CreateAppointmentGroupApiCall,
  CreateAppointmentGroupResponse
} from "./CreateAppointmentGroup.types";

/**
 * Handles making the api call
 */
export const CreateAppointmentGroup: CreateAppointmentGroupApiCall =
  (api) => async (body, onSuccess, onError) => {
    try {
      const response = await api.post<CreateAppointmentGroupResponse>(
        "/appointment-groups",
        fromCamelToSnake(body)
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
