/**
 * Imports hooks
 */
import { useFieldArray, useUtils } from "..";

/**
 * Imports types
 */
import { WorkOrderProduct } from "../useCreateWorkOrder/Context";
import { UseProductsFormProps, AddProductRowProps } from "../useProductsForm";

/**
 * Provides state management for the products form
 */
export const useProductsForm = (props: UseProductsFormProps) => {
  const { control, watch } = props;

  /**
   * Gets form utils
   */
  const { toFloat, toInteger } = useUtils();

  /**
   * Gets the products state
   */
  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name: "products",
  });

  /**
   * Watches any changes to the product fields
   */
  const watchProducts = watch("products");

  /**
   * Combines the field and the watcher to always keep the values in sync
   */
  const products = fields.map((field, index) => {
    const { price, quantity, total } = watchProducts[index];

    return {
      ...field,
      ...watchProducts[index],
      price: toFloat(price),
      quantity: toInteger(quantity),
      total: toFloat(total),
    };
  });

  /**
   * Handles adding a new empty product row
   */
  const addNewProductRow = (type?: "product" | "service") => {
    append({
      type: type || "service",
      quantity: "",
      price: "",
      total: "",
      productId: "",
    });
  };

  /**
   * Handles adding a product row
   */
  const addProductRow = (props: AddProductRowProps) => {
    append(props);
  };

  /**
   * Handles updating a row
   */
  const updateRow = (index: number, row: WorkOrderProduct) => {
    update(index, row);
  };

  /**
   * Handles removing a product row
   */
  const removeProductRow = (index: number) => {
    remove(index);
  };

  /**
   * Handles updating the product rows
   */
  const updateProductRows = (products: WorkOrderProduct[]) => {
    replace(products);
  };

  return {
    products,
    addNewProductRow,
    addProductRow,
    updateProductRows,
    removeProductRow,
    updateRow,
  };
};
