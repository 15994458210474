/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  CloseCustomerOrderApiCall,
  CloseCustomerOrderResponse,
} from "./CloseCustomerOrder.types";

/**
 * Handles making the api call
 */
export const CloseCustomerOrder: CloseCustomerOrderApiCall =
  (api) => async (customerOrderId, onSuccess, onError) => {
    try {
      const response = await api.put<CloseCustomerOrderResponse>(
        `/customer-order/${customerOrderId}/close`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
