import { useState, useEffect } from "react";

/**
 * External imports
 */
import { cloneDeep } from "lodash";

/**
 * Imports hooks
 */
import { useApi, useActions, useSearchUtils } from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { AppointmentGroup } from "../../types";
import { GetAppointmentGroupsOnSuccess, RequestOnError } from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const AccountSettingsSMSProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {};

  return <Provider value={providerValue}>{children}</Provider>;
};
