import { isAnyOf } from "@reduxjs/toolkit";

/**
 * Imports action creators
 */
import { notificationsActionCreators } from "./notifications.slice";

/**
 * Imports types
 */
import type { AppStartListening } from "../../store";

/**
 * Imports utils
 */
import { saveState } from "../../utils";

/**
 * Imports constants
 */
import {
  NOTIFICATIONS_KEY,
  NOTIFICATION_VIEW_KEY,
  NOTIFICATION_SETTINGS_KEY,
} from "../../../constants";

const {
  updateNotifications,
  updateNotificationView,
  updateNotificationSettings,
} = notificationsActionCreators;
const notificationsMatcher = isAnyOf(
  updateNotifications,
  updateNotificationView,
  updateNotificationSettings,
);

export const addNotificationsListener = (startListening: AppStartListening) => {
  startListening({
    matcher: notificationsMatcher,
    effect: (action, listenerApi) => {
      if (action.type === updateNotifications.type) {
        saveState(NOTIFICATIONS_KEY, action.payload);
      }

      if (action.type === updateNotificationView.type) {
        saveState(NOTIFICATION_VIEW_KEY, action.payload);
      }

      if (action.type === updateNotificationSettings.type) {
        saveState(NOTIFICATION_SETTINGS_KEY, action.payload);
      }
    },
  });
};
