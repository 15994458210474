/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  UpdateWorkOrderStatusApiCall,
  UpdateWorkOrderStatusResponse
} from "./UpdateWorkOrderStatus.types";

/**
 * Handles searching for products
 */
export const UpdateWorkOrderStatus: UpdateWorkOrderStatusApiCall =
  (api) => async (workOrderId, status, onSuccess, onError) => {
    try {
      const response = await api.put<UpdateWorkOrderStatusResponse>(
        `/work-orders/${workOrderId}/status/${status}`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
