/**
 * Imports types
 */
import { DefaultForms } from "../../types";

/**
 * Defines the default forms
 */
export const DEFAULT_FORMS: DefaultForms = {
  CREATE_WORK_ORDER: {
    workOrder: {
      carTypeId: "",
      clientId: "",
      clientName: "",
      delegateName: "",
      description: "",
      discount: 0,
      duration: "",
      endDate: "",
      phone: "",
      organizationClientId: "",
      startDate: new Date(),
      subtotal: 0,
      total: 0,
      tax: 0,
      workOrderTypeId: "",
      metaData: {
        ownProduct: false,
        subtotalWithoutHotel: "",
      },
    },
    car: {
      plateNumber: "",
      type: "",
      vinNumber: "",
      description: "",
      make: "",
      model: "",
      manufacturingYear: "",
    },
    tyreService: {
      boltTorque: "",
      carKm: "",
      newTyre: false,
      tyreHeight: "",
      tyrePressure: "",
      tyreRim: "",
      tyreWaste: "",
      tyreWidth: "",
      tyreCount: "",
      wheelTypeId: "",
      dotNumber: "",
      tyreDimensions: [],
    },
    carService: {
      carKm: "",
      mention: "",
      modelType: "",
      customerRequest: [],
      providerProposal: [],
    },
    tyreHotelEnabled: false,
    tyreHotel: {
      description: "",
      tyreHeight: "",
      tyreName: "",
      tyreQuantity: "",
      tyreRim: "",
      tyreWidth: "",
      createdBy: "",
      metaData: {
        dotNumber: "",
        phoneNumber: "",
        carModel: "",
        createdBy: "",
      },
    },
    workers: [],
    products: [],
  },
};
