/**
 * Imports transformers
 */
import { transformGuarantiesResponse } from "../../transformers";

/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetGuarantiesQueryVariables,
  GetGuarantiesResponse,
  GetGuarantiesApiCall
} from "./GetGuaranties.types";

/**
 * Imports queries
 */
import { getGuarantiesQuery } from "../queries";

/**
 * Handles making the api call
 */
export const GetGuaranties: GetGuarantiesApiCall =
  (api) => async (queryVariables, onSuccess, onError) => {
    try {
      const response = await api.query<
        GetGuarantiesResponse,
        GetGuarantiesQueryVariables
      >({
        query: getGuarantiesQuery,
        variables: queryVariables,
        fetchPolicy: "no-cache"
      });

      if (response) {
        onSuccess(transformGuarantiesResponse(response), response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
