/**
 * External Imports
 */
import lodash from "lodash";
import axios from "axios";

/**
 * Imports hooks
 */
import { useTranslation, useSelector, useApollo, useHistory } from "../index";

/**
 * Imports types
 */
import { AxiosResponse, AxiosError } from "axios";
import { ApolloError } from "@apollo/client";
import { ClientAPI, RequestOnError, ApiError } from "./useApi.types";
import { UseApiProps } from "./useApi.types";

/**
 * Imports api calls and queries
 */
import { apiCalls } from "./apiCalls";
import { queries } from "./queries";
import { useLogoutUserMutation } from "../../redux/features/auth/auth.api";

/**
 * Defines the default props
 */
const defaultProps: UseApiProps = {
  withCredentials: true,
};

/**
 * Convers from snake to camel case
 */
export const fromSnakeToCamel: (data: any) => any = (data) => {
  if (lodash.isArray(data)) {
    return lodash.map(data, fromSnakeToCamel);
  }

  if (lodash.isObject(data)) {
    return lodash(data)
      .mapKeys((v: any, k: any) => lodash.camelCase(k))
      .mapValues((v: any, k: any) => fromSnakeToCamel(v))
      .value();
  }

  return data;
};

/**
 * Convers from camel case to snake case
 */
export const fromCamelToSnake: (data: any) => any = (data) => {
  if (lodash.isArray(data)) {
    return lodash.map(data, fromCamelToSnake);
  }

  if (lodash.isObject(data)) {
    return lodash(data)
      .mapKeys((v: any, k: any) => lodash.snakeCase(k))
      .mapValues((v: any, k: any) => fromCamelToSnake(v))
      .value();
  }

  return data;
};

/**
 * Handles catching api errors
 */
export const catchError = (error: unknown, onError?: RequestOnError) => {
  const requestError = error as AxiosError;
  const networkError = error as Error;
  const apolloError = error as ApolloError;

  /**
   * Checks for api errors
   */
  if (onError && requestError.response) {
    const { response } = requestError;

    if (response) {
      const errorResponse = response.data as ApiError;
      return onError(errorResponse);
    }
  }

  /**
   * Checks for network errors
   */
  if (onError && networkError.message) {
    return onError({
      errorMessage: networkError.message,
    });
  }

  /**
   * Checks for Apollo errors
   */
  if (onError && apolloError.message) {
    const errorResponse: ApiError = {
      errorMessage: requestError.message,
    };

    onError(errorResponse);
  }
};

/**
 * Defines the main hook
 */
const useApi = (props?: UseApiProps) => {
  const withCredentials = props?.withCredentials;
  const guest = props?.guest;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the auth state setter
   */
  const { apollo } = useApollo();

  /**
   * Gets the logout mutation
   */
  const [logoutUser] = useLogoutUserMutation();

  /**
   * Gets the state
   */
  const auth = useSelector((state) => state.auth);

  /**
   * Gets the history object
   */
  const history = useHistory();

  /**
   * Handles getting the base api url
   */
  const getApiUrl = () => {
    const { NODE_ENV, REACT_APP_LOCAL_API, REACT_APP_PROD_API } = process.env;

    return NODE_ENV === "development"
      ? REACT_APP_LOCAL_API
      : REACT_APP_PROD_API!;
  };

  /**
   * Creates the api by configuring axios
   */
  const api: ClientAPI = axios.create({
    baseURL: getApiUrl(),
    timeout: 1000 * 35,
    timeoutErrorMessage: t("RequestTimeout"),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Api-Version": "v1",
      ...(guest && {
        "Guest-Token": process.env.REACT_APP_GUEST_TOKEN || "",
      }),
    },
  });

  /**
   * Sets the default functions
   */
  api.CancelToken = axios.CancelToken;
  api.isCancel = axios.isCancel;

  /**
   * Handles configuring the api
   */
  const configureApi = (api: ClientAPI) => {
    /**
     * Intercepts each request and handles the response / errors and headers
     */
    api.interceptors.request.use((request) => {
      if (withCredentials) {
        request.headers!["Authorization"] = `Bearer ${auth.accessToken}`;
      }

      return request;
    });

    /**
     * Intercepts certain responses and handles them app-wide
     */
    api.interceptors.response.use(
      (response) => {
        if (response.headers) {
          const { headers } = response;

          if (headers["content-type"] === "application/vnd.ms-excel") {
            return response as AxiosResponse<unknown, any>;
          }

          if (headers["content-type"] === "application/pdf") {
            return response as AxiosResponse<unknown, any>;
          }
        }

        return fromSnakeToCamel(response) as AxiosResponse<unknown, any>;
      },
      async (error: AxiosError) => {
        if (error.response) {
          const { status, data } = error.response;

          if (status === 403) {
            return Promise.reject(new Error(t("AccessDenied403")));
          }

          if (status === 401) {
            // @ts-ignore
            if (data.errorMessage && data.errorMessage.pin) {
              // @ts-ignore
              const { pin } = data.errorMessage;

              if (pin === "Wrong pin!") {
                return Promise.reject(error);
              }
            } else {
              localStorage.clear();
              logoutUser();
            }
          }

          if (status === 405) {
            if (auth && auth.user && auth.user.type !== "admin") {
              history.push("/dashboard/suspended-account");
            }
          }

          return Promise.reject(error);
        }
        console.log(error);
        return Promise.reject(new Error(t("TechnicalDifficultiesError")));
      },
    );
  };

  /**
   * Configure the api
   */
  configureApi(api);

  return { api, apiCalls: apiCalls(api), queries: queries(apollo!) };
};

useApi.defaultProps = defaultProps;

export { useApi };
