import { createContext } from "react";

/**
 * Imports types
 */
import { AccountUser, FilterModel } from "../../types";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  users: AccountUser[];
  activeFilters: FilterModel[];
  modelsInitialized: boolean;
  handleSubmit: (filters: FilterModel[]) => void;
  resetFilters: () => void;
  deleteFilter: (filter: FilterModel) => void;
  handleSearch: (searchValue: string) => void;
  filterTableResults: (users: AccountUser[]) => AccountUser[];
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  users: [],
  activeFilters: [],
  modelsInitialized: false,
  handleSubmit: () => {},
  resetFilters: () => {},
  deleteFilter: () => {},
  handleSearch: () => {},
  filterTableResults: () => []
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
