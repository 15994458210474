/**
 * Imports the apollo client
 */
import { gql } from "@apollo/client";

/**
 * Defines the clients query
 */
export const getClientsQuery = gql`
  query GetClients {
    clients {
      id
      name
      organization_id
      is_company
      delegate_name
    }
  }
`;

/**
 * Defines the client query
 */
export const getClientQuery = gql`
  query GetClient($id: Int!) {
    client(id: $id) {
      id
      name
      type
      is_company
      phone
      email
      description
      organization_id
      fidelity
      delegate_name
      company {
        id
        name
        vat_number
        reg_number
        phone
        email
        address {
          id
          name
          street
          number
          county
          country
          city
        }
      }
    }
  }
`;

/**
 * Defines the products query
 */
export const getProductsQuery = gql`
  query GetProducts($organization_id: Int!, $is_expendable: Boolean!) {
    products(organization_id: $organization_id, is_expendable: $is_expendable) {
      id
      name
      organization_id
      is_service
      is_count_price
      car_type_id
      price
    }
  }
`;

/**
 * Defines the report history query
 */
export const getReportHistoryQuery = gql`
  query GetReportHistory($type: String!) {
    reports(type: $type) {
      id
      account_id
      organization_id
      client_name
      created_by
      type
      from
      to
      options
      created_at
      updated_at
    }
  }
`;

/**
 * Defines the guaranties query
 */
export const getGuarantiesQuery = gql`
  query Guaranty(
    $client_id: Int
    $organization_id: Int!
    $created_by: Int
    $client_name: String
    $work_order_uuid: String
    $created_at: String
  ) {
    guaranties(
      client_id: $client_id
      organization_id: $organization_id
      created_by: $created_by
      client_name: $client_name
      work_order_uuid: $work_order_uuid
      created_at: $created_at
    ) {
      id
      account_id
      organization_id
      created_by
      client_id
      client_name
      work_order_uuid
      detailed_data
      comments
      description
      created_at
      updated_at
    }
  }
`;

/**
 * Defines the guaranty query
 */
export const getGuarantyQuery = gql`
  query Guaranty($id: Int!) {
    guaranty(id: $id) {
      id
      account_id
      organization_id
      created_by
      client_id
      client_name
      work_order_uuid
      detailed_data
      comments
      product {
        id
        name
      }
      description
      created_at
      updated_at
    }
  }
`;

/**
 * Defines the GetWorkers query
 */
export const getWorkersQuery = gql`
  query GetWorkers {
    workers {
      id
      name
    }
  }
`;

/**
 * Defines the CreateTimeSheet mutation
 */
export const createTimeSheetMutation = gql`
  mutation TimeSheet($status: String!) {
    createTimeSheet(status: $status) {
      id
      user_id
      status
      start_date
      end_date
    }
  }
`;

/**
 * Defines the UpdateTimesheetByAdmin mutation
 */
export const updateTimesheetByAdmin = gql`
  mutation TimeSheet($status: String!, $user_id: Int!) {
    createTimeSheet(status: $status, user_id: $user_id) {
      id
      user_id
      status
      start_date
      end_date
    }
  }
`;

/**
 * Defines the GetTimeSheet query
 */
export const getTimeSheetQuery = gql`
  query TimeSheets($end_date: String) {
    timesheets(end_date: $end_date) {
      id
      status
      start_date
      end_date
    }
  }
`;

/**
 * Defines the GetWorkOrdersList query
 */
export const getWorkOrdersListQuery = gql`
  query WorkOrderDraftLists($created_at: String) {
    WorkOrderDraftLists(created_at: $created_at) {
      id
      account_id
      organization_id
      settings
      created_at
      updated_at
    }
  }
`;
