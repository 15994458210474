/**
 * Imports types
 */
import {
  DeleteReportApiCall,
  ApiError,
  DeleteReportResponse
} from "./DeleteReport.types";
import { AxiosError } from "axios";

/**
 * Handles making the api call
 */
export const DeleteReport: DeleteReportApiCall =
  (api) => async (reportId, onSuccess, onError) => {
    try {
      const response = await api.delete<DeleteReportResponse>(
        `/reports/${reportId}`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      const requestError = error as AxiosError;

      if (onError && requestError.response) {
        const { response } = requestError;
        const errorResponse = response.data as ApiError;
        onError(errorResponse);
      }
    }
  };
