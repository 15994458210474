/**
 * Imports styled components
 */
import { Container, Spinner } from "./SuspenseFallback.styles";

/**
 * Displays the component
 */
export const SuspenseFallback: React.FC = () => {
  return (
    <Container>
      <Spinner>
        <div />
        <div />
        <div />
        <div />
      </Spinner>
    </Container>
  );
};
