import { createContext } from "react";

/**
 * Imports types
 */
import { Appointment, FilterModel } from "../../types";
import {
  ChangeSet,
  Resource,
  AppointmentModel,
} from "@devexpress/dx-react-scheduler";
import {
  ConfirmGuestAppointmentBody,
  ConfirmGuestAppointmentResponse,
  UpdateAppointmentStatusResponse,
} from "../../hooks/useApi";

/**
 * Imports constants
 */
import { DEFAULT_CALENDAR_ACTIVE_VIEW } from "../../constants";

/**
 * Defines the form body
 */
export interface FormBody {
  withGrouping: boolean;
  appointmentGroups: number[];
}

/**
 * Defines the initialize calendar props interface
 */
export interface CalendarInitProps {
  organizationId?: string | number;
  groups?: number[];
  withGrouping?: boolean;
  appointments: Appointment[];
}

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  view: "today" | "calendar";
  withGrouping: boolean;
  loading: boolean;
  updating: boolean;
  appointmentDeleted: boolean;
  activeOrg?: string | number;
  todayAppointments: Appointment[];
  appointments: Appointment[];
  calendarData: AppointmentModel[];
  currentDate: Date;
  filterModels: FilterModel[];
  resources: Resource[];
  calendarActiveView: string;
  activeGroups: number[];
  includeGroupless?: boolean;
  setIncludeGroupless: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  setWithGrouping: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setActiveGroups: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  changeView: (value: "today" | "calendar") => void;
  handleCalendarChange: (changes: ChangeSet) => void;
  changeDate: (currentDate: Date) => void;
  changeActiveOrganization: (organizationId: number) => void;
  setAppointments: React.Dispatch<React.SetStateAction<Appointment[]>>;
  setCalendarActiveView: React.Dispatch<React.SetStateAction<string>>;
  normalizeDate: (formattedDate: string) => string | undefined;
  updateAppointmentStatus: (
    appointmentId: number,
    status: string,
    callback?: (response: UpdateAppointmentStatusResponse) => void,
  ) => Promise<void>;
  deleteAppointment: (
    appointmentId: number,
    callback?: () => void,
  ) => Promise<void>;
  addAppointmentToCalendar: (appointment: Appointment) => void;
  updateAppointmentInCalendar: (appointment: Appointment) => void;
  setCalendarData: React.Dispatch<React.SetStateAction<AppointmentModel[]>>;
  initializeCalendar: (props?: CalendarInitProps) => void;
  confirmGuestAppointment: (
    appointmentId: number,
    appointment: ConfirmGuestAppointmentBody,
    callback?:
      | ((response: ConfirmGuestAppointmentResponse) => void)
      | undefined,
  ) => Promise<void>;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  view: "today",
  withGrouping: false,
  loading: false,
  updating: false,
  appointmentDeleted: false,
  includeGroupless: false,
  setIncludeGroupless: () => {},
  activeOrg: 0,
  todayAppointments: [],
  appointments: [],
  calendarData: [],
  resources: [],
  activeGroups: [],
  changeView: () => {},
  changeDate: () => {},
  currentDate: new Date(),
  filterModels: [],
  calendarActiveView: DEFAULT_CALENDAR_ACTIVE_VIEW,
  handleCalendarChange: () => {},
  changeActiveOrganization: () => {},
  setAppointments: () => {},
  setCalendarActiveView: () => {},
  updateAppointmentStatus: () => new Promise((res) => res()),
  deleteAppointment: () => new Promise((res) => res()),
  addAppointmentToCalendar: () => {},
  updateAppointmentInCalendar: () => {},
  setActiveGroups: () => {},
  setWithGrouping: () => {},
  normalizeDate: () => "",
  setCalendarData: () => {},
  initializeCalendar: () => {},
  confirmGuestAppointment: () => new Promise((res) => res()),
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
