import { useState, useEffect } from "react";

/**
 * Imports hooks
 */
import { useStatisticsUtils, useSelector, useActions } from "../../hooks";

/**
 * Imports types
 */
import { Statistics, StatsCardItem, StatsItem } from "../../types";

/**
 * Provides state management for the dashboard page statistics
 */
export const useStatistics = () => {
  const { statistics } = useSelector((state) => state.workOverview);
  const { updateStatistics } = useActions();

  /**
   * Gets the statistics utils
   */
  const {
    getDefaultValues,
    getWeeklyTotal,
    getMonthlyTotal,
    getCurrentProgress,
    getPastProgress,
  } = useStatisticsUtils();

  /**
   * Initializes the stats
   */
  const [stats, setStats] = useState<StatsCardItem[]>(
    statistics || getDefaultValues(),
  );

  /**
   * Gets the statistics
   */
  const requestStatistics = (data: {
    items: { current: Statistics; past: Statistics };
  }) => {
    const { current, past } = data.items;

    /**
     * Gets the weekly and monthly total added values
     */
    const weeklyTotal = getWeeklyTotal(current, past);
    const monthlyTotal = getMonthlyTotal(current, past);

    /**
     * Defines the current weekly stats
     */
    const currentWeekly: StatsItem = {
      type: current.week.type,
      total: current.week.progress,
      progress: getCurrentProgress(weeklyTotal.current, weeklyTotal.past),
      values: current.week.values,
      timeInterval: current.week.timeInterval,
    };

    /**
     * Defines the past weekly stats
     */
    const pastWeekly: StatsItem = {
      type: past.week.type,
      total: past.week.progress,
      progress: getPastProgress(weeklyTotal.current, weeklyTotal.past),
      values: past.week.values,
      timeInterval: past.week.timeInterval,
    };

    /**
     * Defines the current monthly stats
     */
    const currentMonthly: StatsItem = {
      type: current.month.type,
      total: current.month.progress,
      progress: getCurrentProgress(monthlyTotal.current, monthlyTotal.past),
      values: current.month.values,
      timeInterval: current.month.timeInterval,
    };

    /**
     * Defines the past monthly stats
     */
    const pastMonthly: StatsItem = {
      type: past.month.type,
      total: past.month.progress,
      progress: getPastProgress(monthlyTotal.current, monthlyTotal.past),
      values: past.month.values,
      timeInterval: past.month.timeInterval,
    };

    /**
     * Defines the weekly stats
     */
    const weeklyStats: StatsCardItem = {
      current: currentWeekly,
      past: pastWeekly,
      active: "current",
    };

    /**
     * Defines the monthly stats
     */
    const monthlyStats: StatsCardItem = {
      current: currentMonthly,
      past: pastMonthly,
      active: "current",
    };

    setStats([weeklyStats, monthlyStats]);
    updateStatistics([weeklyStats, monthlyStats]);
  };

  /**
   * Handles getting the statistics
   */
  useEffect(() => {
    if (statistics && statistics.length > 0) {
      setStats(statistics);
    }
    // eslint-disable-next-line
  }, []);

  return {
    stats,
    setStats,
    requestStatistics,
  };
};
