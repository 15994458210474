/**
 * Imports typography
 */
import { typography } from "../common";

/**
 * Imports palettes
 */
import { palette as JavaseaPalette } from "../Javasea/palette";
import { palette as LemontagePalette } from "../Lemontage/palette";

/**
 * Imports types
 */
import { Theme as MuiThemeBase, CSSObject } from "@mui/material/styles";

/**
 * Defines a minimal version of a theme.
 */
interface Theme {
  palette: typeof JavaseaPalette | typeof LemontagePalette;
}

/**
 * Handles generating mixins based on current active theme / palette
 */
export const generateMixins = (theme: Theme) => {
  /**
   * Styles the outlined input root
   */
  const OutlinedInput_root = {
    color: theme.palette.base.dark,
    fontSize: 16,
    lineHeight: 1.5,
    borderRadius: 4,
    background: theme.palette.common.white
  };

  /**
   * Styles the outlined input root in focused state
   */
  const OutlinedInput_rootFocused = {
    outline: `3px solid ${theme.palette.base.grayLight}`
  };

  /**
   * Styles the outlined input root in error state
   */
  const OutlinedInput_rootError = {
    background: theme.palette.error[195],
    "& .MuiOutlinedInput-notchedOutline": {
      outline: 0,
      borderColor: theme.palette.error[100],
      backgroundColor: "transparent",
      borderWidth: 1
    },
    "& .MuiOutlinedInput-input": {
      background: theme.palette.error[195],
      "&:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 50px ${theme.palette.error[195]} inset`
      },
      "&::placeholder": {
        color: theme.palette.error[100]
      }
    },
    "&.Mui-focused": {
      backgroundColor: theme.palette.common.white,
      outline: `3px solid ${theme.palette.error[180]}`,
      "& .MuiOutlinedInput-input": {
        background: theme.palette.common.white,
        "&:-webkit-autofill": {
          WebkitBoxShadow: `0 0 0 50px ${theme.palette.common.white} inset`
        },
        "&::placeholder": {
          color: theme.palette.base.grayLight
        },
        "& .InputSelect-placeholder": {
          color: theme.palette.base.grayLight
        }
      }
    },
    "&.Mui-disabled": {
      "& .MuiOutlinedInput-input": {
        color: theme.palette.error[140],
        WebkitTextFillColor: theme.palette.error[140],
        "&::placeholder": {
          color: theme.palette.error[140]
        }
      }
    }
  };

  /**
   * Styles the outlined input root in disabled state
   */
  const OutlinedInput_rootDisabled = {
    backgroundColor: theme.palette.base.grayThin,
    color: theme.palette.base.gray,
    WebkitTextFillColor: theme.palette.base.gray,
    "& .MuiOutlinedInput-input": {
      backgroundColor: theme.palette.base.grayThin,
      color: theme.palette.base.gray,
      WebkitTextFillColor: theme.palette.base.gray,
      WebkitBoxShadow: `0 0 0 50px ${theme.palette.base.grayThin} inset`
    }
  };

  /**
   * Styles the outlined input notched outlined in focused state
   */
  const OutlinedInput_notchedOutlineFocused = {
    outline: 0,
    borderWidth: 1,
    borderColor: theme.palette.base.dark
  };

  /**
   * Styles the outlined input
   */
  const OutlinedInput_input = {
    borderRadius: 2
  };

  /**
   * Styles the outlined input placeholder
   */
  const OutlinedInput_placeholder = {
    textOverflow: "ellipsis !important",
    color: theme.palette.base.gray,
    opacity: 1,
    fontWeight: 400
  };

  /**
   * Styles the outlined input placeholder in focused state
   */
  const OutlinedInput_placeholderFocus = {
    color: theme.palette.base.grayLight
  };

  /**
   * Styles the outlined input browser autofill
   */
  const OutlinedInput_webkitAutofill = {
    fontSize: 16,
    fontFamily: typography.fontFamily,
    boxShadow: `0 0 0 50px ${theme.palette.common.white} inset`,
    borderRadius: 0,
    WebkitBoxShadow: `0 0 0 50px ${theme.palette.common.white} inset`,
    WebkitTextFillColor: theme.palette.base.dark
  };

  /**
   * Styles the outlined input browser autofill in focused state
   */
  const OutlinedInput_webkitAutofillFocus = {
    boxShadow: `0 0 0 50px ${theme.palette.common.white} inset`,
    borderRadius: 0,
    WebkitBoxShadow: `0 0 0 50px ${theme.palette.common.white} inset`,
    WebkitTextFillColor: theme.palette.base.dark
  };

  /**
   * Styles the Input select paper component
   */
  const InputSelect_paper = (
    minWidth: number | string,
    itemHeight: number,
    itemPaddingTop: number,
    withIcon?: boolean
  ) => {
    return {
      boxShadow: "none",
      border: `1px solid ${theme.palette.base.grayLight}`,
      borderTop: "none",
      minWidth: minWidth,
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      transform: withIcon ? "translate(-20px)" : "translate(0px)"
    };
  };

  const Sidebar_expanded = (theme: MuiThemeBase): CSSObject => ({
    width: 300,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowY: "initial"
  });

  const Sidebar_minified = (theme: MuiThemeBase): CSSObject => ({
    width: 80,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    overflowY: "auto"
  });

  /**
   * Styles the outlined input browser autofill in focused state
   */
  const InfoCardContainer_root = {
    background: "#FCFCFE",
    border: `1px solid ${theme.palette.base.grayThin}`,
    borderRadius: 4,
    padding: 12
  };

  /**
   * Styles the row options icon and the table row hover background color
   */
  const TableRowOptions = {
    "& .RowOptionsIcon": {
      height: 20,
      width: 20,
      color: theme.palette.base.text,
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.base.grayDark
      }
    },
    "&.MuiTableRow-hover:hover": {
      background: "#FCFCFE"
    }
  };

  /**
   * Styles the info icon used in table rows
   */
  const TableInfoIcon = {
    "& .InfoIcon": {
      height: 18,
      width: 18,
      color: theme.palette.base.text,
      marginLeft: 5,
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.base.grayDark
      }
    }
  };

  return {
    InfoCardContainer_root,
    OutlinedInput_root,
    OutlinedInput_rootFocused,
    OutlinedInput_rootError,
    OutlinedInput_rootDisabled,
    OutlinedInput_input,
    OutlinedInput_webkitAutofill,
    OutlinedInput_webkitAutofillFocus,
    OutlinedInput_placeholder,
    OutlinedInput_placeholderFocus,
    OutlinedInput_notchedOutlineFocused,
    InputSelect_paper,
    Sidebar_expanded,
    Sidebar_minified,
    TableRowOptions,
    TableInfoIcon
  };
};
