import { useEffect } from "react";

/**
 * Imports the context
 */
import { context, ProviderProps, ProviderValues } from "./Context";

/**
 * Imports hooks
 */
import {
  useActions,
  useSelector,
  useUserUtils,
  useFilterModelsUtils,
} from "../index";

/**
 * Imports constants
 */
import { MAX_PAGE_SIZE } from "../../constants";

/**
 * Imports types
 */
import { FilterModel } from "../../types";
import { AxiosResponseError, SearchProductsRequest } from "../../redux";

/**
 * Imports queries
 */
import { useLazyGetProductsListQuery } from "../../redux";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const SearchProductsProvider: React.FC<ProviderProps> = (props) => {
  const { children, enableAdmin, exclude } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the products search query
   */
  const [searchProducts, { isLoading }] = useLazyGetProductsListQuery();

  /**
   * Gets the account state
   */
  const { userInitialized, products } = useSelector((state) => state.account);

  /**
   * Gets user related utils
   */
  const { isUserAdmin, getUserOrganization } = useUserUtils();

  /**
   * Gets filter model utils
   */
  const { createFilter } = useFilterModelsUtils();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Returns the default search params
   */
  const getDefaultParams = () => {
    /**
     * Initializes the filter models
     */
    const models: FilterModel[] = [];

    /**
     * Gets the user's organization
     */
    const organization = getUserOrganization();

    models.push(
      createFilter({
        field: "organization_id",
        selected: organization && organization.id,
        type: "dropdown",
      }),
    );

    if (exclude && exclude.includes("expendables")) {
      models.push(
        createFilter({
          field: "is_expendable",
          selected: false,
          type: "boolean",
        }),
      );
    }

    if (exclude && exclude.includes("services")) {
      models.push(
        createFilter({
          field: "is_service",
          selected: false,
          type: "boolean",
        }),
      );
    }

    /**
     * Removes the organization id filter for admins
     */
    if (enableAdmin && isUserAdmin()) {
      models.shift();
    }

    return {
      models,
      order_by: "price",
      order_dir: "asc",
      page_count: 1,
      page_size: MAX_PAGE_SIZE,
    } as SearchProductsRequest;
  };

  /**
   * Handles getting the products
   */
  const getProducts = async () => {
    try {
      await searchProducts();
    } catch (err: unknown) {
      const error = err as AxiosResponseError;

      dispatchMessage({
        message: error.errorMessage ? error.errorMessage : "Unknown Error",
        severity: "error",
        autoClose: 10000,
      });
    }
  };

  /**
   * Gets the products if there are none
   */
  useEffect(() => {
    if (userInitialized && products.length < 1) {
      getProducts();
    }
    // eslint-disable-next-line
  }, [products, userInitialized]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    loading: isLoading,
    products,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
