import { cloneElement, Fragment } from "react";

/**
 * Imports assets
 */
import { ReactComponent as UKFlag } from "./icons/uk_flag.svg";
import { ReactComponent as ROFlag } from "./icons/ro_flag.svg";
import { ReactComponent as HUFlag } from "./icons/hu_flag.svg";
import { ReactComponent as TireIcon } from "./icons/tire-solid.svg";

/**
 * Imports types
 */
import { SVGList, SvgIconProps } from "./SvgIcon.types";

/**
 * Defines the SVG List
 */
const svgList: SVGList = {
  en_en: UKFlag,
  en_ro: ROFlag,
  en_hu: HUFlag,
  TireIcon
};

/**
 * Displays the component
 */
export const SvgIcon: React.FC<SvgIconProps> = (props) => {
  const { name, className, ...otherProps } = props;

  /**
   * Defines the dynamic component
   */
  const Component = svgList[name];

  return cloneElement(
    <Fragment />,
    { className, ...otherProps },
    <Component />
  );
};
