/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  TransferWorkOrderApiCall,
  TransferWorkOrderResponse
} from "./TransferWorkOrder.types";

/**
 * Handles making the api call
 */
export const TransferWorkOrder: TransferWorkOrderApiCall =
  (api) => async (workOrderId, organizationId, onSuccess, onError) => {
    try {
      const response = await api.put<TransferWorkOrderResponse>(
        `/work-orders/${workOrderId}/transfer/organization/${organizationId}`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
