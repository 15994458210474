/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  UpdateCarTypeApiCall,
  UpdateCarTypeResponse
} from "./UpdateCarType.types";

/**
 * Handles making the api call
 */
export const UpdateCarType: UpdateCarTypeApiCall =
  (api) => async (carTypeId, body, onSuccess, onError) => {
    try {
      const response = await api.put<UpdateCarTypeResponse>(
        `/car-types/${carTypeId}`,
        fromCamelToSnake(body)
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
