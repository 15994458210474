/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  DeleteAppointmentGroupApiCall,
  DeleteAppointmentGroupResponse
} from "./DeleteAppointmentGroup.types";

/**
 * Handles making the api call
 */
export const DeleteAppointmentGroup: DeleteAppointmentGroupApiCall =
  (api) => async (appointmentGroupId, onSuccess, onError) => {
    try {
      const response = await api.delete<DeleteAppointmentGroupResponse>(
        `/appointment-groups/${appointmentGroupId}`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
