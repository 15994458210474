/**
 * External imports
 */
import { formatDistanceToNow, differenceInSeconds } from "date-fns";

/**
 * Imports hooks
 */
import { useTranslation } from "..";

/**
 * Provides utility functions for timers and dates
 */
export const useTimerUtils = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Returns the difference in hours, minutes, seconds
   */
  const getTimeDifference = (startDate: Date, endDate: Date) => {
    if (!startDate || !endDate)
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    /**
     * Gets the total seconds between the times
     */
    let delta = Math.abs(startDate.getTime() - endDate.getTime()) / 1000;

    /**
     * Calculate and substract whole days
     */
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    /**
     * Calculates and substracts whole hours
     */
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    /**
     * Calculates and substracts whole minutes
     */
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    /**
     * Remaining seconds
     */
    const seconds = Math.floor(delta % 60);

    return {
      days,
      hours,
      minutes,
      seconds
    };
  };

  /**
   * Formats the time unit (hours, minutes, seconds)
   * Eg: 1:10:2 => 01:10:02
   */
  const formatTimeUnit = (timeUnit: number) => {
    return timeUnit > 9 ? timeUnit : `0${timeUnit}`;
  };

  /**
   * Returns the offset time stamp
   */
  const getOffsetTimestamp = (offsetDate: Date) => {
    const stopwatchOffset = new Date();

    stopwatchOffset.setSeconds(
      stopwatchOffset.getSeconds() +
        differenceInSeconds(new Date(), new Date(offsetDate))
    );

    return stopwatchOffset;
  };

  /**
   * Returns the time elapsed
   */
  const getTimeElapsed = (time: Date) => {
    let diffInSeconds = differenceInSeconds(new Date(), new Date(time));
    if (diffInSeconds < 60) {
      return `${t("Now")} 1 minut.`;
    } else {
      return `${t("Now")} ${formatDistanceToNow(new Date(time))}`;
    }
  };

  return {
    formatTimeUnit,
    getTimeElapsed,
    getTimeDifference,
    getOffsetTimestamp
  };
};
