/**
 * Imports auth utils
 */
import { loadState } from "../../utils";

/**
 * Imports types
 */
import type { DetailedReportsConfig } from "../../../types";

/**
 * Imports constants
 */
import { DETAILED_REPORTS } from "../../../constants";

export const preloadDetailsReportsConfig = () => {
  const storageState = loadState(DETAILED_REPORTS) as DetailedReportsConfig;

  if (storageState) return storageState;
  return {
    monthly: false,
    company: true,
    waste: false,
    advanced: false,
  };
};
