/**
 * Imports transformers
 */
import { transformWorkersResponse } from "../../transformers";

/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetWorkOrdersListResponse,
  GetWorkOrdersListApiCall
} from "./GetWorkOrdersList.types";

/**
 * Imports queries
 */
import { getWorkOrdersListQuery } from "../queries";

/**
 * Handles making the api call
 */
export const GetWorkOrdersList: GetWorkOrdersListApiCall =
  (api) => async (queryVariables, onSuccess, onError) => {
    try {
      const response = await api.query<GetWorkOrdersListResponse>({
        query: getWorkOrdersListQuery,
        variables: queryVariables
      });

      if (response) {
        onSuccess(transformWorkersResponse(response), response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
