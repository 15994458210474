/**
 * Imports types
 */
import { LocalSearchProps } from "./useSearchUtils.types";

/**
 * Provides utility functions for local search and filter
 */
export const useSearchUtils = () => {
  /**
   * Efficiently filters out an array
   */
  const localSearch = (props: LocalSearchProps) => {
    const { source, searchPool, linkKey, searchValue } = props;

    /**
     * Filters from the search pool
     * Returns an array with the linked key property (ids generally)
     */
    const filterResult = searchPool
      .filter((item) => {
        const values = Object.values(item).toString().toLowerCase();
        return values.includes(searchValue.toLowerCase());
      })
      .map((resultItem) => resultItem[linkKey]);

    /**
     * Rebuilds the source array by using the link key
     */
    return source.filter((item) => {
      return filterResult.includes(item[linkKey]);
    });
  };

  return {
    localSearch
  };
};
