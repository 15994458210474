import { useState, useEffect, useMemo } from "react";

/**
 * External imports
 */
import { cloneDeep } from "lodash";

/**
 * Imports types
 */
import {
  TechnicalDrawingV2Props,
  Data,
  TyreData,
  AxisData,
} from "../../components/TechnicalDrawingV2";

/**
 * Defines the default axis data
 */
const defaultAxisData: AxisData = {
  leftInner: false,
  leftOuter: false,
  rightInner: false,
  rightOuter: false,
};

/**
 * Returns the default data
 */
export const getDefaultData = () => {
  return () => {
    const initialSelected: any = {};

    for (let axis = 1; axis <= 5; axis++) {
      initialSelected[`axis${axis}`] = { ...defaultAxisData };
    }

    return initialSelected as Data;
  };
};

/**
 * Provides state management for the technical drawing component.
 */
export const useTechnicalDrawingV2 = (props: TechnicalDrawingV2Props) => {
  const { value, variant, disabled, onChange } = props;

  /**
   * Initializes the state
   */
  const [selected, setSelected] = useState<Data>(getDefaultData());

  /**
   * Handles the change
   */
  const handleChange = (axis: number, tyre: string, value: boolean) => {
    if (disabled) return;

    const clonedSelected = cloneDeep(selected);
    const key = `axis${axis}` as keyof Data;
    const data = clonedSelected[key] as AxisData;

    data[tyre as keyof AxisData] = value;

    setSelected(clonedSelected);
    if (onChange) onChange(clonedSelected);
  };

  /**
   * Returns 2 axis 4 tyres data
   */
  const get2Axis4TyresData = (selected: Data) => {
    return [
      {
        id: "Axis1Left",
        selected: selected.axis1.leftOuter,
        axis: 1,
        value: "leftOuter",
      },
      {
        id: "Axis1Right",
        selected: selected.axis1.rightOuter,
        axis: 1,
        value: "rightOuter",
      },
      {
        id: "Axis2Left",
        selected: selected.axis2.leftOuter,
        axis: 2,
        value: "leftOuter",
      },
      {
        id: "Axis2Right",
        selected: selected.axis2.rightOuter,
        axis: 2,
        value: "rightOuter",
      },
    ] as TyreData[];
  };

  /**
   * Returns 2 axis 6 tyres data
   */
  const get2Axis6TyresData = (selected: Data) => {
    return [
      {
        id: "Axis1Left",
        selected: selected.axis1.leftOuter,
        axis: 1,
        value: "leftOuter",
      },
      {
        id: "Axis1Right",
        selected: selected.axis1.rightOuter,
        axis: 1,
        value: "rightOuter",
      },
      {
        id: "Axis2LeftInner",
        selected: selected.axis2.leftInner,
        axis: 2,
        value: "leftInner",
      },
      {
        id: "Axis2LeftOuter",
        selected: selected.axis2.leftOuter,
        axis: 2,
        value: "leftOuter",
      },
      {
        id: "Axis2RightInner",
        selected: selected.axis2.rightInner,
        axis: 2,
        value: "rightInner",
      },
      {
        id: "Axis2RightOuter",
        selected: selected.axis2.rightOuter,
        axis: 2,
        value: "rightOuter",
      },
    ] as TyreData[];
  };

  /**
   * Returns 3 axis 8 tyres data
   */
  const get3Axis8TyresData = (selected: Data) => {
    return [
      {
        id: "Axis1Left",
        selected: selected.axis1.leftOuter,
        axis: 1,
        value: "leftOuter",
      },
      {
        id: "Axis1Right",
        selected: selected.axis1.rightOuter,
        axis: 1,
        value: "rightOuter",
      },
      {
        id: "Axis2LeftInner",
        selected: selected.axis2.leftInner,
        axis: 2,
        value: "leftInner",
      },
      {
        id: "Axis2LeftOuter",
        selected: selected.axis2.leftOuter,
        axis: 2,
        value: "leftOuter",
      },
      {
        id: "Axis2RightInner",
        selected: selected.axis2.rightInner,
        axis: 2,
        value: "rightInner",
      },
      {
        id: "Axis2RightOuter",
        selected: selected.axis2.rightOuter,
        axis: 2,
        value: "rightOuter",
      },
      {
        id: "Axis3Left",
        selected: selected.axis3.leftOuter,
        axis: 3,
        value: "leftOuter",
      },
      {
        id: "Axis3Right",
        selected: selected.axis3.rightOuter,
        axis: 3,
        value: "rightOuter",
      },
    ] as TyreData[];
  };

  /**
   * Returns 3 axis 10 tyres data
   */
  const get3Axis10TyresData = (selected: Data) => {
    return [
      {
        id: "Axis1Left",
        selected: selected.axis1.leftOuter,
        axis: 1,
        value: "leftOuter",
      },
      {
        id: "Axis1Right",
        selected: selected.axis1.rightOuter,
        axis: 1,
        value: "rightOuter",
      },
      {
        id: "Axis2LeftInner",
        selected: selected.axis2.leftInner,
        axis: 2,
        value: "leftInner",
      },
      {
        id: "Axis2LeftOuter",
        selected: selected.axis2.leftOuter,
        axis: 2,
        value: "leftOuter",
      },
      {
        id: "Axis2RightInner",
        selected: selected.axis2.rightInner,
        axis: 2,
        value: "rightInner",
      },
      {
        id: "Axis2RightOuter",
        selected: selected.axis2.rightOuter,
        axis: 2,
        value: "rightOuter",
      },
      {
        id: "Axis3LeftInner",
        selected: selected.axis3.leftInner,
        axis: 3,
        value: "leftInner",
      },
      {
        id: "Axis3LeftOuter",
        selected: selected.axis3.leftOuter,
        axis: 3,
        value: "leftOuter",
      },
      {
        id: "Axis3RightInner",
        selected: selected.axis3.rightInner,
        axis: 3,
        value: "rightInner",
      },
      {
        id: "Axis3RightOuter",
        selected: selected.axis3.rightOuter,
        axis: 3,
        value: "rightOuter",
      },
    ] as TyreData[];
  };

  /**
   * Returns 4 axis 12 tyres data
   */
  const get4Axis12TyresData = (selected: Data) => {
    return [
      {
        id: "Axis1Left",
        selected: selected.axis1.leftOuter,
        axis: 1,
        value: "leftOuter",
      },
      {
        id: "Axis1Right",
        selected: selected.axis1.rightOuter,
        axis: 1,
        value: "rightOuter",
      },
      {
        id: "Axis2Left",
        selected: selected.axis2.leftOuter,
        axis: 2,
        value: "leftOuter",
      },
      {
        id: "Axis2Right",
        selected: selected.axis2.rightOuter,
        axis: 2,
        value: "rightOuter",
      },
      {
        id: "Axis3RightInner",
        selected: selected.axis3.rightInner,
        axis: 3,
        value: "rightInner",
      },
      {
        id: "Axis3RightOuter",
        selected: selected.axis3.rightOuter,
        axis: 3,
        value: "rightOuter",
      },
      {
        id: "Axis3LeftInner",
        selected: selected.axis3.leftInner,
        axis: 3,
        value: "leftInner",
      },
      {
        id: "Axis3LeftOuter",
        selected: selected.axis3.leftOuter,
        axis: 3,
        value: "leftOuter",
      },
      {
        id: "Axis4LeftInner",
        selected: selected.axis4.leftInner,
        axis: 4,
        value: "leftInner",
      },
      {
        id: "Axis4LeftOuter",
        selected: selected.axis4.leftOuter,
        axis: 4,
        value: "leftOuter",
      },
      {
        id: "Axis4RightInner",
        selected: selected.axis4.rightInner,
        axis: 4,
        value: "rightInner",
      },
      {
        id: "Axis4RightOuter",
        selected: selected.axis4.rightOuter,
        axis: 4,
        value: "rightOuter",
      },
    ] as TyreData[];
  };

  /**
   * Returns 5 axis 12 tyres data
   */
  const get5Axis12TyresData = (selected: Data) => {
    return [
      {
        id: "Axis1Left",
        selected: selected.axis1.leftOuter,
        axis: 1,
        value: "leftOuter",
      },
      {
        id: "Axis1Right",
        selected: selected.axis1.rightOuter,
        axis: 1,
        value: "rightOuter",
      },
      {
        id: "Axis2LeftOuter",
        selected: selected.axis2.leftOuter,
        axis: 2,
        value: "leftOuter",
      },
      {
        id: "Axis2LeftInner",
        selected: selected.axis2.leftInner,
        axis: 2,
        value: "leftInner",
      },
      {
        id: "Axis2RightOuter",
        selected: selected.axis2.rightOuter,
        axis: 2,
        value: "rightOuter",
      },
      {
        id: "Axis2RightInner",
        selected: selected.axis2.rightInner,
        axis: 2,
        value: "rightInner",
      },
      {
        id: "Axis3Left",
        selected: selected.axis3.leftOuter,
        axis: 3,
        value: "leftOuter",
      },
      {
        id: "Axis3Right",
        selected: selected.axis3.rightOuter,
        axis: 3,
        value: "rightOuter",
      },
      {
        id: "Axis4Left",
        selected: selected.axis4.leftOuter,
        axis: 4,
        value: "leftOuter",
      },
      {
        id: "Axis4Right",
        selected: selected.axis4.rightOuter,
        axis: 4,
        value: "rightOuter",
      },
      {
        id: "Axis5Left",
        selected: selected.axis5.leftOuter,
        axis: 5,
        value: "leftOuter",
      },
      {
        id: "Axis5Right",
        selected: selected.axis5.rightOuter,
        axis: 5,
        value: "rightOuter",
      },
    ] as TyreData[];
  };

  /**
   * Returns 5 axis 14 tyres data
   */
  const get5Axis14TyresData = (selected: Data) => {
    return [
      {
        id: "Axis1Left",
        selected: selected.axis1.leftOuter,
        axis: 1,
        value: "leftOuter",
      },
      {
        id: "Axis1Right",
        selected: selected.axis1.rightOuter,
        axis: 1,
        value: "rightOuter",
      },
      {
        id: "Axis2Left",
        selected: selected.axis2.leftOuter,
        axis: 2,
        value: "leftOuter",
      },
      {
        id: "Axis2Right",
        selected: selected.axis2.rightOuter,
        axis: 2,
        value: "rightOuter",
      },
      {
        id: "Axis3LeftInner",
        selected: selected.axis3.leftInner,
        axis: 3,
        value: "leftInner",
      },
      {
        id: "Axis3RightInner",
        selected: selected.axis3.rightInner,
        axis: 3,
        value: "rightInner",
      },
      {
        id: "Axis3LeftOuter",
        selected: selected.axis3.leftOuter,
        axis: 3,
        value: "leftOuter",
      },
      {
        id: "Axis3RightOuter",
        selected: selected.axis3.rightOuter,
        axis: 3,
        value: "rightOuter",
      },
      {
        id: "Axis4LeftInner",
        selected: selected.axis4.leftInner,
        axis: 4,
        value: "leftInner",
      },
      {
        id: "Axis4RightInner",
        selected: selected.axis4.rightInner,
        axis: 4,
        value: "rightInner",
      },
      {
        id: "Axis4LeftOuter",
        selected: selected.axis4.leftOuter,
        axis: 4,
        value: "leftOuter",
      },
      {
        id: "Axis4RightOuter",
        selected: selected.axis4.rightOuter,
        axis: 4,
        value: "rightOuter",
      },
      {
        id: "Axis5Left",
        selected: selected.axis5.leftOuter,
        axis: 5,
        value: "leftOuter",
      },
      {
        id: "Axis5Right",
        selected: selected.axis5.rightOuter,
        axis: 5,
        value: "rightOuter",
      },
    ] as TyreData[];
  };

  /**
   * Returns 5 axis 18 tyres data
   */
  const get5Axis18TyresData = (selected: Data) => {
    return [
      {
        id: "Axis1LeftInner",
        selected: selected.axis1.leftInner,
        axis: 1,
        value: "leftInner",
      },
      {
        id: "Axis1RightInner",
        selected: selected.axis1.rightInner,
        axis: 1,
        value: "rightInner",
      },
      {
        id: "Axis1LeftOuter",
        selected: selected.axis1.leftOuter,
        axis: 1,
        value: "leftOuter",
      },
      {
        id: "Axis1RightOuter",
        selected: selected.axis1.rightOuter,
        axis: 1,
        value: "rightOuter",
      },
      {
        id: "Axis2LeftInner",
        selected: selected.axis2.leftInner,
        axis: 2,
        value: "leftInner",
      },
      {
        id: "Axis2RightInner",
        selected: selected.axis2.rightInner,
        axis: 2,
        value: "rightInner",
      },
      {
        id: "Axis2LeftOuter",
        selected: selected.axis2.leftOuter,
        axis: 2,
        value: "leftOuter",
      },
      {
        id: "Axis2RightOuter",
        selected: selected.axis2.rightOuter,
        axis: 2,
        value: "rightOuter",
      },
      {
        id: "Axis3LeftInner",
        selected: selected.axis3.leftInner,
        axis: 3,
        value: "leftInner",
      },
      {
        id: "Axis3RightInner",
        selected: selected.axis3.rightInner,
        axis: 3,
        value: "rightInner",
      },
      {
        id: "Axis3LeftOuter",
        selected: selected.axis3.leftOuter,
        axis: 3,
        value: "leftOuter",
      },
      {
        id: "Axis3RightOuter",
        selected: selected.axis3.rightOuter,
        axis: 3,
        value: "rightOuter",
      },
      {
        id: "Axis4LeftInner",
        selected: selected.axis4.leftInner,
        axis: 4,
        value: "leftInner",
      },
      {
        id: "Axis4RightInner",
        selected: selected.axis4.rightInner,
        axis: 4,
        value: "rightInner",
      },
      {
        id: "Axis4LeftOuter",
        selected: selected.axis4.leftOuter,
        axis: 4,
        value: "leftOuter",
      },
      {
        id: "Axis4RightOuter",
        selected: selected.axis4.rightOuter,
        axis: 4,
        value: "rightOuter",
      },
      {
        id: "Axis5Left",
        selected: selected.axis5.leftOuter,
        axis: 5,
        value: "leftOuter",
      },
      {
        id: "Axis5Right",
        selected: selected.axis5.rightOuter,
        axis: 5,
        value: "rightOuter",
      },
    ] as TyreData[];
  };

  /**
   * Defines the tyres
   */
  const tyresData = useMemo(() => {
    switch (variant) {
      case "2x6":
        return get2Axis6TyresData(selected);
      case "3x8":
        return get3Axis8TyresData(selected);
      case "3x10":
        return get3Axis10TyresData(selected);
      case "4x12":
        return get4Axis12TyresData(selected);
      case "5x12":
        return get5Axis12TyresData(selected);
      case "5x14":
        return get5Axis14TyresData(selected);
      case "5x18":
        return get5Axis18TyresData(selected);
      default:
        return get2Axis4TyresData(selected);
    }
    // eslint-disable-next-line
  }, [selected, variant]);

  /**
   * Checks if the value is axis data
   */
  const isAxisData = (value: any): value is AxisData =>
    typeof value === "object" &&
    value !== null &&
    "leftInner" in value &&
    "leftOuter" in value &&
    "rightInner" in value &&
    "rightOuter" in value;

  /**
   * Checks if the value is valid
   */
  const isValidValue = (value: any): value is Data => {
    if (typeof value !== "object" || value === null) {
      return false;
    }

    for (let axis = 1; axis <= 5; axis++) {
      const axisKey = `axis${axis}` as keyof Data;
      if (!(axisKey in value) || !isAxisData(value[axisKey])) {
        return false;
      }
    }

    return true;
  };

  /**
   * Handles initialiazing the selected state
   */
  useEffect(() => {
    if (isValidValue(value)) setSelected(value);
    // eslint-disable-next-line
  }, []);

  return {
    tyresData,
    selected,
    handleChange,
  };
};
