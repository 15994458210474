import {forwardRef, cloneElement, createElement, ReactSVGElement} from "react";

/**
 * External Imports
 */
import { v4 as generate } from "uuid";

/**
 * Imports Font Awesome
 */
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from "@fortawesome/react-fontawesome";

/**
 * Imports components
 */
import { SvgIcon } from "../SvgIcon";

/**
 * Imports types
 */
import { IconProps } from "./Icon.types";

/**
 * Displays the component
 */
export const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const {
    icon,
    faIcon,
    svg,
    name,
    size,
    flip,
    className,
    onClick,
    onMouseEnter,
    onMouseLeave,
    ...otherProps
  } = props;

  /**
   * Handles rendering the icon
   */
  const renderIcon = () => {
    if (!icon && !faIcon && !svg && !name) return null;

    /**
     * Defines the base props
     */
    const baseProps = {
      onMouseEnter,
      onMouseLeave,
      onClick
    };

    /**
     * Returns custom svg icon
     */
    if (svg && name)
      return (
        <SvgIcon
          name={name}
          className={className || "SvgIcon-root"}
          {...otherProps}
        />
      );

    /**
     * Returns a Material UI Icon wrapped by a div container
     */
    if (icon) {
      return createElement(
        "div",
        {
          ...baseProps,
          ...otherProps,
          className: className || "Icon-root",
          ref
        },
        [cloneElement(icon, { key: generate() }, [])]
      );
    }

    /**
     * Returns a font awesome icon
     */
    if (faIcon)
      return (
        <FontAwesomeIcon
          {...baseProps}
          {...otherProps}
          ref={ref}
          icon={faIcon as FontAwesomeIconProps["icon"]}
          flip={flip}
          size={size || "1x"}
          className="FontAwesomeIcon-root"
        />
      );

    return null;
  };

  return renderIcon();
});
