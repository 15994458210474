/**
 * External imports
 */
import { cloneDeep } from "lodash";

/**
 * Imports types
 */
import {
  IKanbanColumn,
  UseKanbanProps,
  MoveResult,
  DragEndResult
} from "./useKanban.types";
import { DropResult } from "react-beautiful-dnd";

/**
 * Provides utility functions for work order kanban view
 */
export const useKanban = (props: UseKanbanProps) => {
  const { columns, onDragEnd } = props;

  /**
   * Handles building the drag result
   */
  const buildDragResult = (
    row: any,
    source: DropResult["source"],
    destination: DropResult["destination"],
    columns: IKanbanColumn[],
    updatedColumns: IKanbanColumn[]
  ) => {
    /**
     * Defines the indexes
     */
    const sourceIndex = +source.droppableId;
    const destinationIndex = +destination!.droppableId;

    return {
      row: row,
      oldOrder: source.index,
      oldGroup: columns[sourceIndex].group,
      newOrder: destination!.index,
      newGroup: columns[destinationIndex].group,
      columnIndex: destinationIndex,
      rowIndex: destination!.index,
      columns: updatedColumns
    } as DragEndResult;
  };

  /**
   * Handles reordering
   */
  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const rows = Array.from(list);
    const [removed] = rows.splice(startIndex, 1);
    rows.splice(endIndex, 0, removed);

    return { rows, removed };
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (
    source: any[],
    destination: any[],
    droppableSource: DropResult["source"],
    droppableDestination: DropResult["destination"]
  ) => {
    /**
     * Clones the source and the destination arrays
     */
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);

    /**
     * Gets the item from the source
     */
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    /**
     * Inserts the item in the destination
     */
    destClone.splice(droppableDestination!.index, 0, removed);

    /**
     * Initializes the rows
     */
    const result: MoveResult = {};

    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination!.droppableId] = destClone;

    return { result, removed };
  };

  /**
   * Handles updating the state on drag end
   */
  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    /**
     * Dropped outside the list
     */
    if (!destination) return;

    /**
     * Defines the indexes
     */
    const sourceIndex = +source.droppableId;
    const destinationIndex = +destination.droppableId;

    /**
     * Defines the updated columns
     */
    const updatedColumns = cloneDeep(columns);

    /**
     * Defines the source work orders
     */
    const sourceRows = columns[sourceIndex].rows;

    /**
     * Defines the destination work orders
     */
    const destinationRows = columns[destinationIndex].rows;

    /**
     * Handles reordering if the index is the same
     */
    if (sourceIndex === destinationIndex) {
      const { rows, removed } = reorder(
        sourceRows,
        source.index,
        destination.index
      );

      updatedColumns[sourceIndex].rows = rows;

      const dragResult = buildDragResult(
        removed,
        source,
        destination,
        columns,
        updatedColumns
      );

      onDragEnd(dragResult);
    } else {
      /**
       * Handles moving if the index is not the same
       */
      const { result, removed } = move(
        sourceRows,
        destinationRows,
        source,
        destination
      );

      updatedColumns[sourceIndex].rows = result[sourceIndex];
      updatedColumns[destinationIndex].rows = result[destinationIndex];

      const dragResult = buildDragResult(
        removed,
        source,
        destination,
        columns,
        updatedColumns
      );

      onDragEnd(dragResult);
    }
  };

  /**
   * Finds the row item by draggable id
   */
  const getItemByDraggableId = (
    columns: IKanbanColumn[],
    draggableId: string
  ) => {
    return columns
      .map((column) => column.rows)
      .flat()
      .find((row) => (row ? row.id.toString() === draggableId : false));
  };

  return {
    handleDragEnd,
    getItemByDraggableId
  };
};
