/**
 * External imports
 */
import { cloneDeep, findIndex } from "lodash";

/**
 * Imports auth utils
 */
import { loadState } from "../../utils";

/**
 * Imports types
 */
import type {
  TWorkOverviewType,
  TWorkOverviewView,
} from "./workOverview.types";
import type {
  FilterModel,
  StatsCardItem,
  GenericFilterItem,
  SocketEvent,
  WorkOrder,
} from "../../../types";

/**
 * Imports constants
 */
import {
  STATISTICS_KEY,
  WORKOVERVIEW_VIEW_KEY,
  WORKOVERVIEW_TYPE_KEY,
  WORKOVERVIEW_LIVE_WO_SEARCH_KEY,
  WORKOVERVIEW_LIVE_WO_FILTER_MODELS_KEY,
  WORKOVERVIEW_LIVE_WO_STATUS_FILTERS_KEY,
} from "../../../constants";

export const defaultStatusFilters = [
  {
    labelKey: "New",
    value: "new",
    active: true,
    variant: "secondary",
    textColor: "secondary.120",
    textColorActive: "secondary.60",
  },
  {
    labelKey: "InProgress",
    value: "in-progress",
    active: true,
    variant: "info",
    textColor: "info.120",
    textColorActive: "info.60",
  },
  {
    labelKey: "Scheduled",
    value: "on-hold",
    active: true,
    variant: "scheduled",
    textColor: "scheduled.120",
    textColorActive: "scheduled.60",
  },
  {
    labelKey: "Done",
    value: "done",
    active: true,
    variant: "success",
    textColor: "success.120",
    textColorActive: "success.60",
  },
] as GenericFilterItem[];

export const preloadWorkOverviewViewState = () => {
  const storageState = loadState(WORKOVERVIEW_VIEW_KEY) as TWorkOverviewView;

  if (storageState) return storageState;
  return "table";
};

export const preloadWorkOverviewTypeState = () => {
  const storageState = loadState(WORKOVERVIEW_TYPE_KEY) as TWorkOverviewType;

  if (storageState) return storageState;
  return "live";
};

export const preloadStatisticsState = () => {
  const storageState = loadState(STATISTICS_KEY) as StatsCardItem[];

  if (storageState) return storageState;
  return [];
};

export const preloadLiveWOFilterModelsState = () => {
  const storageState = loadState(
    WORKOVERVIEW_LIVE_WO_FILTER_MODELS_KEY,
  ) as FilterModel[];

  if (storageState) return storageState;
  return [];
};

export const preloadLiveWOStatusFiltersState = () => {
  const storageState = loadState(
    WORKOVERVIEW_LIVE_WO_STATUS_FILTERS_KEY,
  ) as GenericFilterItem[];

  if (storageState) return storageState;
  return defaultStatusFilters;
};

export const preloadLiveWOSearchValueState = () => {
  const storageState = loadState(WORKOVERVIEW_LIVE_WO_SEARCH_KEY) as string;

  if (storageState) return storageState;
  return "";
};

export const addSocketEventDataToWorkOrders = (
  workOrders: WorkOrder[],
  payload: SocketEvent<any>,
) => {
  const clone = cloneDeep(workOrders);
  const { model, modelName, event } = payload;
  const index = findIndex(clone, { id: model.id });

  if (event === "CREATED" && index === -1) {
    clone.unshift({
      ...model,
      tyreService: { tyreHeight: "", tyreWidth: "", tyreRim: "" },
      status: "new",
      event: {
        type: modelName,
        createdAt: model.createdAt,
        updatedAt: model.updatedAt,
      },
    } as WorkOrder);
  }

  if (event === "UPDATED") {
    if (index > -1) {
      clone[index] = {
        ...clone[index],
        ...model,
        paymentTypeId: model.paymentType?.id || clone[index]?.paymentTypeId,
        updatedAt: clone[index].updatedAt,
        event: {
          type: modelName,
          createdAt: model.createdAt,
          updatedAt: model.updatedAt,
        },
      } as WorkOrder;
    }
  }

  if (event === "DELETED" && index > -1) {
    clone.splice(index, 1);
  }

  return clone;
};
