import { Fragment, useEffect } from "react";

/**
 * Imports components
 */
import { Transition } from "../Transition";

/**
 * Imports types
 */
import { RenderControllerProps } from "./RenderController.types";

/**
 * Conditionally renders the provided children based on media query props
 *  or plain mount/unmount condition
 */
export const RenderController: React.FC<RenderControllerProps> = (props) => {
  const {
    transition,
    unMountOn,
    mountOn,
    onMount,
    onUnmount,
    children
  } = props;

  /**
   * Handles the callbacks for mount/unmount if any were provided
   */
  useEffect(() => {
    onMount && onMount();
    return () => onUnmount && onUnmount();
    // eslint-disable-next-line
  }, []);

  if (unMountOn) return null;

  if (mountOn) {
    if (transition) {
      return (
        <Transition {...transition}>
          <Fragment> {children} </Fragment>
        </Transition>
      );
    }
    return <Fragment> {children} </Fragment>;
  }

  return null;
};
