import React, { cloneElement, useEffect } from "react";

/**
 * Imports hooks
 */
import { useHistory, useSelector } from "../../hooks";

/**
 * External Imports
 */
import { Route as BaseRoute } from "react-router-dom";

/**
 * Imports types
 */
import { RouteProps } from "./Route.types";

/**
 * Custom Route component, extended Route from react-router-dom to
 * pass props to children. (optional)
 */
export const Route: React.FC<RouteProps> = (props) => {
  const { allow, withProps, path, exact, children } = props;

  /**
   * Gets the auth state
   */
  const { user } = useSelector((state) => state.auth);

  /**
   * Gets the history
   */
  const history = useHistory();

  /**
   * Handles re-routing unauthorized access
   */
  useEffect(() => {
    if (allow && user) {
      if (!allow.includes(user.type)) {
        history.push("/dashboard");
      }
    }
    // eslint-disable-nextline
  }, [user, window.location.href]);

  if (!withProps) {
    return (
      <BaseRoute path={path} exact={exact}>
        {children}
      </BaseRoute>
    );
  }

  return (
    <BaseRoute
      path={path}
      exact={exact}
      render={(props) => {
        return cloneElement(children, { ...props });
      }}
    />
  );
};
