/**
 * External imports
 */
import { cloneDeep } from "lodash";

/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  CreateReportApiCall,
  CreateReportResponse
} from "./CreateReport.types";

/**
 * Handles making the api call
 */
export const CreateReport: CreateReportApiCall =
  (api) => async (body, params, onSuccess, onError) => {
    try {
      const { reportType, reportFormat } = params;

      const response = await api.post<CreateReportResponse>(
        `/reports/${reportType}/${reportFormat}`,
        body
      );

      if (response) {
        const { data: axiosData } = response;

        if (axiosData.data.items && !Array.isArray(axiosData.data.items)) {
          const formattedData = cloneDeep(axiosData);
          formattedData.data.items = Object.values(formattedData.data.items!);

          onSuccess(formattedData, response);
        } else {
          onSuccess(axiosData, response);
        }
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
