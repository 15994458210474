/**
 * Imports Material UI Components
 */
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";

/**
 * Imports types
 */
import { PopperStyledProps } from "./Tooltip.types";

/**
 * Imports styled
 */
import { styled } from "@mui/material";

/**
 * Styles the child container component
 */
export const ChildContainer = styled(Box)(() => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 200
  };
});

/**
 * Styles the popper component
 */
export const PopperMui = styled(Popper, {
  shouldForwardProp: (propName) =>
    propName !== "zIndex" &&
    propName !== "transform" &&
    propName !== "margin" &&
    propName !== "mt" &&
    propName !== "mb" &&
    propName !== "ml" &&
    propName !== "mr"
})<PopperStyledProps>((props) => {
  const { margin, mt, mb, ml, mr, zIndex, transform, theme } = props;

  return {
    zIndex: zIndex || 2000,
    ...(transform && {
      transform: `${transform} !important`
    }),
    '&[data-popper-placement*="right"]': {
      marginLeft: "15px !important"
    },
    '&[data-popper-placement*="left"]': {
      marginRight: "15px !important"
    },
    '&[data-popper-placement*="bottom"]': {
      marginTop: "15px !important"
    },
    '&[data-popper-placement*="top"]': {
      marginBottom: "15px !important"
    },
    '&[data-popper-placement*="bottom-end"]': {
      marginTop: "10px !important"
    },
    "& .Tooltip-outerArrow": {
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent"
    },
    "& .Tooltip-innerArrow": {
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent"
    },
    '&[data-popper-placement*="bottom"] .Tooltip-outerArrow': {
      top: "0 !important",
      left: "50% !important",
      marginTop: "-0.71em",
      width: 0,
      height: 10,
      borderTop: `10px solid ${theme.palette.base.grayLight}`,
      transform: "rotate(180deg) !important"
    },
    '&[data-popper-placement*="bottom"] .Tooltip-innerArrow': {
      top: "0 !important",
      left: "50% !important",
      marginTop: -11,
      marginLeft: -10,
      marginRight: 10,
      width: 0,
      height: 10,
      borderTop: `10px solid ${theme.palette.common.white}`
    },
    '&[data-popper-placement*="top"] .Tooltip-outerArrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: -1,
      marginRight: 0,
      width: 0,
      height: 0,
      borderTop: `10px solid ${theme.palette.base.grayLight}`
    },
    '&[data-popper-placement*="top"] .Tooltip-innerArrow': {
      bottom: 0,
      left: 0,
      marginBottom: -11,
      marginLeft: -10,
      marginRight: 4,
      marginTop: -11,
      width: 0,
      height: 0,
      borderTop: `10px solid ${theme.palette.common.white}`
    },
    '&[data-popper-placement*="left"] .Tooltip-outerArrow': {
      right: 0,
      marginRight: -14,
      marginTop: 20,
      marginBottom: 4,
      width: 0,
      height: 0,
      borderTop: `10px solid ${theme.palette.base.grayLight}`,
      transform: "rotate(270deg) !important"
    },
    '&[data-popper-placement*="left"] .Tooltip-innerArrow': {
      right: 0,
      marginRight: 10,
      marginTop: -11,
      marginBottom: 8,
      marginLeft: -10,
      width: 0,
      height: 0,
      borderTop: `10px solid ${theme.palette.common.white}`
    },
    '&[data-popper-placement*="right"] .Tooltip-outerArrow': {
      left: 0,
      marginLeft: -14,
      marginTop: 20,
      marginBottom: 4,
      width: 0,
      height: 0,
      borderTop: `10px solid ${theme.palette.base.grayLight}`,
      transform: "rotate(90deg) !important"
    },
    '&[data-popper-placement*="right"] .Tooltip-innerArrow': {
      left: 0,
      marginLeft: -10,
      marginTop: -11,
      marginBottom: 4,
      width: 0,
      height: 0,
      borderTop: `10px solid ${theme.palette.common.white}`
    },
    ...(mt && {
      marginTop: `${mt}px !important`
    }),
    ...(mb && {
      marginBottom: `${mb}px !important`
    }),
    ...(ml && {
      marginLeft: `${ml}px !important`
    }),
    ...(mr && {
      marginRight: `${mr}px !important`
    }),
    ...(margin && {
      margin: `${margin} !important`
    })
  };
});

/**
 * Styles the content container component
 */
export const ContentContainer = styled(Paper)((props) => {
  const { theme } = props;

  return {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontSize: "0.8rem",
    fontWeight: 500,
    maxWidth: 400,
    padding: "0.3rem 1rem",
    borderRadius: 4,
    border: `1px solid ${theme.palette.base.grayLight}`,
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75rem"
    }
  };
});

/**
 * Styles the outer arrow component
 */
export const OuterArrow = styled("div")(() => {
  return {
    position: "absolute",
    boxSizing: "border-box"
  };
});

/**
 * Styles the inner arrow component
 */
export const InnerArrow = styled("div")(() => {
  return {
    boxSizing: "border-box"
  };
});
