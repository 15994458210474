/**
 * Imports types
 */
import { FormType } from "../../types";

/**
 * Imports default forms
 */
import { DEFAULT_FORMS } from "./defaultForms";

/**
 * Provides utility functions for forms
 */
export const useFormUtils = () => {
  /**
   * Checks if the provided value is a valid number
   */
  const isNumber = (value: string | number) => {
    const regex = /^-?\d+(?:\.\d+)?$/;

    return regex.test(value.toString());
  };

  /**
   * Checks if the provided value is a valid plate number
   */
  const isPlateNumber = (value: string) => {
    const regex = /([a-zA-Z]{2} [0-9]{2} [a-zA-Z]{3})/;

    return regex.test(value.toString());
  };

  /**
   * Returns the default form based on form type
   */
  const getDefaultForm = (form: FormType) => {
    return DEFAULT_FORMS[form];
  };

  return {
    isNumber,
    isPlateNumber,
    getDefaultForm
  };
};
