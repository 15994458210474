import { createContext } from "react";

/**
 * Imports types
 */
import { SelectOption } from "../../types";

/**
 * Imports i18n
 */
import { Language } from "../../translations";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  activeLanguage: Language;
  languages: Language[];
  getLanguageOptions: SelectOption[];
  setActiveLanguage: React.Dispatch<React.SetStateAction<Language | undefined>>;
  changeLanguage: (languageKey: Language) => void;
  getCalendarLocale: "ro-RO" | "hu-HU" | "en-US";
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  activeLanguage: "en_en",
  languages: [],
  getLanguageOptions: [],
  setActiveLanguage: () => {},
  changeLanguage: () => {},
  getCalendarLocale: "ro-RO",
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
