import { createContext } from "react";

/**
 * Imports types
 */
import {
  BaseSyntheticEvent,
  SyntheticEvent,
  Dispatch,
  SetStateAction,
} from "react";
import { UseFormReturn, FieldArrayWithId } from "react-hook-form";
import {
  LoyaltyCard,
  WorkOrder,
  BreadcrumbPath,
  SelectOption,
  TyreDimension,
  WorkOrderFormBody,
  Product as OriginalProduct,
} from "../../types";
import { UpdateWorkOrderBody } from "../useApi";

/**
 * Defines the form body interface
 */
export type { WorkOrderFormBody as FormBody } from "../../types";

/**
 * Defines the work order product interface
 */
export type WorkOrderProduct = FieldArrayWithId<
  WorkOrderFormBody,
  "products",
  "id"
>;

/**
 * Defines the work order products interface
 */
export type WorkOrderProducts = WorkOrderProduct[];

/**
 * Defines the work order route params interface
 */
export interface WorkOrderRouteParams {
  workOrderId: string;
}

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  methods?: UseFormReturn<WorkOrderFormBody, object>;
  loading: boolean;
  viewMode: "edit" | "view";
  workOrder?: WorkOrder;
  activeTab: number;
  errorTabs: number[];
  workOrderId?: string;
  editLoading: boolean;
  tyreDimensions: TyreDimension[];
  validateTab: (index: number) => void;
  productsList: SelectOption[];
  servicesList: SelectOption[];
  setErrorTabs: Dispatch<SetStateAction<number[]>>;
  disabledTabs: number[];
  dataPrefilled: boolean;
  setDataPrefilled: Dispatch<SetStateAction<boolean>>;
  setActiveTab: Dispatch<SetStateAction<number>>;
  setWorkOrder: Dispatch<SetStateAction<WorkOrder | undefined>>;
  onFormSubmit: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>;
  validatedTabs: number[];
  changeViewMode: (value: "edit" | "view") => void;
  mutateWorkOrder: (
    data: UpdateWorkOrderBody,
    callback: (data: WorkOrder) => void,
    onError: () => void,
  ) => Promise<void>;
  breadcrumbPaths: BreadcrumbPath[];
  deleteWorkOrder: () => void;
  handleTabChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: number,
  ) => void;
  handleScanResult: (card: LoyaltyCard) => void;
  setValidatedTabs: Dispatch<SetStateAction<number[]>>;
  replaceDimensionsRows: (dimensions: TyreDimension[]) => void;
  addNewDimensionsRow: () => void;
  removeDimensionsRow: (index: number) => void;
  workOrderRemoved: boolean;
  workOrderProducts: OriginalProduct[];
  workOrderNotFound: boolean;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  tyreDimensions: [],
  methods: undefined,
  loading: false,
  viewMode: "edit",
  workOrder: undefined,
  activeTab: 0,
  errorTabs: [],
  workOrderId: undefined,
  editLoading: false,
  validateTab: () => {},
  productsList: [],
  servicesList: [],
  setErrorTabs: () => {},
  disabledTabs: [],
  dataPrefilled: false,
  setDataPrefilled: () => {},
  setActiveTab: () => {},
  setWorkOrder: () => {},
  onFormSubmit: () => new Promise((res) => res()),
  validatedTabs: [],
  changeViewMode: () => {},
  mutateWorkOrder: () => new Promise((res) => res()),
  breadcrumbPaths: [],
  deleteWorkOrder: () => {},
  handleTabChange: () => {},
  handleScanResult: () => {},
  setValidatedTabs: () => {},
  addNewDimensionsRow: () => {},
  removeDimensionsRow: () => {},
  replaceDimensionsRows: () => {},
  workOrderRemoved: false,
  workOrderProducts: [],
  workOrderNotFound: false,
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
