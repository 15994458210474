import { forwardRef, useEffect } from "react";

/**
 * Imports Material UI Components
 */
import Zoom from "@mui/material/Zoom";

/**
 * Imports components
 */
import { MessageBox } from "../MessageBox";

/**
 * Imports hooks
 */
import { useSelector, useActions } from "../../hooks";

/**
 * Imports types
 */
import { TransitionProps } from "@mui/material/transitions";

/**
 * Imports styled components
 */
import { Snackbar } from "./MessagePopup.styles";

/**
 * Defines the zoom transition
 */
const ZoomTransition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Zoom in ref={ref} {...props} />;
});

/**
 * Displays the component
 */
export const MessagePopup: React.FC = () => {
  /**
   * Gets the message dispatcher state
   */
  const { open, config } = useSelector((state) => state.message);

  /**
   * Gets the message dispatcher actions
   */
  const { resetMessage } = useActions();

  /**
   * Gets the config
   */
  const { title, severity, message, autoClose, permanent, origin } = config;

  /**
   * Closes the alert
   */
  const closeAlert = () => {
    if (!permanent) resetMessage();
  };

  /**
   * Handles autoclosing the message
   */
  useEffect(() => {
    if (!permanent && message && open) {
      let timer: NodeJS.Timeout;
      timer = setTimeout(() => {
        resetMessage();
      }, autoClose);

      return () => clearTimeout(timer);
    }
  }, [autoClose, permanent, message, open, resetMessage]);

  return (
    <Snackbar
      open={open}
      transitionDuration={250}
      TransitionComponent={ZoomTransition}
      anchorOrigin={origin}
      message={
        <MessageBox
          label={title}
          permanent={permanent}
          variant={severity || "info"}
          show={open}
          message={message}
          onClose={closeAlert}
        />
      }
      onClose={closeAlert}
    />
  );
};
