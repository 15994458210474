/**
 * Imports Palette
 */
import { palette } from "./palette";

/**
 * Imports common theme settings
 */
import { breakpoints, typography, zIndex } from "../common";
import { generateMixins } from "../common/mixins";

/**
 * Creates the Theme
 */
export const LemontageTheme = {
  palette,
  typography,
  zIndex,
  breakpoints,
  mixins: generateMixins({ palette })
};
