/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  UpdateCustomerOrderApiCall,
  UpdateCustomerOrderResponse
} from "./UpdateCustomerOrder.types";

/**
 * Handles making the api call
 */
export const UpdateCustomerOrder: UpdateCustomerOrderApiCall =
  (api) => async (customerOrderId, body, onSuccess, onError) => {
    try {
      const response = await api.put<UpdateCustomerOrderResponse>(
        `/customer-order/${customerOrderId}`,
        fromCamelToSnake(body)
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
