import { useState, useEffect } from "react";

/**
 * External imports
 */
import { cloneDeep } from "lodash";

/**
 * Imports hooks
 */
import {
  useEvents,
  useSearch,
  useDebounce,
  useSelector,
  useUserUtils,
  useSearchUtils,
  useFilterModelsUtils,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { FilterModel, AccountUser } from "../../types";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const LiveTimesheetProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the socket event listener
   */
  const { listen } = useEvents();

  /**
   * Gets the account state
   */
  const { userInitialized, accountUsers } = useSelector(
    (state) => state.account,
  );

  /**
   * Initializes the users
   */
  const [users, setUsers] = useState<AccountUser[]>([]);

  /**
   * Initializes the trigger search flag
   */
  const [triggerSearch, setTriggerSearch] = useState(false);

  /**
   * Initializes the models initialized flag
   */
  const [modelsInitialized, setModelsInitialized] = useState(false);

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets search utils
   */
  const { localSearch } = useSearchUtils();

  /**
   * Gets the user utility functions
   */
  const { getUserOrganization } = useUserUtils();

  /**
   * Gets the filter model utils
   */
  const { modelsToInputs, addOrReplaceFilter, createFilter, removeFilter } =
    useFilterModelsUtils();

  /**
   * Gets the filter models from the search provider
   */
  const { defaultFilters, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  /**
   * Handles filtering the table results
   */
  const filterTableResults = (users: AccountUser[]) => {
    /**
     * Initializes the result
     */
    let result = cloneDeep(users).map((user) => {
      return {
        ...user,
        status: user.lastTimeSheet ? user.lastTimeSheet.status : "-",
      };
    });

    /**
     * Filters based on filter models
     */
    if (activeFilters.length > 0) {
      result = result.filter((user) => {
        const inputs = Object.entries(
          modelsToInputs(
            activeFilters.filter((filter) => filter.field !== "quick_text"),
          ),
        );

        return inputs.every((entry) => {
          const key = entry[0];
          const value = entry[1];

          if (key === "timesheetStatus") {
            return user.status === value;
          }

          return (user as any)[key] === value;
        });
      });
    }

    /**
     * Defines the search value
     */
    const searchValue = activeFilters.find(
      (filter) => filter.field === "quick_text",
    )?.selected;

    result = localSearch({
      linkKey: "id",
      source: result,
      searchPool: result,
      searchValue: searchValue || "",
    });

    return result;
  };

  /**
   * Handles initializing the filters
   */
  const initializeFilterModels = () => {
    const filters: FilterModel[] = [];

    /**
     * Gets the user's organization
     */
    const organization = getUserOrganization();

    if (organization) {
      /**
       * Defines the organization filter model
       */
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      filters.push(organizationFilterModel);
    }

    setDefaultFilters(filters);
    setModelsInitialized(filters.length > 0);
  };

  /**
   * Handles creating a report based on search value
   */
  const handleSearch = (searchValue: string) => {
    /**
     * Defines the search filter model
     */
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });

    /**
     * Updates the active filters
     */
    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    setActiveFilters(filterModels);
  };

  /**
   * Handles submitting the filters modal form
   */
  const handleSubmit = (filters: FilterModel[]) => {
    setActiveFilters(filters);
    setTriggerSearch(true);
  };

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    setActiveFilters(defaultFilters);
    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  /**
   * Handles deleting a filter
   */
  const deleteFilter = (filter: FilterModel) => {
    const updatedFilters = removeFilter(filter, activeFilters);
    setActiveFilters(updatedFilters);

    debounce(() => {
      setTriggerSearch(true);
    }, 1500);
  };

  /**
   * Handles triggering a search after filter deletion
   */
  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Handles keeping the state in sync with the user state
   */
  useEffect(() => {
    setUsers(accountUsers);
    initializeFilterModels();
    // eslint-disable-next-line
  }, [accountUsers]);

  /**
   * Handles updating the state based on socket events
   */
  useEffect(() => {
    if (userInitialized) {
      listen({
        channel: "general",
        event: "GeneralEvent",
        withAuth: true,
        callback: (payload) => {
          const { model, modelName } = payload;

          if (modelName === "TimeSheet") {
            setUsers((prevState) =>
              prevState.map((user) => {
                if (user.id === model.userId) {
                  return {
                    ...user,
                    status: model.status,
                    lastTimeSheet: {
                      ...user.lastTimeSheet,
                      status: model.status,
                      startDate: model.startDate,
                      endDate: model.endDate,
                    },
                  };
                } else {
                  return { ...user };
                }
              }),
            );
          }
        },
      });
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    users,
    activeFilters,
    modelsInitialized,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    filterTableResults,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
