/**
 * Imports auth utils
 */
import { loadState } from "../../utils";

/**
 * Imports types
 */
import { Auth } from "../../../types";

/**
 * Imports constants
 */
import { AUTH_KEY } from "../../../constants";

export const preloadAuth = (): Auth => {
  const storageAuth = loadState(AUTH_KEY) as Auth;

  if (storageAuth) {
    const { accessToken, expiresIn, tokenType, user } = storageAuth;

    return {
      user,
      expiresIn,
      tokenType,
      accessToken,
    };
  } else {
    return {
      accessToken: "",
      expiresIn: 0,
      tokenType: "",
    };
  }
};
