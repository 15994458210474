import { createSlice } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { ReduxSlice } from "..";
import { PayloadAction } from "@reduxjs/toolkit";
import { DeviceState } from "./device.types";

const initialState: DeviceState = {
  isMobile: false,
  isTablet: false,
  isTabletOrLower: false,
  isTabletOrHigher: false,
  isDesktop: false,
};

export const deviceSlice = createSlice({
  name: ReduxSlice.Device,
  initialState,
  reducers: {
    updateDeviceType: (state, action: PayloadAction<DeviceState>) => {
      state.isMobile = action.payload.isMobile;
      state.isTablet = action.payload.isTablet;
      state.isDesktop = action.payload.isDesktop;
      state.isTabletOrLower = action.payload.isTabletOrLower;
      state.isTabletOrHigher = action.payload.isTabletOrHigher;
    },
  },
});

export const deviceActionCreators = deviceSlice.actions;
export const deviceReducer = deviceSlice.reducer;
