/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  DeleteTyreHotelApiCall,
  DeleteTyreHotelResponse
} from "./DeleteTyreHotel.types";

/**
 * Handles making the api call
 */
export const DeleteTyreHotel: DeleteTyreHotelApiCall =
  (api) => async (tyreHotelId, onSuccess, onError) => {
    try {
      const response = await api.delete<DeleteTyreHotelResponse>(
        `/tyre-hotels/${tyreHotelId}`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
