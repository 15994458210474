import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import {
  WorkOrderTyreHotel,
  FilterModel,
  TableData,
  SortOrder,
} from "../../types";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  loading: boolean;
  tableRowsLoading: boolean;
  orderBy: string;
  orderDir: SortOrder;
  totalTyreHotels: number;
  pageCount: number;
  tyreHotels: WorkOrderTyreHotel[];
  activeFilters: FilterModel[];
  modelsInitialized: boolean;
  exportTyreHotels: () => Promise<void>;
  handleSubmit: (filters: FilterModel[]) => void;
  resetFilters: () => void;
  deleteFilter: (filter: FilterModel) => void;
  handleSearch: (searchValue: string) => void;
  formatActiveFilters: (filters: FilterModel[]) => FilterModel[];
  setPageCount: Dispatch<SetStateAction<number>>;
  setTotalTyreHotels: Dispatch<SetStateAction<number>>;
  setTyreHotels: Dispatch<SetStateAction<WorkOrderTyreHotel[]>>;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  handleSort: (e: React.MouseEvent<any>, property: keyof TableData) => void;
  updateTyreHotel: (tyreHotel: WorkOrderTyreHotel) => void;
  closeTyreHotel: (id: number) => Promise<void>;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  loading: false,
  tableRowsLoading: false,
  orderBy: "finished",
  orderDir: "desc",
  totalTyreHotels: 0,
  pageCount: 0,
  tyreHotels: [],
  activeFilters: [],
  modelsInitialized: false,
  handleSubmit: () => {},
  resetFilters: () => {},
  deleteFilter: () => {},
  handleSearch: () => {},
  exportTyreHotels: () => new Promise((res) => res()),
  formatActiveFilters: () => [],
  setPageCount: () => {},
  setTotalTyreHotels: () => {},
  setTyreHotels: () => {},
  handlePageChange: () => {},
  handleSort: () => {},
  updateTyreHotel: () => {},
  closeTyreHotel: () => new Promise((res) => res()),
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
