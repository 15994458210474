import { isAnyOf } from "@reduxjs/toolkit";

/**
 * Imports queries
 */
import { fetchAccountInformation } from "./account.api";

/**
 * Imports action creators
 */
import { accountActionCreators } from "./account.slice";

/**
 * Imports types
 */
import { AppStartListening } from "../../store";

/**
 * Imports utils
 */
import { saveState } from "../../utils";

/**
 * Imports constants
 */
import {
  ACCOUNT_DELETED_KEY,
  AVATAR_CONFIG_KEY,
  LOCKED_KEY,
} from "../../../constants";

/**
 * Listens to the account deletion action
 */
export const addAccountDeletionListener = (
  startListening: AppStartListening,
) => {
  startListening({
    actionCreator: accountActionCreators.updateUserAccountDeleted,
    effect: (action, listenerApi) => {
      /**
       * Gets the account object
       */
      const isDeleted = action.payload;

      /**
       * Updates local storage
       */
      saveState(ACCOUNT_DELETED_KEY, isDeleted);
    },
  });
};

/**
 * Listens to the account information fetch
 */
export const addFetchAccountInformationListener = (
  startListening: AppStartListening,
) => {
  startListening({
    matcher: fetchAccountInformation.matchFulfilled,
    effect: (action, listenerApi) => {
      /**
       * Gets the account object
       */
      const data = action.payload.data;

      /**
       * Updates local storage
       */
      saveState(ACCOUNT_DELETED_KEY, data.account.status === "deleted");
    },
  });
};

const { updateLockedState, updateAvatarConfig } = accountActionCreators;
const accountMatcher = isAnyOf(updateLockedState, updateAvatarConfig);

export const addAccountListener = (startListening: AppStartListening) => {
  startListening({
    matcher: accountMatcher,
    effect: (action, listenerApi) => {
      if (action.type === updateLockedState.type) {
        saveState(LOCKED_KEY, action.payload);
      }

      if (action.type === updateAvatarConfig.type) {
        saveState(AVATAR_CONFIG_KEY, action.payload);
      }
    },
  });
};
