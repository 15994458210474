/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  UpdateCompanyApiCall,
  UpdateCompanyResponse
} from "./UpdateCompany.types";

/**
 * Handles making the api call
 */
export const UpdateCompany: UpdateCompanyApiCall =
  (api) => async (companyId, body, onSuccess, onError) => {
    try {
      const response = await api.put<UpdateCompanyResponse>(
        `/companies/${companyId}`,
        fromCamelToSnake(body)
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
