import { useState, useEffect } from "react";

/**
 * Imports types
 */
import {
  UseWindowDimensions,
  WindowDimensions
} from "./useWindowDimensions.types";

/**
 * Gets the window dimensions
 */
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height
  };
};

/**
 * Defines the main hook
 */
export const useWindowDimensions: UseWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(
    getWindowDimensions()
  );

  /**
   * Handles updating the window dimensions state
   */
  const handleResize = () => setWindowDimensions(getWindowDimensions());

  /**
   * Handles adding an event listener on the resize
   */
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return (): void => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    width: windowDimensions.width,
    height: windowDimensions.height
  };
};
