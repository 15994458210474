/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import { LogoutApiCall, LogoutResponse } from "./Logout.types";

/**
 * Handles making the api call
 */
export const Logout: LogoutApiCall = (api) => async (onSuccess, onError) => {
  try {
    const response = await api.post<LogoutResponse>("/logout");

    if (response) {
      const { data } = response;
      onSuccess(data, response);
    }
  } catch (error: unknown) {
    catchError(error, onError);
  }
};
