import { useState, useEffect } from "react";

/**
 * External imports
 */
import { findIndex, cloneDeep } from "lodash";

/**
 * Imports hooks
 */
import {
  useApi,
  useEvents,
  useSearch,
  useActions,
  useSelector,
  useDebounce,
  useUserUtils,
  useSocketUtils,
  useTranslation,
  useFilterModelsUtils,
} from "../../hooks";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import type { FilterModel, Product, TableData } from "../../types";

/**
 * Imports API types
 */
import type {
  RequestOnError,
  SearchProductsBody,
  SearchProductsOnSuccess,
} from "../useApi";

export const ServicesProvider: React.FC = (props) => {
  const { children } = props;

  const { Provider } = context;

  const { t } = useTranslation();
  const debounce = useDebounce();
  const { listen } = useEvents();
  const { dispatchMessage } = useActions();
  const { getUserOrganization } = useUserUtils();
  const { handleSocketEvents } = useSocketUtils();
  const { apiCalls } = useApi({ withCredentials: true });
  const { userInitialized } = useSelector((state) => state.account);
  const {
    removeFilter,
    createFilter,
    addOrReplaceFilter,
    formatActiveFilters,
  } = useFilterModelsUtils();
  const { defaultFilters, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  const [triggerSearch, setTriggerSearch] = useState(false);
  const [services, setServices] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [tableRowsLoading, setTableRowsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalServices, setTotalServices] = useState(0);
  const [orderBy, setOrderBy] = useState("name");
  const [orderDir, setOrderDir] = useState<"asc" | "desc">("asc");
  const [modelsInitialized, setModelsInitialized] = useState(false);

  const handleSearch = (searchValue: string) => {
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });
    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    setLoading(true);
    searchServices(filterModels, 1);
    setActiveFilters(filterModels);
  };

  const deleteFilter = (filter: FilterModel) => {
    const updatedFilters = removeFilter(filter, activeFilters);
    setActiveFilters(updatedFilters);

    debounce(() => {
      setPageCount(1);
      setLoading(true);
      setTriggerSearch(true);
    }, 1500);
  };

  const resetFilters = () => {
    setLoading(true);
    setActiveFilters(defaultFilters);
    setPageCount(1);

    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      searchServices(filters, 1);
    }, 500);
  };

  const initializeFilterModels = () => {
    const models: FilterModel[] = [];
    const organization = getUserOrganization();

    if (organization) {
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      models.push(organizationFilterModel);
    }

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  const onRequestError: RequestOnError = (error) => {
    setLoading(false);
    setTableRowsLoading(false);
    dispatchMessage({
      message: error.errorMessage ? error.errorMessage : "Unknown Error",
      severity: "error",
      autoClose: 10000,
    });
  };

  const searchServices = async (filters: FilterModel[], page?: number) => {
    const _filters: FilterModel[] = cloneDeep(filters);
    const isServiceFilterModel = createFilter({
      field: "is_service",
      selected: true,
      type: "boolean",
    });
    _filters.push(isServiceFilterModel);

    const requestBody: SearchProductsBody = {
      models: _filters.filter((model) => !model.displayOnly),
      order_by: orderBy,
      order_dir: orderDir,
      page_count: page || pageCount,
      page_size: 15,
    };

    const onSuccess: SearchProductsOnSuccess = (response) => {
      if (response && response.data) {
        const { data } = response;
        const { items, pageCount, orderBy, orderDir, total } = data;

        setServices(items);
        setPageCount(pageCount);
        setOrderBy(orderBy);
        setOrderDir(orderDir);
        setTotalServices(total);
        setLoading(false);
        setTableRowsLoading(false);
      }
    };

    await apiCalls.searchProducts(requestBody, onSuccess, onRequestError);
  };

  const updateService = (service: Product) => {
    const index = findIndex(services, { id: service.id });
    const _services = cloneDeep(services);
    _services[index] = service;

    setServices(_services);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPageCount(page);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  const handleSort = (e: React.MouseEvent<any>, property: keyof TableData) => {
    const isAsc = orderBy === property && orderDir === "asc";

    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property as string);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  useEffect(() => {
    if (userInitialized) {
      initializeFilterModels();
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        searchServices(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);

      if (activeFilters.length < 1) {
        setLoading(false);
        setTableRowsLoading(false);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
        initializeFilterModels();
      } else {
        searchServices(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  useEffect(() => {
    if (userInitialized) {
      listen({
        channel: "general",
        event: "GeneralEvent",
        withAuth: true,
        callback: (payload) =>
          handleSocketEvents({
            payload,
            modelName: "Product",
            setState: setServices,
          }),
      });
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  const providerValue: ProviderValues = {
    orderBy,
    loading,
    orderDir,
    pageCount,
    services,
    activeFilters,
    totalServices,
    tableRowsLoading,
    modelsInitialized,
    handleSort,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    setPageCount,
    setServices,
    updateService,
    handlePageChange,
    setTotalServices,
    formatActiveFilters,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
