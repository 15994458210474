import { createSlice } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { ReduxSlice } from "../../features";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  TNotificationsState,
  TNotificationView,
} from "./notifications.types";
import type { Notification, NotificationSettings } from "../../../types";

/**
 * Imports utils
 */
import {
  preloadNotificationsState,
  preloadNotificationViewState,
  preloadNotificationSettingsState,
} from "./notifications.utils";

const initialState: TNotificationsState = {
  view: preloadNotificationViewState(),
  notifications: preloadNotificationsState(),
  settings: preloadNotificationSettingsState(),
};

export const notificationsSlice = createSlice({
  name: ReduxSlice.Notifications,
  initialState,
  reducers: {
    updateNotifications: (
      state,
      action: PayloadAction<Notification<any>[]>,
    ) => {
      state.notifications = action.payload;
    },
    updateNotificationView: (
      state,
      action: PayloadAction<TNotificationView>,
    ) => {
      state.view = action.payload;
    },
    updateNotificationSettings: (
      state,
      action: PayloadAction<NotificationSettings>,
    ) => {
      state.settings = action.payload;
    },
  },
});

export const notificationsActionCreators = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
