import { useState, useEffect } from "react";

/**
 * External imports
 */
import { toInteger } from "lodash";
import { getWeek, getYear } from "date-fns";

/**
 * Imports hooks
 */
import {
  useApi,
  useUtils,
  useEvents,
  useActions,
  useUserUtils,
  useSocketUtils,
  useLocalStorage,
  useAppointmentsUtils,
  useCustomerOrderUtils,
  useSelector,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { CustomerOrder, Appointment } from "../../types";
import { DragEndResult } from "../useKanban";
import {
  RequestOnError,
  SearchAppointmentsOnSuccess,
  CreateCustomerOrderOnSuccess,
  GetCustomersOrderOnSuccess,
  UpdateCustomerOrderOnSuccess,
  DeleteCustomerOrderOnSuccess,
  CloseCustomerOrderOnSuccess,
} from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const CustomerOrderProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the socket event listener
   */
  const { listen } = useEvents();

  /**
   * Gets the auth state
   */
  const { user } = useSelector((state) => state.auth);

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Gets socket event utils
   */
  const { handleSocketEvents } = useSocketUtils();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(true);

  /**
   * Initializes the loading appointments state
   */
  const [loadingAppointments, setLoadingAppointments] = useState(true);

  /**
   * Initializes the customers order
   */
  const [customersOrder, setCustomersOrder] = useState<CustomerOrder[]>([]);

  /**
   * Initializes the customer order in edit
   */
  const [customerOrderInEdit, setCustomerOrderInEdit] = useState("");

  /**
   * Initializes the view per group flag
   */
  const [viewPerGroup, setViewPerGroup] = useLocalStorage(
    "customer-order-view",
    false,
  );

  /**
   * Initializes all appointments
   */
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  /**
   * Initializes the active organization
   */
  const [activeOrg, setActiveOrg] = useState<string | number>();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets general utils
   */
  const { formatDate } = useUtils();

  /**
   * Gets the customer order utils
   */
  const { getMaxOrderValue, formatDateToISO, getDefaultFormDate } =
    useCustomerOrderUtils();

  /**
   * Gets the appointments utils
   */
  const { filterAppointmentsByOrganization } = useAppointmentsUtils();

  /**
   * Gets the user utils
   */
  const { isUserAdmin } = useUserUtils();

  /**
   * Handles formatting the appointment group id
   */
  const formatAppointmentGroupId = (groupId: string) => {
    return groupId === "null" ? null : toInteger(groupId);
  };

  /**
   * Handles getting the customers order
   */
  const getCustomersOrder = async () => {
    setLoading(true);

    /**
     * Defines the api call success callback
     */
    const onRequestSuccess: GetCustomersOrderOnSuccess = (response) => {
      if (response && response.data) {
        const { data } = response;

        setLoading(false);
        setCustomersOrder(data);
      }
    };

    /**
     * Defines the api call error callback
     */
    const onRequestError: RequestOnError = (error) => {
      setLoading(false);
      dispatchMessage({
        message: error.errorMessage ? error.errorMessage : "Unknown Error",
        severity: "error",
        autoClose: 10000,
      });
    };

    await apiCalls.getCustomersOrder(onRequestSuccess, onRequestError);
  };

  /**
   * Handles creating a new customer order
   */
  const createCustomerOrderFromAppointment = async (data: Appointment) => {
    if (data) {
      /**
       * Handles the success of the api call
       */
      const onSuccess: CreateCustomerOrderOnSuccess = () => {
        setAppointments((prev) => prev.filter((item) => item.id !== data.id));
      };

      /**
       * Handles the request error
       */
      const handleError: RequestOnError = () => {
        setLoading(false);
        dispatchMessage({
          message: "Error",
          severity: "error",
        });
      };

      /**
       * Handles making the api call
       */
      await apiCalls.createCustomerOrder(
        {
          ...data,
          onDate: formatDateToISO(
            formatDate(getDefaultFormDate(), "dd/MM/yyyy HH:mm"),
          ),
          order: getMaxOrderValue(customersOrder) + 10,
        },
        onSuccess,
        handleError,
      );
    }
  };

  /**
   * Handles closing a customer order
   */
  const closeCustomerOrder = async (orderId: number) => {
    /**
     * Defines the api call success callback
     */
    const onRequestSuccess: CloseCustomerOrderOnSuccess = (response) => {
      if (response && response.data) {
        setCustomersOrder((prevState) =>
          prevState.filter((order) => order.id !== orderId),
        );
      }
    };

    /**
     * Defines the api call error callback
     */
    const onRequestError: RequestOnError = (error) => {
      setLoading(false);
      dispatchMessage({
        message: error.errorMessage ? error.errorMessage : "Unknown Error",
        severity: "error",
        autoClose: 10000,
      });
    };

    await apiCalls.closeCustomerOrder(
      orderId,
      onRequestSuccess,
      onRequestError,
    );
  };

  /**
   * Handles getting the appointments
   */
  const getAppointments = async (
    activeOrg?: string | number,
    skipLoading?: boolean,
  ) => {
    const weekNumber = getWeek(new Date());
    const currentYear = getYear(new Date());

    if (!skipLoading) setLoadingAppointments(true);

    /**
     * Defines the api call success callback
     */
    const onRequestSuccess: SearchAppointmentsOnSuccess = (response) => {
      if (response && response.data && user) {
        const { data } = response;
        const organization =
          isUserAdmin() && activeOrg ? activeOrg : user.organizationId;

        /**
         * Handles filtering the appointments based on the selected organization
         */
        const filteredAppointments = filterAppointmentsByOrganization(
          data,
          organization,
        );

        setLoadingAppointments(false);
        setAppointments(filteredAppointments);
      }
    };

    /**
     * Defines the api call error callback
     */
    const onRequestError: RequestOnError = (error) => {
      setLoading(false);
      setLoadingAppointments(false);
      dispatchMessage({
        message: error.errorMessage ? error.errorMessage : "Unknown Error",
        severity: "error",
        autoClose: 10000,
      });
    };

    await apiCalls.searchAppointments(
      currentYear,
      weekNumber,
      onRequestSuccess,
      onRequestError,
    );
  };

  /**
   * Handles deleting a customer order
   */
  const deleteCustomerOrder = async (orderId: string) => {
    /**
     * Defines the api call success callback
     */
    const onRequestSuccess: DeleteCustomerOrderOnSuccess = (response) => {
      if (response && response.data) {
        setCustomersOrder((prevState) =>
          prevState.filter((order) => order.id.toString() !== orderId),
        );
      }
    };

    /**
     * Defines the api call error callback
     */
    const onRequestError: RequestOnError = (error) => {
      setLoading(false);
      dispatchMessage({
        message: error.errorMessage ? error.errorMessage : "Unknown Error",
        severity: "error",
        autoClose: 10000,
      });
    };

    await apiCalls.deleteCustomerOrder(
      orderId,
      onRequestSuccess,
      onRequestError,
    );
  };

  /**
   * Gets the row order
   */
  const getRowOrder = (dragResult: DragEndResult) => {
    const { row, newOrder, columns, columnIndex } = dragResult;
    const nextOrder = columns[columnIndex].rows[newOrder - 1];
    const prevOrder = columns[columnIndex].rows[newOrder + 1];

    const calculatedOrder = nextOrder
      ? nextOrder.order + 1
      : prevOrder
      ? prevOrder.order - 1
      : row.order;

    if (!prevOrder) return calculatedOrder;
    if (calculatedOrder >= prevOrder.order) return prevOrder.order - 1;

    return calculatedOrder;
  };

  /**
   * Handles updating the customer order order and/or appointment group
   */
  const handleDragAndDrop = async (dragResult: DragEndResult) => {
    const {
      row,
      newOrder,
      oldOrder,
      newGroup,
      oldGroup,
      columns,
      columnIndex,
    } = dragResult;
    const data = row as CustomerOrder;

    if (newOrder !== oldOrder || newGroup !== oldGroup) {
      const order = getRowOrder(dragResult);
      const newGroupId = columns[columnIndex].group;

      await updateCustomerOrder({
        ...data,
        order,
        appointmentGroupId: formatAppointmentGroupId(newGroupId),
      });
    }
  };

  /**
   * Handles updating the customer order
   */
  const updateCustomerOrder = async (data: CustomerOrder) => {
    if (data) {
      /**
       * Handles the success of the api call
       * Note: Not updating state here because the socket will handle that
       */
      const onSuccess: UpdateCustomerOrderOnSuccess = () => {};

      /**
       * Handles the request error
       */
      const handleError: RequestOnError = () => {
        setLoading(false);
        dispatchMessage({
          message: "Error",
          severity: "error",
        });
      };

      /**
       * Handles making the api call
       */
      await apiCalls.updateCustomerOrder(data.id, data, onSuccess, handleError);
    }
  };

  /**
   * Handles initializing the active org
   */
  useEffect(() => {
    if (userInitialized && user && user.id) {
      getCustomersOrder();
      getAppointments();
      setActiveOrg(user.organizationId);
    }
    // eslint-disable-next-line
  }, [userInitialized, user]);

  /**
   * Updates the state based on socket event updates
   */
  useEffect(() => {
    if (userInitialized && user && user.id) {
      /**
       * Customer Order socket events
       */
      listen({
        channel: "general",
        event: "GeneralEvent",
        withAuth: true,
        callback: (payload) => {
          handleSocketEvents({
            payload,
            forceUniqueById: true,
            modelName: "CustomerOrder",
            setState: setCustomersOrder,
          });
        },
      });

      /**
       * Appointment socket events
       */
      listen({
        channel: "general",
        event: "GeneralEvent",
        withAuth: true,
        callback: (payload) => {
          handleSocketEvents({
            payload,
            forceUniqueById: true,
            modelName: "Appointments",
            setState: setAppointments,
          });
        },
      });
    }
    // eslint-disable-next-line
  }, [userInitialized, user]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    loading,
    appointments,
    viewPerGroup,
    customersOrder,
    customerOrderInEdit,
    loadingAppointments,
    activeOrg,
    setActiveOrg,
    setViewPerGroup,
    handleDragAndDrop,
    deleteCustomerOrder,
    setCustomerOrderInEdit,
    closeCustomerOrder,
    getAppointments,
    createCustomerOrderFromAppointment,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
