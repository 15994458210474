import { Fragment, useEffect } from "react";

/**
 * Imports hooks
 */
import { useTheme } from "@mui/material/styles";
import { useWindowDimensions, useActions, useDebounce } from "../../hooks";

export const DeviceController: React.FC = (props) => {
  const { children } = props;

  const theme = useTheme();
  const debounce = useDebounce();
  const { width } = useWindowDimensions();
  const { updateDeviceType } = useActions();

  useEffect(() => {
    debounce(() => {
      updateDeviceType({
        isMobile: width < theme.breakpoints.values.sm,
        isTablet:
          width <= theme.breakpoints.values.md &&
          width >= theme.breakpoints.values.sm,
        isDesktop: width >= theme.breakpoints.values.lg,
        isTabletOrLower: width <= theme.breakpoints.values.md,
        isTabletOrHigher: width >= theme.breakpoints.values.md,
      });
    }, 500);

    // eslint-disable-next-line
  }, [width]);

  return <Fragment>{children}</Fragment>;
};
