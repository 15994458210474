/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import { TrackReportApiCall, TrackReportResponse } from "./TrackReport.types";

/**
 * Handles making the api call
 */
export const TrackReport: TrackReportApiCall =
  (api) => async (body, onSuccess, onError) => {
    try {
      const response = await api.post<TrackReportResponse>(
        "/reports/track",
        fromCamelToSnake(body)
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
