/**
 * Imports transformers
 */
import { transformProductsResponse } from "../../transformers";

/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetProductsQueryVariables,
  GetProductsResponse,
  GetProductsApiCall
} from "./GetProducts.types";

/**
 * Imports queries
 */
import { getProductsQuery } from "../queries";

/**
 * Handles making the api call
 */
export const GetProducts: GetProductsApiCall =
  (api) => async (queryVariables, onSuccess, onError) => {
    try {
      const response = await api.query<
        GetProductsResponse,
        GetProductsQueryVariables
      >({
        query: getProductsQuery,
        variables: queryVariables
      });

      if (response) {
        onSuccess(transformProductsResponse(response), response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
