import { createContext } from "react";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  changeView: (value: "kanban" | "table") => void;
  changeWorkOrderType: (value: "draft" | "live") => void;
  showLiveWorkOrders: () => boolean;
  showDraftWorkOrders: () => boolean;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  changeView: () => {},
  changeWorkOrderType: () => {},
  showLiveWorkOrders: () => false,
  showDraftWorkOrders: () => false,
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
