import { useState } from "react";

/**
 * Imports the create list groups function
 */
import { useCreateListGroups } from "./createListGroups";

/**
 * Imports the context
 */
import { context, ProviderValues, ProviderProps } from "./Context";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const ListGroupsProvider: React.FC<ProviderProps> = (props) => {
  const { children, expanded } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Initializes the expanded list group
   */
  const [expandedListGroup, setExpandedListGroup] = useState(-1);

  /**
   * Handles expanding the list
   */
  const expandList = (id?: number) => {
    if (id) {
      setExpandedListGroup((prevState) => {
        return prevState === id ? -1 : id;
      });
    }
  };

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    expanded,
    expandedListGroup,
    setExpandedListGroup,
    expandList,
    createListGroups: useCreateListGroups,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
