import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import { FilterModel } from "..";
import { MetadataColumn } from "../../components/ReportMetadata";
import { TimesheetReportItem, SortOrder, TableData } from "../../types";

/**
 * Defines the Provider values interface
 */
export interface ProviderValues {
  orderBy: string;
  loading: boolean;
  pageCount: number;
  orderDir: SortOrder;
  totalUserReports: number;
  tableRowsLoading: boolean;
  modelsInitialized: boolean;
  activeFilters: FilterModel[];
  timesheetTotal: MetadataColumn[];
  userReports: TimesheetReportItem[];
  setPageCount: Dispatch<SetStateAction<number>>;
  setTotalUserReports: Dispatch<SetStateAction<number>>;
  setUserReports: Dispatch<SetStateAction<TimesheetReportItem[]>>;
  resetFilters: () => void;
  handleSearch: (searchValue: string) => void;
  deleteFilter: (filter: FilterModel) => void;
  handleSubmit: (filters: FilterModel[]) => void;
  formatActiveFilters: (filters: FilterModel[]) => FilterModel[];
  handleSort: (e: React.MouseEvent<any>, property: keyof TableData) => void;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  pageCount: 0,
  loading: false,
  orderDir: "asc",
  userReports: [],
  activeFilters: [],
  timesheetTotal: [],
  totalUserReports: 0,
  orderBy: "start_date",
  tableRowsLoading: false,
  modelsInitialized: false,
  setPageCount: () => {},
  setUserReports: () => {},
  setTotalUserReports: () => {},
  handleSort: () => {},
  handleSubmit: () => {},
  resetFilters: () => {},
  deleteFilter: () => {},
  handleSearch: () => {},
  handlePageChange: () => {},
  formatActiveFilters: () => []
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
