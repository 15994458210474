import { useContext } from "react";

/**
 * Imports the context
 */
import { context } from "./Context";

/**
 * Imports the provider
 */
import { ServicesProvider } from "./Provider";

const useServices = () => useContext(context);

export { useServices, ServicesProvider };
