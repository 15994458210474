/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  UpdateContactApiCall,
  UpdateContactResponse
} from "./UpdateContact.types";

/**
 * Handles making the api call
 */
export const UpdateContact: UpdateContactApiCall =
  (api) => async (contactId, body, onSuccess, onError) => {
    try {
      const response = await api.put<UpdateContactResponse>(
        `/contacts/${contactId}`,
        fromCamelToSnake(body)
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
