/**
 * Imports transformers
 */
import { transformGuarantyResponse } from "../../transformers";

/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetGuarantyQueryVariables,
  GetGuarantyResponse,
  GetGuarantyApiCall
} from "./GetGuaranty.types";

/**
 * Imports queries
 */
import { getGuarantyQuery } from "../queries";

/**
 * Handles making the api call
 */
export const GetGuaranty: GetGuarantyApiCall =
  (api) => async (queryVariables, onSuccess, onError) => {
    try {
      const response = await api.query<
        GetGuarantyResponse,
        GetGuarantyQueryVariables
      >({
        query: getGuarantyQuery,
        variables: queryVariables,
        fetchPolicy: "no-cache"
      });

      if (response) {
        onSuccess(transformGuarantyResponse(response), response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
