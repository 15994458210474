import { useState, useEffect } from "react";

/**
 * External imports
 */
import { uniq } from "lodash";

/**
 * Imports hooks
 */
import {
  useApi,
  useForm,
  useTabs,
  useWatch,
  useUtils,
  useActions,
  useDebounce,
  useUserUtils,
  useTranslation,
  useProductUtils,
  useWorkOrderITPUtils,
  useSelector,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { FormBody } from "./Context";
import { SelectOption, BreadcrumbPath, WorkOrder } from "../../types";
import { RequestOnError, CreateWorkOrderOnSuccess } from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const CreateWorkorderITPProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the auth state
   */
  const { user } = useSelector((state) => state.auth);

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Initializes the start date
   */
  const [startDate] = useState(new Date());

  /**
   * Initializes the loading flag
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the services list
   */
  const [servicesList, setServicesList] = useState<SelectOption[]>([]);

  /**
   * Initializes the breadcrumb paths
   */
  const [breadcrumbPaths, setBreadcrumbPaths] = useState<BreadcrumbPath[]>([]);

  /**
   * Initialzies the newly created workorder state
   */
  const [newWorkOrder, setNewWorkOrder] = useState<WorkOrder>();

  /**
   * Initializes the print workorder state
   */
  const [workOrderPrint, setWorkOrderPrint] = useState(false);

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets general utils
   */
  const { formatDate } = useUtils();

  /**
   * Gets work order utils
   */
  const {
    getErrorTabs,
    createBreadcrumb,
    getDefaultValuesITP,
    checkTabForErrors,
    shouldValidateTab,
    shouldInvalidateTab,
    getDefaultTabsState,
    createWorkOrderBodyITP,
  } = useWorkOrderITPUtils();

  /**
   * Initializes the tabs state
   */
  const {
    activeTab,
    errorTabs,
    disabledTabs,
    validatedTabs,
    activateTab,
    setActiveTab,
    setErrorTabs,
    setDisabledTabs,
    setValidatedTabs,
  } = useTabs({ defaults: getDefaultTabsState });

  /**
   * Gets products utils
   */
  const { getSelectOptions } = useProductUtils();

  /**
   * Gets user utils
   */
  const { getProductById, getDefaultWorkOrderTypeITP, getUserProducts } =
    useUserUtils();

  /**
   * Gets the user products
   */
  const userProducts = getUserProducts;

  /**
   * Initializes the form
   */
  const methods = useForm<FormBody>({
    defaultValues: getDefaultValuesITP(),
    mode: "all",
    criteriaMode: "all",
  });

  /**
   * Gets the form methods
   */
  const { control, formState, reset, setValue, getValues, handleSubmit } =
    methods;

  /**
   * Gets the form state
   */
  const { isDirty } = formState;

  /**
   * Watch for changes in the custom work order fields
   */
  const [expirationDate, carTypeId] = useWatch({
    control,
    name: ["workOrder.metaData.expirationDate", "workOrder.carTypeId"],
  });

  /**
   * Watches for changes in the products field
   */
  const [productId] = useWatch({
    control,
    name: ["products.0.productId"],
  });

  /**
   * Handles initializing the breadcrumb
   */
  const initializeBreadcrumb = () => {
    const baseBreadcrumb = createBreadcrumb("create");
    setBreadcrumbPaths(baseBreadcrumb);
  };

  /**
   * Handles resetting the form
   */
  const resetWorkOrder = () => {
    setActiveTab(0);
    setErrorTabs([]);
    setValidatedTabs([]);
    setDisabledTabs([1]);
    reset(getDefaultValuesITP());
    debounce(() => {
      initializeITP();
      if (servicesList.length > 0) {
        handleProductChange(servicesList[0].value);
      }
    }, 300);
  };

  /**
   * Handles the tab change
   */
  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    validateTab(activeTab);
    activateTab(event, newValue);
  };

  /**
   * Handles the form submission
   */
  const onFormSubmit = handleSubmit((data: FormBody) => {
    if (userProducts.length < 1) {
      setActiveTab(2);
      return dispatchMessage({
        message: t("StillFetchingProductsPleaseTryAgain"),
        severity: "warning",
      });
    }

    if (loading) return;

    /**
     * Handles creating a work order
     */
    setLoading(true);
    createWorkOrder(data);
  });

  /**
   * Handles validating a tab
   */
  const validateTab = (index: number) => {
    const formBody = getValues();
    const shouldValidate = shouldValidateTab(index, formState, formBody);
    const shouldInvalidate = shouldInvalidateTab(index, formState, formBody);
    const hasErrors = checkTabForErrors(formState);

    if (shouldValidate) {
      setValidatedTabs((prevTabs) => uniq([...prevTabs, index]));
    }

    if (shouldInvalidate) {
      setValidatedTabs(validatedTabs.filter((tab) => tab !== index));
    }

    if (errorTabs.includes(index) && !hasErrors) {
      setErrorTabs(errorTabs.filter((tab) => tab !== index));
    }
  };

  /**
   * Handles creating a new work order
   */
  const createWorkOrder = async (formBody: FormBody) => {
    /**
     * Creates the request body
     */
    const data = createWorkOrderBodyITP(formBody);

    /**
     * Defines the api call success callback
     */
    const onSuccess: CreateWorkOrderOnSuccess = ({ data }) => {
      setLoading(false);
      setActiveTab(0);
      setValidatedTabs([]);
      setErrorTabs([]);
      setDisabledTabs([1]);
      setNewWorkOrder(data);
      dispatchMessage({
        title: data.uuid,
        message: t("WorkOrderCreated"),
        severity: "success",
        autoClose: 5000,
      });

      debounce(() => {
        resetWorkOrder();
      }, 350);
    };

    /**
     * Defines the api call error callback
     */
    const onError: RequestOnError = () => {
      setLoading(false);
      dispatchMessage({
        message: "Error",
        severity: "error",
      });
    };

    await apiCalls.createWorkOrder(data, onSuccess, onError);
  };

  /**
   * Handles updating the row inputs when the product changes
   */
  const handleProductChange = (productId: number) => {
    if (productId === null) return;
    const product = getProductById(productId);

    if (product) {
      const { price } = product;
      const total = price.toFixed(2);

      setValue("products.0.price", price);
      setValue("products.0.total", total);
      setValue("products.0.quantity", 1);
      setValue("products.0.productId", productId);
      setValue("workOrder.subtotal", total);
      setValue("workOrder.total", total);
    }
  };

  /**
   * Initializes the form
   */
  const initializeITP = () => {
    reset(getDefaultValuesITP());

    if (user) {
      const { username } = user;
      setValue("workOrder.metaData.createdBy", username);
    }

    if (!expirationDate) {
      setValue(
        "workOrder.metaData.expirationDate",
        formatDate(new Date(), "dd/MM/yyyy"),
      );
    }

    const defaultWorkOrderType = getDefaultWorkOrderTypeITP();

    if (defaultWorkOrderType) {
      setValue("workOrder.workOrderTypeId", defaultWorkOrderType.id);
    }
  };

  /**
   * Gets the initial services and products
   */
  useEffect(() => {
    if (userInitialized && userProducts.length > 0) {
      /**
       * Filters only the products that are ITP
       */
      const itpServices = userProducts.filter((product) => {
        if (product.isService && product.isCountPrice) {
          return (
            (product.carTypeId === carTypeId || !product.carTypeId) &&
            product.name.includes("ITP")
          );
        }

        return false;
      });

      const { services } = getSelectOptions(itpServices);

      setServicesList(services);

      if (!productId && services.length > 0) {
        handleProductChange(services[0].value);
      }
    }
    // eslint-disable-next-line
  }, [userProducts, carTypeId, userInitialized]);

  /**
   * Handles updating the product when the car type changes
   */
  useEffect(() => {
    if (servicesList.length > 0 && productId) {
      const foundProduct = servicesList.find(
        (service) => service.value === productId,
      );

      if (!foundProduct && servicesList.length > 0) {
        handleProductChange(servicesList[0].value);
      }
    }
    // eslint-disable-next-line
  }, [servicesList, productId]);

  /**
   * Checks the tabs for errors when the active tab changes
   */
  useEffect(() => {
    setErrorTabs(getErrorTabs(formState));
    // eslint-disable-next-line
  }, [activeTab]);

  /**
   * Enabled or disables the summary tab
   */
  useEffect(() => {
    setDisabledTabs(validatedTabs.length > 0 ? [] : [1]);
    // eslint-disable-next-line
  }, [validatedTabs]);

  /**
   * Initializes the breadcrumb path
   */
  useEffect(() => {
    initializeBreadcrumb();
    // eslint-disable-next-line
  }, []);

  /**
   * Handles initializing the form
   */
  useEffect(() => {
    if (userInitialized) initializeITP();
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    methods,
    loading,
    startDate,
    activeTab,
    errorTabs,
    isDirty,
    newWorkOrder,
    disabledTabs,
    servicesList,
    validatedTabs,
    workOrderPrint,
    breadcrumbPaths,
    activateTab,
    validateTab,
    setErrorTabs,
    setActiveTab,
    onFormSubmit,
    setNewWorkOrder,
    setWorkOrderPrint,
    resetWorkOrder,
    handleTabChange,
    setDisabledTabs,
    setValidatedTabs,
    handleProductChange,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
