/**
 * Imports transformers
 */
import { transformCreateTimesheetResponse } from "../../transformers";

/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  CreateTimeSheetResponse,
  CreateTimeSheetApiCall
} from "./CreateTimeSheet.types";

/**
 * Imports queries
 */
import { createTimeSheetMutation } from "../queries";

/**
 * Handles making the api call
 */
export const CreateTimeSheet: CreateTimeSheetApiCall =
  (api) => async (queryVariables, onSuccess, onError) => {
    try {
      const response = await api.query<CreateTimeSheetResponse>({
        query: createTimeSheetMutation,
        variables: queryVariables,
        fetchPolicy: "no-cache"
      });

      if (response) {
        onSuccess(transformCreateTimesheetResponse(response), response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
