/**
 * Imports auth utils
 */
import { loadState } from "../../utils";

/**
 * Imports types
 */
// import type { WorkOrderFormBody } from "../../../types";

/**
 * Imports constants
 */
import {
  // WORKOORDER_DRAFT_KEY,
  DEFAULT_WORKORDER_TYPE_KEY,
} from "../../../constants";

export const preloadDefaultWorkOrderType = () => {
  const defaultWorkOrderType = loadState(DEFAULT_WORKORDER_TYPE_KEY) as number;

  if (defaultWorkOrderType) return defaultWorkOrderType;
  return 0;
};

// export const preloadWorkOrderDraft = () => {
//   const storageState = loadState(WORKOORDER_DRAFT_KEY) as WorkOrderFormBody;

//   if (storageState) return storageState;
//   return undefined;
// };
