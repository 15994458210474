/**
 * Imports Material UI Components
 */
import MuiSnackbar from "@mui/material/Snackbar";

/**
 * Imports styled
 */
import { styled } from "@mui/material";

/**
 * Defines the Snackbar props
 */
interface SnackbarProps {
  type: "loading" | "success" | "error";
}

/**
 * Styles the Snackbar
 */
export const Snackbar = styled(MuiSnackbar)<SnackbarProps>((props) => {
  const { theme, type } = props;

  /**
   * Gets the background color
   */
  const getBackgroundColor = () => {
    if (type === "loading") return theme.palette.info[120];
    if (type === "success") return theme.palette.success[120];
    if (type === "error") return theme.palette.error[120];

    return theme.palette.primary.main;
  };

  return {
    background: "transparent",
    zIndex: 9999,
    "& .MuiSnackbarContent-root": {
      boxShadow: "none",
      background: getBackgroundColor(),
      width: "100%",
      padding: "5px 10px",
      color: theme.palette.base.text,
      borderRadius: 4
    },
    "& .MuiSnackbarContent-message": {
      width: "100%"
    }
  };
});

/**
 * Styles the Message Box
 */
export const MessageBox = styled("div")(({ theme }) => {
  return {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiIconButton-root": {
      padding: 0,
      "& svg": {
        color: theme.palette.common.white
      }
    }
  };
});

/**
 * Styles the Progress Container
 */
export const ProgressContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    "& .MuiCircularProgress-root": {
      color: theme.palette.common.white,
      marginRight: 10
    },
    "& .FeatherIcon": {
      color: theme.palette.common.white,
      marginRight: 10
    },
    "& .InfoContainer": {
      display: "flex",
      flexDirection: "column",
      "& .InfoContainer-title": {
        marginBottom: 10
      }
    }
  };
});
