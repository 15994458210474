import { createContext } from "react";

/**
 * Imports types
 */
import { UseFormReturn, FieldArrayWithId } from "react-hook-form";
import { AddProductRowProps } from "../useProductsForm";
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  BaseSyntheticEvent
} from "react";
import {
  SelectOption,
  BreadcrumbPath,
  WorkOrderFormBody,
  WorkOrder
} from "../../types";

/**
 * Defines the form body interface
 */
export type { WorkOrderFormBody as FormBody } from "../../types";

/**
 * Defines the work order product interface
 */
export type WorkOrderProduct = FieldArrayWithId<
  WorkOrderFormBody,
  "products",
  "id"
>;

/**
 * Defines the work order products interface
 */
export type WorkOrderProducts = WorkOrderProduct[];

/**
 * Defines the work order route params interface
 */
export interface WorkOrderRouteParams {
  workOrderId: string;
}

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  startDate: Date;
  loading: boolean;
  isDirty: boolean;
  activeTab: number;
  errorTabs: number[];
  disabledTabs: number[];
  workOrderPrint: boolean;
  validatedTabs: number[];
  servicesList: SelectOption[];
  breadcrumbPaths: BreadcrumbPath[];
  newWorkOrder: WorkOrder | undefined;
  methods?: UseFormReturn<WorkOrderFormBody, object>;
  resetWorkOrder: () => void;
  handleProductChange: (productId: number) => void;
  setNewWorkOrder: Dispatch<SetStateAction<WorkOrder | undefined>>;
  validateTab: (index: number) => void;
  setActiveTab: Dispatch<SetStateAction<number>>;
  setErrorTabs: Dispatch<SetStateAction<number[]>>;
  setDisabledTabs: Dispatch<SetStateAction<number[]>>;
  setWorkOrderPrint: Dispatch<SetStateAction<boolean>>;
  setValidatedTabs: Dispatch<SetStateAction<number[]>>;
  activateTab: (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ) => void;
  handleTabChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ) => void;
  onFormSubmit: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  activeTab: 0,
  errorTabs: [],
  loading: false,
  isDirty: false,
  disabledTabs: [],
  servicesList: [],
  validatedTabs: [],
  methods: undefined,
  breadcrumbPaths: [],
  startDate: new Date(),
  workOrderPrint: false,
  newWorkOrder: undefined,
  handleProductChange: () => {},
  setNewWorkOrder: () => {},
  activateTab: () => {},
  validateTab: () => {},
  setErrorTabs: () => {},
  setActiveTab: () => {},
  setWorkOrderPrint: () => {},
  resetWorkOrder: () => {},
  handleTabChange: () => {},
  setDisabledTabs: () => {},
  setValidatedTabs: () => {},
  onFormSubmit: () => new Promise((res) => res())
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
