import { isAnyOf } from "@reduxjs/toolkit";

/**
 * Imports action creators
 */
import { workOverviewActionCreators } from "./workOverview.slice";

/**
 * Imports types
 */
import { AppStartListening } from "../../store";

/**
 * Imports utils
 */
import { saveState } from "../../utils";
import { defaultStatusFilters } from "./workOverview.utils";

/**
 * Imports constants
 */
import {
  STATISTICS_KEY,
  WORKOVERVIEW_VIEW_KEY,
  WORKOVERVIEW_TYPE_KEY,
  WORKOVERVIEW_LIVE_WO_SEARCH_KEY,
  WORKOVERVIEW_LIVE_WO_FILTER_MODELS_KEY,
  WORKOVERVIEW_LIVE_WO_STATUS_FILTERS_KEY,
} from "../../../constants";

const {
  updateStatistics,
  updateWorkOverviewType,
  updateWorkOverviewView,
  resetLiveWorkOrdersFilters,
  updateLiveWorkOrdersSearchValue,
  updateLiveWorkOrdersFilterModels,
  updateLiveWorkOrdersStatusFilters,
} = workOverviewActionCreators;
const workOverviewMatcher = isAnyOf(
  updateStatistics,
  updateWorkOverviewType,
  updateWorkOverviewView,
  resetLiveWorkOrdersFilters,
  updateLiveWorkOrdersSearchValue,
  updateLiveWorkOrdersFilterModels,
  updateLiveWorkOrdersStatusFilters,
);

export const addWorkOverviewListener = (startListening: AppStartListening) => {
  startListening({
    matcher: workOverviewMatcher,
    effect: (action, listenerApi) => {
      if (action.type === updateWorkOverviewType.type) {
        saveState(WORKOVERVIEW_TYPE_KEY, action.payload);
      }

      if (action.type === updateWorkOverviewView.type) {
        saveState(WORKOVERVIEW_VIEW_KEY, action.payload);
      }

      if (action.type === updateStatistics.type) {
        saveState(STATISTICS_KEY, action.payload);
      }

      if (action.type === updateLiveWorkOrdersSearchValue.type) {
        saveState(WORKOVERVIEW_LIVE_WO_SEARCH_KEY, action.payload);
      }

      if (action.type === updateLiveWorkOrdersFilterModels.type) {
        saveState(WORKOVERVIEW_LIVE_WO_FILTER_MODELS_KEY, action.payload);
      }

      if (action.type === updateLiveWorkOrdersStatusFilters.type) {
        saveState(WORKOVERVIEW_LIVE_WO_STATUS_FILTERS_KEY, action.payload);
      }

      if (action.type === resetLiveWorkOrdersFilters.type) {
        saveState(WORKOVERVIEW_LIVE_WO_SEARCH_KEY, "");
        saveState(WORKOVERVIEW_LIVE_WO_FILTER_MODELS_KEY, []);
        saveState(
          WORKOVERVIEW_LIVE_WO_STATUS_FILTERS_KEY,
          defaultStatusFilters,
        );
      }
    },
  });
};
