import lodash from "lodash";

/**
 * Gets the url for the api client
 */
export const getBaseUrl = () => {
  const { NODE_ENV, REACT_APP_LOCAL_API, REACT_APP_PROD_API } = process.env;

  return NODE_ENV === "development" ? REACT_APP_LOCAL_API : REACT_APP_PROD_API!;
};

/**
 * Converts from snake to camel case
 */
export const fromSnakeToCamel: (data: any) => any = (data) => {
  if (lodash.isArray(data)) {
    return lodash.map(data, fromSnakeToCamel);
  }

  if (lodash.isObject(data)) {
    return lodash(data)
      .mapKeys((v: any, k: any) => lodash.camelCase(k))
      .mapValues((v: any, k: any) => fromSnakeToCamel(v))
      .value();
  }

  return data;
};

/**
 * Convers from camel case to snake case
 */
export const fromCamelToSnake: (data: any) => any = (data) => {
  if (lodash.isArray(data)) {
    return lodash.map(data, fromCamelToSnake);
  }

  if (lodash.isObject(data)) {
    return lodash(data)
      .mapKeys((v: any, k: any) => lodash.snakeCase(k))
      .mapValues((v: any, k: any) => fromCamelToSnake(v))
      .value();
  }

  return data;
};

/**
 * Builds the error object
 */
export const buildErrorObject = (status: number, errorMessage: string) => {
  return {
    status,
    data: {
      errorMessage,
    },
  };
};
