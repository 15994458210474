/**
 * Imports auth utils
 */
import { loadState } from "../../utils";

/**
 * Imports types
 */
import type { TNotificationView } from "./notifications.types";
import type { Notification, NotificationSettings } from "../../../types";

/**
 * Imports constants
 */
import {
  NOTIFICATIONS_KEY,
  NOTIFICATION_VIEW_KEY,
  NOTIFICATION_SETTINGS_KEY,
} from "../../../constants";

export const preloadNotificationsState = () => {
  const storageState = loadState(NOTIFICATIONS_KEY) as Notification<any>[];

  if (storageState) return storageState;
  return [];
};

export const preloadNotificationViewState = () => {
  const storageState = loadState(NOTIFICATION_VIEW_KEY) as TNotificationView;

  if (storageState) return storageState;
  return "all";
};

export const preloadNotificationSettingsState = () => {
  const storageState = loadState(
    NOTIFICATION_SETTINGS_KEY,
  ) as NotificationSettings;

  if (storageState) return storageState;
  return {
    workOrders: true,
    products: true,
    clients: true,
    tyreHotels: true,
  };
};
