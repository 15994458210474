/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetCustomersOrderApiCall,
  GetCustomersOrderResponse
} from "./GetCustomersOrder.types";

/**
 * Handles making the api call
 */
export const GetCustomersOrder: GetCustomersOrderApiCall =
  (api) => async (onSuccess, onError) => {
    try {
      const response = await api.get<GetCustomersOrderResponse>(
        "/customer-order"
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
