/**
 * Imports Material UI components
 */
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

/**
 * Imports styled
 */
import { styled } from "@mui/material";

/**
 * Imports types
 */
import {
  MessageBoxContainerProps,
  MessageBoxContentProps
} from "./MessageBox.types";

/**
 * Styles the MessageBox container
 */
export const MessageBoxContainer = styled(Box)<MessageBoxContainerProps>(
  (props) => {
    const { theme, variant } = props;

    const variants = {
      success: {
        borderLeft: `solid 5px ${theme.palette.success[100]}`,
        "& .MessageBox-icon": {
          color: theme.palette.success[100]
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.success[100]
        }
      },
      error: {
        borderLeft: `solid 5px ${theme.palette.error[100]}`,
        "& .MessageBox-icon": {
          color: theme.palette.error[100]
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.error[100]
        }
      },
      warning: {
        borderLeft: `solid 5px ${theme.palette.secondary[100]}`,
        "& .MessageBox-icon": {
          color: theme.palette.secondary[100]
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.secondary[100]
        }
      },
      info: {
        borderLeft: `solid 5px ${theme.palette.primary[100]}`,
        "& .MessageBox-icon": {
          color: theme.palette.primary[100]
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary[100]
        }
      }
    };

    return {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      borderRadius: 4,
      border: `1px solid ${theme.palette.base.grayLight}`,
      background: theme.palette.common.white,
      margin: 0,
      ...variants[variant],
      "& .Icon-root": {
        display: "flex"
      }
    };
  }
);

/**
 * Styles the MessageBox header
 */
export const MessageBoxHeader = styled(Box)(() => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 10px 0",
    "& .MuiIconButton-root svg": {
      color: "#555a5f"
    }
  };
});

/**
 * Styles the MessageBox title
 */
export const MessageBoxTitle = styled(Typography)((props) => {
  const { theme } = props;

  return {
    color: theme.palette.base.text,
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    paddingLeft: 6,
    marginTop: 2
  };
});

/**
 * Styles the MessageBox icon
 */
export const MessageBoxIcon = styled(IconButton)((props) => {
  const { theme } = props;

  return {
    color: theme.palette.base.text,
    height: 25,
    width: 25,
    "&:hover": {
      background: "transparent",
      color: theme.palette.base.grayThin
    }
  };
});

/**
 * Styles the MessageBox content
 */
export const MessageBoxContent = styled(Box)<MessageBoxContentProps>(
  (props) => {
    const { fullHeight } = props;

    return {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      maxHeight: fullHeight ? "none" : 200,
      overflowY: "auto",
      overflowX: fullHeight ? "auto" : "hidden",
      padding: "0 10px"
    };
  }
);
