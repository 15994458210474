import { createApi } from "@reduxjs/toolkit/query/react";

/**
 * Imports API client
 */
import { axiosQuery } from "./axiosQuery";

/**
 * Initializes the API service
 */
export const apiService = createApi({
  reducerPath: "apiService",
  baseQuery: axiosQuery,
  tagTypes: ["Appointments", "Account", "Organizations"],
  endpoints: () => ({}),
});
