import { Suspense, lazy, useEffect } from "react";

/**
 * External Imports
 */
import { Switch } from "react-router-dom";

/**
 *  Imports components
 */
import { Route } from "../Route";
import { SuspenseFallback } from "../SuspenseFallback";

/**
 * Imports hooks
 */
import { useActions, useNavigation, useSelector } from "../../hooks";

const ProtectedRoutes = lazy(() => import("../ProtectedRoutes"));
const PublicRoutes = lazy(() => import("../PublicRoutes"));

/**
 * Displays the component
 */
export const Routes: React.FC = () => {
  /**
   * Gets the paths
   */
  const { Paths } = useNavigation();

  /**
   * Gets the state
   */
  const { accessToken } = useSelector((state) => state.auth);

  /**
   * Gets the actions
   */
  const { checkIfAuthenticated } = useActions();

  useEffect(() => {
    checkIfAuthenticated();
  }, [accessToken, checkIfAuthenticated]);

  return (
    <Switch>
      <Route withProps path={Paths.Dashboard}>
        <Suspense fallback={<SuspenseFallback />}>
          <ProtectedRoutes />
        </Suspense>
      </Route>
      <Route withProps path={Paths.Public}>
        <Suspense fallback={<SuspenseFallback />}>
          <PublicRoutes />
        </Suspense>
      </Route>
    </Switch>
  );
};
