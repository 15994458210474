import { useState } from "react";

/**
 * Imports the context
 */
import { context, ProviderValues, ProviderProps } from "./Context";

/**
 * Imports hooks
 */
import { useFilterModels } from "../useFilterModels";

/**
 * Imports constants
 */
import {
  DEFAULT_SORT_ORDER,
  DEFAULT_ORDER_BY,
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE
} from "../../constants";

/**
 * Imports types
 */
import { SortOrder, InputSelectOption } from "../../types";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const SearchProvider: React.FC<ProviderProps> = (props) => {
  const { children, defaults } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the filter models states and setters
   */
  const { activeFilters, defaultFilters, setActiveFilters, setDefaultFilters } =
    useFilterModels();

  /**
   * Initializes the sort order
   */
  const [order, setOrder] = useState<SortOrder>(
    defaults?.order || DEFAULT_SORT_ORDER
  );

  /**
   * Initializes the order by field
   */
  const [orderBy, setOrderBy] = useState<string | number>(
    defaults?.orderBy || DEFAULT_ORDER_BY
  );

  /**
   * Initializes the page
   */
  const [pageCount, setPageCount] = useState(
    defaults?.pageCount || DEFAULT_PAGE
  );

  /**
   * Initializes the rows per page
   */
  const [pageSize, setPageSize] = useState(
    defaults?.pageSize || DEFAULT_ROWS_PER_PAGE
  );

  /**
   * Initializes the orderBy options
   */
  const [orderByOptions, setOrderByOptions] = useState<InputSelectOption[]>([]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    defaultFilters,
    activeFilters,
    order,
    orderBy,
    orderByOptions,
    pageCount,
    pageSize,
    setOrderByOptions,
    setDefaultFilters,
    setActiveFilters,
    setOrder,
    setOrderBy,
    setPageCount,
    setPageSize
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
