import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import { Product, FilterModel, TableData, SortOrder } from "../../types";

export interface ProviderValues {
  loading: boolean;
  tableRowsLoading: boolean;
  orderBy: string;
  orderDir: SortOrder;
  totalServices: number;
  pageCount: number;
  services: Product[];
  activeFilters: FilterModel[];
  modelsInitialized: boolean;
  handleSubmit: (filters: FilterModel[]) => void;
  resetFilters: () => void;
  deleteFilter: (filter: FilterModel) => void;
  handleSearch: (searchValue: string) => void;
  formatActiveFilters: (filters: FilterModel[]) => FilterModel[];
  setPageCount: Dispatch<SetStateAction<number>>;
  setTotalServices: Dispatch<SetStateAction<number>>;
  setServices: Dispatch<SetStateAction<Product[]>>;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  handleSort: (e: React.MouseEvent<any>, property: keyof TableData) => void;
  updateService: (product: Product) => void;
}

export const defaultValues: ProviderValues = {
  loading: false,
  tableRowsLoading: false,
  orderBy: "createdAt",
  orderDir: "desc",
  totalServices: 0,
  pageCount: 0,
  services: [],
  activeFilters: [],
  modelsInitialized: false,
  handleSubmit: () => {},
  resetFilters: () => {},
  deleteFilter: () => {},
  handleSearch: () => {},
  formatActiveFilters: () => [],
  setPageCount: () => {},
  setTotalServices: () => {},
  setServices: () => {},
  handlePageChange: () => {},
  handleSort: () => {},
  updateService: () => {},
};

export const context = createContext<ProviderValues>(defaultValues);
