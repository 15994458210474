/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  RecoverAccountApiCall,
  RecoverAccountResponse
} from "./RecoverAccount.types";

/**
 * Handles making the api call
 */
export const RecoverAccount: RecoverAccountApiCall =
  (api) => async (onSuccess, onError) => {
    try {
      const response = await api.put<RecoverAccountResponse>(
        "accounts/restore"
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
