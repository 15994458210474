/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetAppointmentsApiCall,
  GetAppointmentsResponse
} from "./GetAppointments.types";

/**
 * Handles making the api call
 */
export const GetAppointments: GetAppointmentsApiCall =
  (api) => async (onSuccess, onError) => {
    try {
      const response = await api.get<GetAppointmentsResponse>(
        "/appointments/search/calendar"
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
