import { darken, lighten } from "@mui/material/styles";

/**
 * Defines the palette
 */
export const palette = {
  base: {
    dark: "#424124",
    white: "#fff",
    text: "#5e5d45",
    gray: "#aaa99c",
    grayDark: "#848370",
    grayLight: "#cfcfc8",
    grayThin: "#f5f5f4"
  },
  common: {
    white: "#fff",
    black: "#000"
  },
  primary: {
    light: "#f7ea71",
    lighter: "#f5e342",
    main: "#f2dc13",
    darker: "#c9b91c",
    dark: "#9f9625",
    20: "#4d5139",
    40: "#777430",
    60: "#9f9625",
    80: "#c9b91c",
    100: "#f2dc13",
    120: "#f5e342",
    140: "#f7ea71",
    160: "#faf1a1",
    180: "#fcf8d0"
  },
  secondary: {
    light: "#adcaf4",
    lighter: "#92b8f0",
    main: "#77a6ec",
    darker: "#668eca",
    dark: "#5576a8",
    20: "#354664",
    40: "#465e86",
    60: "#5576a8",
    80: "#668eca",
    100: "#77a6ec",
    120: "#92b8f0",
    140: "#adcaf4",
    160: "#c9dbf7",
    180: "#e4edfb"
  },
  error: {
    light: "#EC9595",
    lighter: "#E57171",
    main: "#DF4E4E",
    darker: darken("#DF4E4E", 0.4),
    dark: darken("#DF4E4E", 0.2),
    20: darken("#DF4E4E", 0.8),
    40: darken("#DF4E4E", 0.6),
    60: darken("#DF4E4E", 0.4),
    80: darken("#DF4E4E", 0.2),
    100: "#DF4E4E",
    120: "#E57171",
    140: "#EC9595",
    160: "#F2B8B8",
    180: "#F9DCDC",
    195: "#FDF6F6"
  },
  success: {
    light: "#92e4b3",
    lighter: "#6edb99",
    main: "#4AD280",
    darker: darken("#4AD280", 0.2),
    dark: darken("#4AD280", 0.4),
    20: darken("#4AD280", 0.8),
    40: darken("#4AD280", 0.6),
    60: darken("#4AD280", 0.4),
    80: darken("#4AD280", 0.2),
    100: "#4AD280",
    120: "#6edb99",
    140: "#92e4b3",
    160: "#b7edcc",
    180: "#dbf6e6",
    195: "#f6fdf9"
  },
  info: {
    light: lighten("#587baf", 0.2),
    lighter: lighten("#587baf", 0.4),
    main: "#6E9ADB",
    darker: darken("#587baf", 0.4),
    dark: darken("#587baf", 0.2),
    20: darken("#587baf", 0.8),
    40: darken("#587baf", 0.6),
    60: darken("#587baf", 0.4),
    80: darken("#587baf", 0.2),
    100: "#587baf",
    120: "#6E9ADB",
    140: lighten("#587baf", 0.4),
    160: lighten("#587baf", 0.6),
    180: lighten("#587baf", 0.8),
    195: lighten("#587baf", 1)
  },
  scheduled: {
    light: lighten("#a78bc1", 0.2),
    lighter: lighten("#a78bc1", 0.4),
    main: "#a78bc1",
    darker: darken("#a78bc1", 0.4),
    dark: darken("#a78bc1", 0.2),
    20: darken("#a78bc1", 0.8),
    40: darken("#a78bc1", 0.6),
    60: "#6D3F98",
    80: darken("#a78bc1", 0.2),
    100: "#a78bc1",
    120: lighten("#6D3F98", 0.4),
    140: lighten("#a78bc1", 0.4),
    160: lighten("#a78bc1", 0.6),
    180: "#ECDBF6",
    195: lighten("#a78bc1", 1)
  }
};
