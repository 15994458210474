import { apiService } from "../../services";

import type {
  SearchAppointmentsResponse,
  UpdateAppointmentStatusResponse,
  CreateAppointmentBody,
  AppointmentStatus,
  UpdateAppointmentBody,
  ConfirmGuestAppointmentBody,
} from "./appointments.types";

const appointmentsApi = apiService.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Query - Gets the appointments
     */
    getAppointments: builder.query<
      SearchAppointmentsResponse,
      { currentYear: number; weekNumber: number }
    >({
      query: ({ currentYear, weekNumber }) => ({
        url: `/appointments/search/calendar/year/${currentYear}/week/${weekNumber}`,
        method: "get",
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }),
      providesTags: ["Appointments"],
    }),

    /**
     * Mutation - Deletes an appointment
     */
    deleteAppointment: builder.mutation<void, { appointmentId: number }>({
      query: ({ appointmentId }) => ({
        url: `/appointments/${appointmentId}`,
        method: "delete",
      }),
      // invalidatesTags: ["Appointments"],
    }),

    /**
     * Mutation - Updates an appointment status
     */
    putAppointmentStatus: builder.mutation<
      UpdateAppointmentStatusResponse,
      { appointmentId: number; status: AppointmentStatus }
    >({
      query: ({ appointmentId, status }) => ({
        url: `/appointments/${appointmentId}/statuses/${status}`,
        method: "put",
      }),
      // invalidatesTags: ["Appointments"],
    }),

    /**
     * Mutation - Updates an existing appointment
     */
    putAppointment: builder.mutation<
      void,
      { appointmentId: number; data: UpdateAppointmentBody }
    >({
      query: ({ appointmentId, data }) => ({
        url: `/appointments/${appointmentId}`,
        method: "put",
        data,
      }),
      // invalidatesTags: ["Appointments"],
    }),

    /**
     * Mutation - Confirms a Guest Appointment
     */
    putGuestAppointment: builder.mutation<
      void,
      { appointmentId: number; data: ConfirmGuestAppointmentBody }
    >({
      query: ({ appointmentId, data }) => ({
        url: `/appointments/${appointmentId}/confirm`,
        method: "put",
        data,
      }),
      invalidatesTags: ["Appointments"],
    }),

    /**
     * Mutation - Creates an appointment
     */
    postAppointment: builder.mutation<void, CreateAppointmentBody>({
      query: (data) => ({
        url: "/appointments",
        method: "post",
        data,
      }),
      // invalidatesTags: ["Appointments"],
    }),
  }),
});

export const {
  useGetAppointmentsQuery,
  useLazyGetAppointmentsQuery,
  usePostAppointmentMutation,
  useDeleteAppointmentMutation,
  usePutAppointmentStatusMutation,
  usePutAppointmentMutation,
  usePutGuestAppointmentMutation,
} = appointmentsApi;
export const {
  endpoints: {
    getAppointments,
    putAppointmentStatus,
    deleteAppointment,
    putAppointment,
  },
} = appointmentsApi;
