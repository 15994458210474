import { createContext } from "react";

/**
 * Imports types
 */
import { SelectOption } from "../../types";

/**
 * Defines the available themes
 */
export enum Themes {
  lemontage = "lemontage",
  javasea = "javasea",
}

/**
 * Defines the theme types
 */
export type ThemeType = "lemontage" | "javasea";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  activeTheme: ThemeType;
  getThemeOptions: SelectOption[];
  changeTheme: (themeKey: ThemeType) => void;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  activeTheme: "javasea",
  getThemeOptions: [],
  changeTheme: () => {},
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
