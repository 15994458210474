import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports constants
 */
import { DEFAULT_SORT_ORDER, DEFAULT_ORDER_BY } from "../../constants";

/**
 * Imports types
 */
import { SortOrder, FilterModel } from "../../types";
import { CreateReportResponse } from "../useReports";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  order: SortOrder;
  orderBy: string | number;
  report?: CreateReportResponse["data"];
  waiting: boolean;
  loading: boolean;
  totalized: boolean;
  modelsInitialized: boolean;
  activeFilters: FilterModel[];
  setReport: Dispatch<SetStateAction<CreateReportResponse["data"] | undefined>>;
  setOrder: Dispatch<SetStateAction<SortOrder>>;
  setOrderBy: Dispatch<SetStateAction<string | number>>;
  handleSubmit: (filters: FilterModel[]) => void;
  resetFilters: () => void;
  deleteFilter: (filter: FilterModel) => void;
  handleSearch: (searchValue: string, totalized?: boolean) => void;
  getReportTimeInterval: () => any;
  shouldRenderReportResult: () => boolean;
  downloadPdfReport: () => Promise<void>;
  downloadExcelReport: () => Promise<void>;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  order: DEFAULT_SORT_ORDER,
  orderBy: DEFAULT_ORDER_BY,
  report: undefined,
  waiting: false,
  loading: false,
  totalized: false,
  modelsInitialized: false,
  activeFilters: [],
  setReport: () => {},
  setOrder: () => {},
  setOrderBy: () => {},
  handleSubmit: () => {},
  handleSearch: () => {},
  resetFilters: () => {},
  deleteFilter: () => {},
  getReportTimeInterval: () => {},
  shouldRenderReportResult: () => false,
  downloadPdfReport: () => new Promise((res) => res()),
  downloadExcelReport: () => new Promise((res) => res()),
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
