/**
 * Imports hooks
 */
import { useFieldArray } from "..";

/**
 * Imports types
 */
import { TyreDimension } from "../../types";
import {
  UseTyreDimensionsFormProps,
  AddDimensionsRowProps
} from "./useTyreDimensionsForm.types";

/**
 * Provides state management for the products form
 */
export const useTyreDimensionsForm = (props: UseTyreDimensionsFormProps) => {
  const { control, watch } = props;

  /**
   * Gets the products state
   */
  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name: "tyreService.tyreDimensions"
  });

  /**
   * Watches any changes to the dimensions fields
   */
  const watchDimensions = watch("tyreService.tyreDimensions");

  /**
   * Combines the field and the watcher to always keep the values in sync
   */
  const tyreDimensions = fields.map((field, index) => {
    return {
      ...field,
      ...watchDimensions[index]
    };
  });

  /**
   * Handles adding a new empty product row
   */
  const addNewDimensionsRow = () => {
    append({
      tyreWidth: "",
      tyreHeight: "",
      tyreRim: "",
      tyrePressure: "",
      boltTorque: "",
      dotNumber: ""
    });
  };

  /**
   * Handles adding a product row
   */
  const addDimensionsRow = (props: AddDimensionsRowProps) => {
    append(props);
  };

  /**
   * Handles updating a row
   */
  const updateDimensionsRow = (index: number, row: TyreDimension) => {
    update(index, row);
  };

  /**
   * Handles removing a dimension row
   */
  const removeDimensionsRow = (index: number) => {
    remove(index);
  };

  /**
   * Handles updating the dimensions rows
   */
  const replaceDimensionsRows = (dimensions: TyreDimension[]) => {
    replace(dimensions);
  };

  return {
    tyreDimensions,
    addNewDimensionsRow,
    addDimensionsRow,
    replaceDimensionsRows,
    removeDimensionsRow,
    updateDimensionsRow
  };
};
