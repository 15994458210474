/**
 * External Imports
 */
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";

/**
 * Imports types
 */
import { ScrollToElementOptions, FieldError, DeepMap } from "./useScroll.types";

/**
 * Defines the main hook
 */
export const useScroll = () => {
  /**
   * Handles scrolling to the error
   */
  const scrollToError = async (
    errors: DeepMap<any, FieldError>,
    errorKeys: string[]
  ) => {
    /**
     * Gets the errors from the form state
     */
    const keys = Object.keys(errors);

    /**
     * Gets the error names
     */
    const errorNames = errorKeys.filter((errorKey) => keys.includes(errorKey));

    /**
     * Finds the first error in the dom and scrolls it into view and focuses on it
     */
    if (errorNames.length > 0) {
      const errorName = errorNames[0];
      const elementNode = document.getElementsByName(errorName)[0];

      if (elementNode) {
        await smoothScrollIntoView(elementNode);
        elementNode.focus();
      }
    }
  };

  /**
   * Handles scrolling to a given element
   */
  const scrollToElement = (
    element: "" | HTMLElement | null,
    options?: ScrollToElementOptions
  ) => {
    if (element) {
      const { scrollContainerId, offset, fallback } = options || {};

      /**
       * Defines the scroll offset
       */
      const scrollOffset = offset || 0;

      /**
       * Defines the position of the element
       */
      const position = element.offsetTop;

      /**
       * Defines the offset position
       */
      const offsetPosition = position - scrollOffset;

      /**
       * Defines the scrollTo options
       */
      const scrollToOptions: ScrollToOptions = {
        top: offsetPosition,
        behavior: "smooth"
      };

      if (scrollContainerId) {
        const container = document.getElementById(scrollContainerId);
        if (container) return container.scrollTo(scrollToOptions);
      }

      /**
       * Window - scrollTo fallback
       */
      if (fallback === "window") return window.scrollTo(scrollToOptions);

      /**
       * Element - scrollIntoView fallback
       */
      if (fallback === "scrollIntoView")
        return element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return {
    scrollToError,
    scrollToElement
  };
};
