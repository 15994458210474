/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetStatisticsApiCall,
  GetStatisticsResponse
} from "./GetStatistics.types";

/**
 * Handles making the api call
 */
export const GetStatistics: GetStatisticsApiCall =
  (api) => async (onSuccess, onError) => {
    try {
      const response = await api.get<GetStatisticsResponse>("/statistics");
      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
