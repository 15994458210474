/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GenerateReportsPDFApiCall,
  GenerateReportsPDFResponse
} from "./GenerateReportsPDF.types";

/**
 * Handles making the api call
 */
export const GenerateReportsPDF: GenerateReportsPDFApiCall =
  (api) => async (params, body, onSuccess, onError) => {
    try {
      const { type, format, interval } = params;

      const response = await api.post<GenerateReportsPDFResponse>(
        `/generate/report/${type}/${format}/${interval}`,
        body,
        { responseType: "blob" }
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
