/**
 * Imports styled
 */
import { styled } from "@mui/material";

/**
 * Styles the container
 */
export const Container = styled("div")((props) => {
  const { theme } = props;
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
    background: theme.palette.base.grayThin,
    padding: "0 8px",
    height: 24,
    maxWidth: "fit-content",
    "& svg": {
      width: 16,
      height: 16,
      color: theme.palette.base.text,
      marginRight: 5
    }
  };
});
