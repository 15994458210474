/**
 * Imports styled
 */
import { styled } from "@mui/material";
import { keyframes } from "@mui/system";

/**
 * Styles the Container
 */
export const Container = styled("div")(() => {
  return {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };
});

/**
 * Styles the Spinner
 */
export const Spinner = styled("div")((props) => {
  const { theme } = props;

  /**
   * Defines the spinning key frames
   */
  const spin = keyframes`
    from {
       transform: rotate(0deg);
     }
     to {
       transform: rotate(360deg);
     }
   `;

  return {
    display: "inline-block",
    position: "relative",
    width: 80,
    height: 80,
    "& div": {
      boxSizing: "border-box",
      dispaly: "block",
      position: "absolute",
      width: 64,
      height: 64,
      margin: 8,
      border: `8px solid ${theme.palette.primary.main}`,
      animation: `${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
      borderRadius: "50%",
      borderColor: `${theme.palette.primary.main} transparent transparent transparent`
    },
    "& div:nth-of-type(1)": {
      animationDelay: "-0.45s"
    },
    "& div:nth-of-type(2)": {
      animationDelay: "-0.3s"
    },
    "& div:nth-of-type(3)": {
      animationDelay: "-0.15s"
    }
  };
});
