/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  TransformWorkOrderDraftApiCall,
  TransformWorkOrderDraftResponse
} from "./TransformWorkOrderDraft.types";

/**
 * Handles making the api call
 */
export const TransformWorkOrderDraft: TransformWorkOrderDraftApiCall =
  (api) => async (draftId, onSuccess, onError) => {
    try {
      const response = await api.get<TransformWorkOrderDraftResponse>(
        `/work-orders/${draftId}/transform`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
