/**
 * Imports transformers
 */
import { fromSnakeToCamel } from "../../useApi";

/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetClientQueryResponse,
  GetClientQueryApiCall,
  GetClientQueryVariables
} from "./GetClientQuery.types";

/**
 * Imports queries
 */
import { getClientQuery } from "../queries";

/**
 * Handles making the api call
 */
export const GetClientQuery: GetClientQueryApiCall =
  (api) => async (queryVariables, onSuccess, onError) => {
    try {
      const response = await api.query<
        GetClientQueryResponse,
        GetClientQueryVariables
      >({
        query: getClientQuery,
        variables: queryVariables
      });

      if (response) {
        const { data } = response;
        onSuccess(fromSnakeToCamel(data), response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
