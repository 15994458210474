/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  ValidateRegistrationCodeApiCall,
  ValidateRegistrationCodeResponse
} from "./ValidateRegistrationCode.types";

/**
 * Handles making the api call
 */
export const ValidateRegistrationCode: ValidateRegistrationCodeApiCall =
  (api) => async (body, onSuccess, onError) => {
    try {
      const response = await api.post<ValidateRegistrationCodeResponse>(
        "/registration/request/validate-code",
        fromCamelToSnake(body)
      );

      if (response) {
        const { data } = response;

        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
