import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import { LoyaltyCard, FilterModel, TableData, SortOrder } from "../../types";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  loading: boolean;
  tableRowsLoading: boolean;
  orderBy: string;
  orderDir: SortOrder;
  totalLoyaltyCards: number;
  pageCount: number;
  loyaltyCards: LoyaltyCard[];
  activeFilters: FilterModel[];
  modelsInitialized: boolean;
  handleSubmit: (filters: FilterModel[]) => void;
  resetFilters: () => void;
  deleteFilter: (filter: FilterModel) => void;
  handleSearch: (searchValue: string) => void;
  formatActiveFilters: (filters: FilterModel[]) => FilterModel[];
  setPageCount: Dispatch<SetStateAction<number>>;
  setTotalLoyaltyCards: Dispatch<SetStateAction<number>>;
  setLoyaltyCards: Dispatch<SetStateAction<LoyaltyCard[]>>;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  handleSort: (e: React.MouseEvent<any>, property: keyof TableData) => void;
  updateLoyaltyCard: (card: LoyaltyCard) => void;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  loading: false,
  tableRowsLoading: false,
  orderBy: "finished",
  orderDir: "desc",
  totalLoyaltyCards: 0,
  pageCount: 0,
  loyaltyCards: [],
  activeFilters: [],
  modelsInitialized: false,
  handleSubmit: () => {},
  resetFilters: () => {},
  deleteFilter: () => {},
  handleSearch: () => {},
  formatActiveFilters: () => [],
  setPageCount: () => {},
  setTotalLoyaltyCards: () => {},
  setLoyaltyCards: () => {},
  handlePageChange: () => {},
  handleSort: () => {},
  updateLoyaltyCard: () => {}
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
