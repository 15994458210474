/**
 * Imports types
 */
import { RegisterUserBody } from "../../types";
import { ValidateRegistrationCodeBody, useUtils, ApiError } from "../index";
import { FormBody, UserInfoData, CompanyInfoData } from "./useRegister.types";

/**
 * Defines the useRegister utils hooks
 */
export const useRegisterUtils = () => {
  /**
   * Gets the uitls
   */
  const { removeEmpty } = useUtils();

  /**
   * Handles building the default values for the user info
   */
  const buildUserInfoDefaultValues = () => {
    /**
     * Builds the form default values
     */
    const defaultValues: UserInfoData = {
      username: "",
      password: "",
      passwordConfirmation: ""
    };

    return defaultValues;
  };

  /**
   * Handles building the default values for the company info
   */
  const buildCompanyInfoDefaultValues = () => {
    /**
     * Builds the form default values
     */
    const defaultValues: CompanyInfoData = {
      companyName: "",
      vatNumber: "",
      regNumber: "",
      country: "",
      city: "",
      county: "",
      street: "",
      postCode: "",
      streetNumber: "",
      emailAddress: "",
      phoneNumber: "",
      website: "",
      timeSheet: ""
    };

    return defaultValues;
  };

  /**
   * Handles building the default values
   */
  const buildDefaultValues = () => {
    /**
     * Builds the form default values
     */
    const defaultValues: FormBody = {
      email: "",
      code: "",
      userData: {
        username: "",
        password: "",
        passwordConfirmation: ""
      },
      companyData: {
        companyName: "",
        vatNumber: "",
        regNumber: "",
        country: "",
        city: "",
        county: "",
        street: "",
        postCode: "",
        streetNumber: "",
        emailAddress: "",
        phoneNumber: "",
        website: "",
        timeSheet: ""
      }
    };

    return defaultValues;
  };

  /**
   * Handles building the request body
   */
  const buildRequestBody = (
    validationData: ValidateRegistrationCodeBody,
    userInfoData: UserInfoData,
    companyInfoData: CompanyInfoData
  ) => {
    const { email, code } = validationData;
    const { username, password, passwordConfirmation } = userInfoData;

    /**
     * Gets the user data
     */
    const userData = {
      username,
      password,
      passwordConfirmation
    };

    /**
     * Gets the company data
     */
    const companyData = removeEmpty(companyInfoData);

    /**
     * Builds the request body
     */
    const body: RegisterUserBody = {
      email,
      code,
      userData,
      companyData
    };

    return body;
  };

  /**
   * Handles getting the api error message
   */
  const getApiErrorMessage = (error: ApiError) => {
    /**
     * Initializes the default message
     */
    let message = "Unknown Error";

    if (error.errorMessage) {
      const { errorMessage } = error;

      if (errorMessage.email && errorMessage.email.length > 0) {
        message = errorMessage.email[0];
      } else {
        message = errorMessage;
      }
    }

    return message;
  };

  return {
    buildRequestBody,
    getApiErrorMessage,
    buildDefaultValues,
    buildUserInfoDefaultValues,
    buildCompanyInfoDefaultValues
  };
};
