import { useState } from "react";

/**
 * External imports
 */
import { camelCase, upperFirst } from "lodash";

/**
 * Imports hooks
 */
import { useSelector, useApi, useActions, useTranslation } from "..";

/**
 * Imports types
 */
import { Timesheet, TimesheetOption } from "../../types";
import { RequestOnError, CreateTimeSheetOnSuccess } from "../../hooks/useApi";

/**
 * Imports constants
 */
import { TIMESHEET } from "../../constants";

/**
 * Provides state management for the timesheet component
 */
export const useTimesheet = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the account state
   */
  const { timesheet, accountInformation } = useSelector(
    (state) => state.account,
  );

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the update loading state
   */
  const [updateLoading, setUpdateLoading] = useState(false);

  /**
   * Gets the api calls
   */
  const { queries } = useApi({ withCredentials: true });

  /**
   * Gets the redux actions
   */
  const { dispatchMessage, updateAccountTimesheet } = useActions();

  /**
   * Creates a new timesheet
   */
  const createTimesheet = async (status: string) => {
    setLoading(true);

    /**
     * Handles the success of the api call
     */
    const onSuccess: CreateTimeSheetOnSuccess = ({ createTimeSheet }) => {
      updateAccountTimesheet(createTimeSheet);
      setLoading(false);
    };

    /**
     * Handles the request error
     */
    const handleError: RequestOnError = () => {
      dispatchMessage({
        message: "Error: Creating timesheet failed.",
        severity: "error",
      });
    };

    await queries.createTimeSheet({ status }, onSuccess, handleError);
  };

  /**
   * Updates a timesheet of a user
   */
  const updateTimesheet = async (status: string, userId: number) => {
    setUpdateLoading(true);

    /**
     * Handles the success of the api call
     */
    const onSuccess: CreateTimeSheetOnSuccess = (data) => {
      setUpdateLoading(false);
    };

    /**
     * Handles the request error
     */
    const handleError: RequestOnError = () => {
      dispatchMessage({
        message: "Error: Updating timesheet failed.",
        severity: "error",
      });
    };

    await queries.updateTimesheetByAdmin(
      { status, userId },
      onSuccess,
      handleError,
    );
  };

  /**
   * Handles rendering the timesheet text for the button
   */
  const renderTimesheetText = () => {
    if (loading || accountInformation.id === -1) return `${t("Loading")}...`;
    if (!timesheet) return t("SelectStatus");

    return t(upperFirst(camelCase(timesheet.status.toLowerCase())));
  };

  /**
   * Returns the status options
   */
  const getStatusOptions = (currentTimesheet?: Timesheet) => {
    const options: TimesheetOption[] = [
      { label: t("Working"), value: TIMESHEET.WORKING, variant: "success" },
      { label: t("Break"), value: TIMESHEET.BREAK, variant: "warning" },
      { label: t("Away"), value: TIMESHEET.AWAY, variant: "warning" },
      { label: t("Lunch"), value: TIMESHEET.LUNCH, variant: "warning" },
      { label: t("OnHoliday"), value: TIMESHEET.ON_HOLIDAY, variant: "error" },
      { label: t("GoneHome"), value: TIMESHEET.GONE_HOME, variant: "error" },
    ];

    if (!currentTimesheet) return options;

    return options.filter(
      (option) =>
        option.label !==
        t(upperFirst(camelCase(currentTimesheet.status.toLowerCase()))),
    );
  };

  /**
   * Handles checking if the timesheet timer should render
   */
  const shouldRenderTimesheetTimer = () => {
    if (!timesheet) return true;

    /**
     * Gets the status
     */
    const { status } = timesheet;

    if (status === TIMESHEET.ON_HOLIDAY || status === TIMESHEET.GONE_HOME)
      return false;

    return true;
  };

  /**
   * Returns the status color variant
   */
  const getStatusVariant = (timesheet?: Timesheet) => {
    if (timesheet && !loading) {
      switch (timesheet.status.toUpperCase()) {
        case "WORKING":
          return "success";
        case "LUNCH":
        case "BREAK":
        case "AWAY":
          return "warning";
        case "ON_HOLIDAY":
        case "GONE_HOME":
          return "error";
      }
    }

    return "pending";
  };

  return {
    loading,
    updateLoading,
    timesheet,
    createTimesheet,
    updateTimesheet,
    renderTimesheetText,
    getStatusOptions,
    getStatusVariant,
    shouldRenderTimesheetTimer,
  };
};
