import { useCallback } from "react";

/**
 * External imports
 */
import { snakeCase } from "lodash";

/**
 * Imports hooks
 */
import { useSelector } from "../../hooks";
import { useAccountSettings } from "../useAccountSettings";

/**
 * Imports types
 */
import {
  UpdateAccountSettingBody,
  CreateAccountSettingBody,
} from "../../hooks";
import { SMSType, SMSLanguage } from "../../redux";

export const useSMSSettingsUtils = () => {
  const {
    smsMessageAppointmentCreated,
    smsMessageAppointmentNotification,
    smsMessageItpNotification,
    smsMessageAppointmentDeleted,
  } = useAccountSettings();
  const { smsDefaultMessages } = useSelector((state) => state.account);

  const calculateSmsBytesAndCredits = (message: string) => {
    const GSM_CHAR_LIMIT = 160;
    const GSM_PART_SIZE = 153;
    const UNICODE_CHAR_LIMIT = 70;
    const UNICODE_PART_SIZE = 67;

    // Helper function to check if a string contains any Unicode characters
    function containsUnicode(str: string): boolean {
      for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127) {
          return true;
        }
      }
      return false;
    }

    // Determine if the message contains Unicode characters
    const isUnicode = containsUnicode(message);

    // Set appropriate character limits and part sizes based on encoding type
    const charLimit = isUnicode ? UNICODE_CHAR_LIMIT : GSM_CHAR_LIMIT;
    const partSize = isUnicode ? UNICODE_PART_SIZE : GSM_PART_SIZE;

    // Calculate the total number of SMS parts
    const totalLength = message.length;
    if (totalLength <= charLimit) {
      return 1;
    } else {
      return Math.ceil(totalLength / partSize);
    }
  };

  const getSMSValue = useCallback(
    (type: SMSType, language: SMSLanguage): string => {
      switch (type) {
        case "smsMessageAppointmentCreated":
          if (!smsMessageAppointmentCreated) {
            return smsDefaultMessages.smsMessageAppointmentCreated[language];
          }

          return smsMessageAppointmentCreated.options![language]
            ? smsMessageAppointmentCreated.options![language]
            : smsDefaultMessages.smsMessageAppointmentCreated[language];

        case "smsMessageAppointmentNotification":
          if (!smsMessageAppointmentNotification) {
            return smsDefaultMessages.smsMessageAppointmentNotification[
              language
            ];
          }

          return smsMessageAppointmentNotification.options![language]
            ? smsMessageAppointmentNotification.options![language]
            : smsDefaultMessages.smsMessageAppointmentNotification[language];

        case "smsMessageItpNotification":
          if (!smsMessageItpNotification) {
            return smsDefaultMessages.smsMessageItpNotification[language];
          }

          return smsMessageItpNotification.options![language]
            ? smsMessageItpNotification.options![language]
            : smsDefaultMessages.smsMessageItpNotification[language];

        case "smsMessageAppointmentDeleted":
          if (!smsMessageAppointmentDeleted) {
            return smsDefaultMessages.smsMessageAppointmentDeleted[language];
          }

          return smsMessageAppointmentDeleted.options![language]
            ? smsMessageAppointmentDeleted.options![language]
            : smsDefaultMessages.smsMessageAppointmentDeleted[language];
        default:
          return "";
      }
    },
    [
      smsDefaultMessages,
      smsMessageAppointmentCreated,
      smsMessageAppointmentNotification,
      smsMessageItpNotification,
      smsMessageAppointmentDeleted,
    ],
  );

  const shouldUpdateExistingSetting = useCallback(
    (type: SMSType) => {
      switch (type) {
        case "smsMessageAppointmentCreated":
          return smsMessageAppointmentCreated ? true : false;
        case "smsMessageAppointmentNotification":
          return smsMessageAppointmentNotification ? true : false;
        case "smsMessageItpNotification":
          return smsMessageItpNotification ? true : false;
        case "smsMessageAppointmentDeleted":
          return smsMessageAppointmentDeleted ? true : false;
        default:
          return false;
      }
    },
    [
      smsMessageAppointmentCreated,
      smsMessageAppointmentNotification,
      smsMessageItpNotification,
      smsMessageAppointmentDeleted,
    ],
  );

  const getSMSSetting = (type: SMSType) => {
    switch (type) {
      case "smsMessageAppointmentCreated":
        return smsMessageAppointmentCreated!;
      case "smsMessageAppointmentNotification":
        return smsMessageAppointmentNotification!;
      case "smsMessageItpNotification":
        return smsMessageItpNotification!;
      case "smsMessageAppointmentDeleted":
        return smsMessageAppointmentDeleted!;
    }
  };

  const buildUpdateSettingBody = useCallback(
    (type: SMSType, language: SMSLanguage, value: string) => {
      const setting = getSMSSetting(type);
      const body: UpdateAccountSettingBody = {
        name: snakeCase(type),
        value: "true",
        options: {
          ro: setting.options!.ro,
          hu: setting.options!.hu,
          en: setting.options!.en,
        },
      };

      body.options![language as SMSLanguage] = value;

      return body as UpdateAccountSettingBody;
    },
    [
      smsMessageAppointmentCreated,
      smsMessageAppointmentNotification,
      smsMessageItpNotification,
      smsMessageAppointmentDeleted,
    ],
  );

  const buildCreateSettingBody = (
    type: SMSType,
    language: SMSLanguage,
    value: string,
  ) => {
    const body: CreateAccountSettingBody = {
      name: snakeCase(type),
      value: "true",
      options: {
        ro: "",
        hu: "",
        en: "",
      },
    };

    body.options![language as SMSLanguage] = value;

    return body as CreateAccountSettingBody;
  };

  return {
    calculateSmsBytesAndCredits,
    shouldUpdateExistingSetting,
    getSMSValue,
    getSMSSetting,
    buildCreateSettingBody,
    buildUpdateSettingBody,
  };
};
