import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import {
  RequestUserRegistrationBody,
  ValidateRegistrationCodeBody
} from "../../hooks";
import { UserInfoData, CompanyInfoData } from "./useRegister.types";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  steps: string[];
  loading: boolean;
  activeStep: number;
  requestSent: boolean;
  requestValidated: boolean;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setUserInfoData: Dispatch<SetStateAction<UserInfoData | undefined>>;
  registerUser: (body: CompanyInfoData) => Promise<void>;
  requestRegistration: (body: RequestUserRegistrationBody) => Promise<void>;
  validateRegistration: (body: ValidateRegistrationCodeBody) => Promise<void>;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  steps: [],
  activeStep: 0,
  loading: false,
  requestSent: false,
  requestValidated: false,
  setActiveStep: () => {},
  setUserInfoData: () => {},
  registerUser: (body: CompanyInfoData) => new Promise((res) => res()),
  requestRegistration: (body: RequestUserRegistrationBody) =>
    new Promise((res) => res()),
  validateRegistration: (body: ValidateRegistrationCodeBody) =>
    new Promise((res) => res())
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
