import { createSlice } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { ReduxSlice } from "../../features";
import { PayloadAction } from "@reduxjs/toolkit";
import { SnackbarState, SnackbarConfig } from "./snackbar.types";

/**
 * Defines the snackbar initial state
 */
const initialState: SnackbarState = {
  open: false,
  config: {
    title: "",
    description: "",
    variant: "loading",
    closeAfter: undefined,
  },
};

/**
 * Handles creating the a redux state slice
 *
 * - This will automatically generate action-creators and action-types that correspond
 * to the reducers and state.
 * - The internal usage of Immer allows for writing 'mutating' update logic that becomes
 * correct immutable updates.
 */
export const snackbarSlice = createSlice({
  name: ReduxSlice.Snackbar,
  initialState,
  reducers: {
    dispatchSnackbar: (state, action: PayloadAction<SnackbarConfig>) => {
      state.open = true;
      state.config = action.payload;
    },
    resetSnackbar: (state) => {
      state.open = false;
      state.config = initialState.config;
    },
  },
});

/**
 * Exports the action-creators
 */
export const snackbarActionCreators = snackbarSlice.actions;

/**
 * Exports the reducer
 */
export const snackbarReducer = snackbarSlice.reducer;
