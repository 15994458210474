/**
 * External Imports
 */
import { v4 as generate } from "uuid";

/**
 * Imports Material UI Components
 */
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

/**
 * Imports components
 */
import { Icon } from "../Icon";

/**
 * Imports types
 */
import { MessageBoxListProps } from "./MessageBoxList.types";

/**
 * Imports styled components
 */
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "./MessageBoxList.styles";

/**
 * Defines the default props
 */
const defaultProps: MessageBoxListProps = {
  list: [],
  valueKey: ""
};

/**
 * Displays the component
 */
export const MessageBoxList: React.FC<MessageBoxListProps> = (props) => {
  const { list, valueKey } = props;

  /**
   * Handles rendering the list items
   */
  const renderListItems = () => {
    return list.map((listItem) => {
      const listItemKey = generate();
      const text = valueKey ? listItem[valueKey] : listItem;

      return (
        <ListItem key={listItemKey}>
          <ListItemIcon>
            <Icon icon={<FiberManualRecordIcon />} />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      );
    });
  };

  return <List dense={true}>{renderListItems()}</List>;
};

MessageBoxList.defaultProps = defaultProps;
