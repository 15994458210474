import { createSlice } from "@reduxjs/toolkit";

/**
 * Imports utils
 */
import { buildMessageConfig } from "./message.utils";

/**
 * Imports types
 */
import { ReduxSlice } from "../../features";
import { PayloadAction } from "@reduxjs/toolkit";
import { MessageState, MessageConfig } from "./message.types";

/**
 * Defines the message initial state
 */
const initialState: MessageState = {
  open: false,
  config: {
    title: "",
    message: "",
    severity: "success",
    permanent: false,
    autoClose: 5000,
    origin: {
      vertical: "top",
      horizontal: "center",
    },
  },
};

/**
 * Handles creating the a redux state slice
 *
 * - This will automatically generate action-creators and action-types that correspond
 * to the reducers and state.
 * - The internal usage of Immer allows for writing 'mutating' update logic that becomes
 * correct immutable updates.
 */
export const messageSlice = createSlice({
  name: ReduxSlice.Message,
  initialState,
  reducers: {
    dispatchMessage: (state, action: PayloadAction<MessageConfig>) => {
      state.open = true;
      state.config = buildMessageConfig(action.payload, initialState);
    },
    resetMessage: (state) => {
      state.open = false;
      state.config = initialState.config;
    },
  },
});

/**
 * Exports the action-creators
 */
export const messageActionCreators = messageSlice.actions;

/**
 * Exports the reducer
 */
export const messageReducer = messageSlice.reducer;
