/**
 * Imports transformers
 */
import { transformCreateTimesheetResponse } from "../../transformers";

/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  UpdateTimesheetByAdminResponse,
  UpdateTimesheetByAdminApiCall
} from "./UpdateTimesheetByAdmin.types";

/**
 * Imports queries
 */
import { updateTimesheetByAdmin } from "../queries";

/**
 * Handles making the api call
 */
export const UpdateTimesheetByAdmin: UpdateTimesheetByAdminApiCall =
  (api) => async (queryVariables, onSuccess, onError) => {
    try {
      const response = await api.query<UpdateTimesheetByAdminResponse>({
        query: updateTimesheetByAdmin,
        variables: fromCamelToSnake(queryVariables),
        fetchPolicy: "no-cache"
      });

      if (response) {
        onSuccess(transformCreateTimesheetResponse(response), response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
