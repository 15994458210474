import { createContext } from "react";

/**
 * Imports types
 */
import { Appointment, CustomerOrder } from "../../types";
import { DragEndResult } from "../useKanban";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  loading: boolean;
  loadingAppointments: boolean;
  customersOrder: CustomerOrder[];
  viewPerGroup?: boolean;
  customerOrderInEdit: string;
  appointments: Appointment[];
  activeOrg?: string | number;
  setActiveOrg: React.Dispatch<
    React.SetStateAction<string | number | undefined>
  >;
  setCustomerOrderInEdit: React.Dispatch<React.SetStateAction<string>>;
  setViewPerGroup: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  deleteCustomerOrder: (orderId: string) => Promise<void>;
  handleDragAndDrop: (dragResult: DragEndResult) => Promise<void>;
  closeCustomerOrder: (orderId: number) => Promise<void>;
  getAppointments: (
    activeOrg?: string | number,
    skipLoading?: boolean
  ) => Promise<void>;
  createCustomerOrderFromAppointment: (data: Appointment) => Promise<void>;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  loading: false,
  loadingAppointments: false,
  customersOrder: [],
  viewPerGroup: false,
  customerOrderInEdit: "",
  appointments: [],
  activeOrg: "",
  setActiveOrg: () => {},
  setCustomerOrderInEdit: () => {},
  setViewPerGroup: () => {},
  deleteCustomerOrder: async () => {},
  handleDragAndDrop: async () => {},
  closeCustomerOrder: async () => {},
  getAppointments: async () => {},
  createCustomerOrderFromAppointment: async () => {},
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
