/**
 * Imports types
 */
import { AxiosInstance } from "axios";

/**
 * Imports api callers
 */
import { Logout } from "./Logout";
import { LoginUser } from "./LoginUser";
import { GetClient } from "./GetClient";
import { GetProduct } from "./GetProduct";
import { UpdateUser } from "./UpdateUser";
import { GetCarType } from "./GetCarType";
import { CreateUser } from "./CreateUser";
import { DeleteUser } from "./DeleteUser";
import { TrackReport } from "./TrackReport";
import { GetCarTypes } from "./GetCarTypes";
import { SearchUsers } from "./SearchUsers";
import { DeleteClient } from "./DeleteClient";
import { GetUserRoles } from "./GetUserRoles";
import { CreateReport } from "./CreateReport";
import { GetWorkOrder } from "./GetWorkOrder";
import { CreateClient } from "./CreateClient";
import { UpdateClient } from "./UpdateClient";
import { DeleteReport } from "./DeleteReport";
import { CreateWorker } from "./CreateWorker";
import { UpdateWorker } from "./UpdateWorker";
import { DeleteWorker } from "./DeleteWorker";
import { RegisterUser } from "./RegisterUser";
import { GetStatistics } from "./GetStatistics";
import { SearchClients } from "./SearchClients";
import { GetWorkersOld } from "./GetWorkersOld";
import { UpdateAddress } from "./UpdateAddress";
import { CreateProduct } from "./CreateProduct";
import { DeleteProduct } from "./DeleteProduct";
import { UpdateProduct } from "./UpdateProduct";
import { UpdateCompany } from "./UpdateCompany";
import { CreateCarType } from "./CreateCarType";
import { UpdateCarType } from "./UpdateCarType";
import { DeleteCarType } from "./DeleteCarType";
import { CreateContact } from "./CreateContact";
import { UpdateContact } from "./UpdateContact";
import { DeleteContact } from "./DeleteContact";
import { SwitchAccount } from "./SwitchAccount";
import { SearchWorkers } from "./SearchWorkers";
import { SearchProducts } from "./SearchProducts";
import { SuspendAccount } from "./SuspendAccount";
import { RecoverAccount } from "./RecoverAccount";
import { ChangePassword } from "./ChangePassword";
import { CreateGuaranty } from "./CreateGuaranty";
import { DeleteGuaranty } from "./DeleteGuaranty";
import { UpdateGuaranty } from "./UpdateGuaranty";
import { GetPaymentTypes } from "./GetPaymentTypes";
import { UpdateWorkOrder } from "./UpdateWorkOrder";
import { CreateWorkOrder } from "./CreateWorkOrder";
import { DeleteWorkOrder } from "./DeleteWorkOrder";
import { DeleteTyreHotel } from "./DeleteTyreHotel";
import { UpdateTyreHotel } from "./UpdateTyreHotel";
import { CreateTyreHotel } from "./CreateTyreHotel";
import { DeleteTimesheet } from "./DeleteTimesheet";
import { UpdateTimesheet } from "./UpdateTimesheet";
import { GetAppointments } from "./GetAppointments";
import { SearchWorkOrders } from "./SearchWorkOrders";
import { GetAssetManifest } from "./GetAssetManifest";
import { SearchTyreHotels } from "./SearchTyreHotels";
import { ExportTyreHotels } from "./ExportTyreHotels";
import { SearchTimesheets } from "./SearchTimesheets";
import { DeletePaymentType } from "./DeletePaymentType";
import { UpdatePaymentType } from "./UpdatePaymentType";
import { CreateLoyaltyCard } from "./CreateLoyaltyCard";
import { CreatePaymentType } from "./CreatePaymentType";
import { DeleteLoyaltyCard } from "./DeleteLoyaltyCard";
import { UpdateLoyaltyCard } from "./UpdateLoyaltyCard";
import { GetDraftWorkOrder } from "./GetDraftWorkOrder";
import { CreateAppointment } from "./CreateAppointment";
import { DeleteAppointment } from "./DeleteAppointment";
import { UpdateAppointment } from "./UpdateAppointment";
import { TransferWorkOrder } from "./TransferWorkOrder";
import { GetCustomersOrder } from "./GetCustomersOrder";
import { GetAccountSettings } from "./GetAccountSettings";
import { GetDailyWorkOrders } from "./GetDailyWorkOrders";
import { SearchLoyaltyCards } from "./SearchLoyaltyCards";
import { CreateOrganization } from "./CreateOrganization";
import { DeleteOrganization } from "./DeleteOrganization";
import { UpdateOrganization } from "./UpdateOrganization";
import { GenerateReportsPDF } from "./GenerateReportsPDF";
import { GetTimesheetReports } from "./GetTimesheetReports";
import { CreateClientCompany } from "./CreateClientCompany";
import { SearchByPlateNumber } from "./SearchByPlateNumber";
import { CreateCustomerOrder } from "./CreateCustomerOrder";
import { UpdateCustomerOrder } from "./UpdateCustomerOrder";
import { DeleteCustomerOrder } from "./DeleteCustomerOrder";
import { GetAppointmentGroups } from "./GetAppointmentGroups";
import { GetUserOrganizations } from "./GetUserOrganizations";
import { CreateCompanyAddress } from "./CreateCompanyAddress";
import { CreatePriceCondition } from "./CreatePriceCondition";
import { UpdatePriceCondition } from "./UpdatePriceCondition";
import { DeletePriceCondition } from "./DeletePriceCondition";
import { CreateWorkOrderDraft } from "./CreateWorkOrderDraft";
import { DeleteWorkOrderDraft } from "./DeleteWorkOrderDraft";
import { UpdateWorkOrderDraft } from "./UpdateWorkOrderDraft";
import { CreateAccountSetting } from "./CreateAccountSetting";
import { UpdateAccountSetting } from "./UpdateAccountSetting";
import { GetCustomerOrderById } from "./GetCustomerOrderById";
import { SearchWorkOrderDrafts } from "./SearchWorkOrderDrafts";
import { UpdateWorkOrderStatus } from "./UpdateWorkOrderStatus";
import { GetSuspendedWorkOrders } from "./GetSuspendedWorkOrders";
import { UpdateAppointmentGroup } from "./UpdateAppointmentGroup";
import { DeleteAppointmentGroup } from "./DeleteAppointmentGroup";
import { DeleteWorkOrderProduct } from "./DeleteWorkOrderProduct";
import { CreateWorkOrderProduct } from "./CreateWorkOrderProduct";
import { CreateAppointmentGroup } from "./CreateAppointmentGroup";
import { UpdateWorkOrderProduct } from "./UpdateWorkOrderProduct";
import { TransformWorkOrderDraft } from "./TransformWorkOrderDraft";
import { UpdateAppointmentStatus } from "./UpdateAppointmentStatus";
import { RequestUserRegistration } from "./RequestUserRegistration";
import { UpdateWorkOrderRelations } from "./UpdateWorkOrderRelations";
import { ValidateRegistrationCode } from "./ValidateRegistrationCode";
import { DeleteAccount } from "./DeleteAccount";
import { GetDeleteRequests } from "./GetDeleteRequests";
import { ReSendSmsITP } from "./ReSendSmsITP";
import { CloseTyreHotel } from "./CloseTyreHotel";
import { GenerateReportsExcel } from "./GenerateReportsExcel";
import { CloseCustomerOrder } from "./CloseCustomerOrder";
import { SearchAppointments } from "./SearchAppointments";
import { ExportWorkOrders } from "./ExportWorkOrders";
import { GetGuestOrganizations } from "./GetGuestOrganizations";
import { GetGuestOrganizationAppointments } from "./GetGuestOrganizationAppointments";
import { GetGuestGeneralInfo } from "./GetGuestGeneralInfo";
import { CreateGuestAppointment } from "./CreateGuestAppointment";
import { ConfirmGuestAppointment } from "./ConfirmGuestAppointment";
import { GetGuestOrganization } from "./GetGuestOrganization";
import { DeleteAccountSetting } from "./DeleteAccountSetting";

/**
 * Defines the api calls
 */
export const apiCalls = (api: AxiosInstance) => {
  return {
    logout: Logout(api),
    loginUser: LoginUser(api),
    getClient: GetClient(api),
    getProduct: GetProduct(api),
    updateUser: UpdateUser(api),
    getCarType: GetCarType(api),
    createUser: CreateUser(api),
    deleteUser: DeleteUser(api),
    searchUsers: SearchUsers(api),
    trackReport: TrackReport(api),
    getCarTypes: GetCarTypes(api),
    getWorkers: GetWorkersOld(api),
    getUserRoles: GetUserRoles(api),
    createReport: CreateReport(api),
    getWorkOrder: GetWorkOrder(api),
    createClient: CreateClient(api),
    deleteClient: DeleteClient(api),
    updateClient: UpdateClient(api),
    deleteReport: DeleteReport(api),
    createWorker: CreateWorker(api),
    updateWorker: UpdateWorker(api),
    deleteWorker: DeleteWorker(api),
    registerUser: RegisterUser(api),
    getStatistics: GetStatistics(api),
    searchClients: SearchClients(api),
    updateAddress: UpdateAddress(api),
    createProduct: CreateProduct(api),
    deleteProduct: DeleteProduct(api),
    updateProduct: UpdateProduct(api),
    updateCompany: UpdateCompany(api),
    createCarType: CreateCarType(api),
    updateCarType: UpdateCarType(api),
    deleteCarType: DeleteCarType(api),
    createContact: CreateContact(api),
    updateContact: UpdateContact(api),
    deleteContact: DeleteContact(api),
    switchAccount: SwitchAccount(api),
    searchWorkers: SearchWorkers(api),
    searchProducts: SearchProducts(api),
    suspendAccount: SuspendAccount(api),
    recoverAccount: RecoverAccount(api),
    changePassword: ChangePassword(api),
    createGuaranty: CreateGuaranty(api),
    deleteGuaranty: DeleteGuaranty(api),
    updateGuaranty: UpdateGuaranty(api),
    getPaymentTypes: GetPaymentTypes(api),
    updateWorkOrder: UpdateWorkOrder(api),
    createWorkOrder: CreateWorkOrder(api),
    deleteWorkOrder: DeleteWorkOrder(api),
    deleteTyreHotel: DeleteTyreHotel(api),
    updateTyreHotel: UpdateTyreHotel(api),
    createTyreHotel: CreateTyreHotel(api),
    deleteTimesheet: DeleteTimesheet(api),
    updateTimesheet: UpdateTimesheet(api),
    getAppointments: GetAppointments(api),
    exportTyreHotels: ExportTyreHotels(api),
    searchWorkOrders: SearchWorkOrders(api),
    getAssetManifest: GetAssetManifest(api),
    searchTyreHotels: SearchTyreHotels(api),
    searchTimesheets: SearchTimesheets(api),
    deletePaymentType: DeletePaymentType(api),
    updatePaymentType: UpdatePaymentType(api),
    updateLoyaltyCard: UpdateLoyaltyCard(api),
    createLoyaltyCard: CreateLoyaltyCard(api),
    deleteLoyaltyCard: DeleteLoyaltyCard(api),
    createPaymentType: CreatePaymentType(api),
    getDraftWorkOrder: GetDraftWorkOrder(api),
    createAppointment: CreateAppointment(api),
    deleteAppointment: DeleteAppointment(api),
    updateAppointment: UpdateAppointment(api),
    transferWorkOrder: TransferWorkOrder(api),
    getCustomersOrder: GetCustomersOrder(api),
    getAccountSettings: GetAccountSettings(api),
    getDailyWorkOrders: GetDailyWorkOrders(api),
    searchLoyaltyCards: SearchLoyaltyCards(api),
    createOrganization: CreateOrganization(api),
    deleteOrganization: DeleteOrganization(api),
    updateOrganization: UpdateOrganization(api),
    generateReportsPDF: GenerateReportsPDF(api),
    createClientCompany: CreateClientCompany(api),
    getTimesheetReports: GetTimesheetReports(api),
    searchByPlateNumber: SearchByPlateNumber(api),
    createCustomerOrder: CreateCustomerOrder(api),
    updateCustomerOrder: UpdateCustomerOrder(api),
    deleteCustomerOrder: DeleteCustomerOrder(api),
    getCustomerOrderById: GetCustomerOrderById(api),
    deleteWorkOrderDraft: DeleteWorkOrderDraft(api),
    getAppointmentGroups: GetAppointmentGroups(api),
    getUserOrganizations: GetUserOrganizations(api),
    createCompanyAddress: CreateCompanyAddress(api),
    createPriceCondition: CreatePriceCondition(api),
    updatePriceCondition: UpdatePriceCondition(api),
    deletePriceCondition: DeletePriceCondition(api),
    createWorkOrderDraft: CreateWorkOrderDraft(api),
    updateWorkOrderDraft: UpdateWorkOrderDraft(api),
    createAccountSetting: CreateAccountSetting(api),
    updateAccountSetting: UpdateAccountSetting(api),
    searchWorkOrderDrafts: SearchWorkOrderDrafts(api),
    updateWorkOrderStatus: UpdateWorkOrderStatus(api),
    createAppointmentGroup: CreateAppointmentGroup(api),
    updateAppointmentGroup: UpdateAppointmentGroup(api),
    getSuspendedWorkOrders: GetSuspendedWorkOrders(api),
    deleteWorkOrderProduct: DeleteWorkOrderProduct(api),
    deleteAppointmentGroup: DeleteAppointmentGroup(api),
    createWorkOrderProduct: CreateWorkOrderProduct(api),
    updateWorkOrderProduct: UpdateWorkOrderProduct(api),
    transformWorkOrderDraft: TransformWorkOrderDraft(api),
    requestUserRegistration: RequestUserRegistration(api),
    updateAppointementStatus: UpdateAppointmentStatus(api),
    updateWorkOrderRelations: UpdateWorkOrderRelations(api),
    validateRegistrationCode: ValidateRegistrationCode(api),
    deleteAccount: DeleteAccount(api),
    getDeleteRequests: GetDeleteRequests(api),
    reSendSmsITP: ReSendSmsITP(api),
    closeTyreHotel: CloseTyreHotel(api),
    generateReportsExcel: GenerateReportsExcel(api),
    closeCustomerOrder: CloseCustomerOrder(api),
    searchAppointments: SearchAppointments(api),
    exportWorkOrders: ExportWorkOrders(api),
    getGuestOrganizations: GetGuestOrganizations(api),
    getGuestOrganizationAppointments: GetGuestOrganizationAppointments(api),
    getGuestGeneralInfo: GetGuestGeneralInfo(api),
    createGuestAppointment: CreateGuestAppointment(api),
    confirmGuestAppointment: ConfirmGuestAppointment(api),
    getGuestOrganization: GetGuestOrganization(api),
    deleteAccountSetting: DeleteAccountSetting(api),
  };
};

/**
 * Exports types
 */
export * from "./Logout";
export * from "./LoginUser";
export * from "./GetClient";
export * from "./GetProduct";
export * from "./UpdateUser";
export * from "./GetCarType";
export * from "./CreateUser";
export * from "./DeleteUser";
export * from "./SearchUsers";
export * from "./GetCarTypes";
export * from "./TrackReport";
export * from "./GetUserRoles";
export * from "./CreateReport";
export * from "./GetWorkOrder";
export * from "./DeleteClient";
export * from "./CreateClient";
export * from "./UpdateClient";
export * from "./DeleteReport";
export * from "./CreateWorker";
export * from "./UpdateWorker";
export * from "./DeleteWorker";
export * from "./RegisterUser";
export * from "./GetStatistics";
export * from "./SearchClients";
export * from "./GetWorkersOld";
export * from "./UpdateAddress";
export * from "./CreateProduct";
export * from "./DeleteProduct";
export * from "./UpdateProduct";
export * from "./UpdateCompany";
export * from "./CreateCarType";
export * from "./UpdateCarType";
export * from "./DeleteCarType";
export * from "./CreateContact";
export * from "./UpdateContact";
export * from "./DeleteContact";
export * from "./SwitchAccount";
export * from "./SwitchAccount";
export * from "./SearchWorkers";
export * from "./SearchProducts";
export * from "./SuspendAccount";
export * from "./RecoverAccount";
export * from "./ChangePassword";
export * from "./CreateGuaranty";
export * from "./DeleteGuaranty";
export * from "./UpdateGuaranty";
export * from "./ChangePassword";
export * from "./CreateGuaranty";
export * from "./DeleteGuaranty";
export * from "./UpdateGuaranty";
export * from "./GetPaymentTypes";
export * from "./UpdateWorkOrder";
export * from "./CreateWorkOrder";
export * from "./DeleteWorkOrder";
export * from "./DeleteTyreHotel";
export * from "./UpdateTyreHotel";
export * from "./CreateTyreHotel";
export * from "./DeleteTimesheet";
export * from "./UpdateTimesheet";
export * from "./GetAppointments";
export * from "./ExportTyreHotels";
export * from "./SearchWorkOrders";
export * from "./GetAssetManifest";
export * from "./SearchTyreHotels";
export * from "./SearchTimesheets";
export * from "./DeletePaymentType";
export * from "./CreatePaymentType";
export * from "./CreateLoyaltyCard";
export * from "./UpdatePaymentType";
export * from "./DeleteLoyaltyCard";
export * from "./UpdateLoyaltyCard";
export * from "./GetDraftWorkOrder";
export * from "./CreateAppointment";
export * from "./DeleteAppointment";
export * from "./UpdateAppointment";
export * from "./TransferWorkOrder";
export * from "./GetCustomersOrder";
export * from "./GetAccountSettings";
export * from "./GetDailyWorkOrders";
export * from "./SearchLoyaltyCards";
export * from "./CreateOrganization";
export * from "./DeleteOrganization";
export * from "./UpdateOrganization";
export * from "./GenerateReportsPDF";
export * from "./CreateClientCompany";
export * from "./GetTimesheetReports";
export * from "./SearchByPlateNumber";
export * from "./CreateCustomerOrder";
export * from "./UpdateCustomerOrder";
export * from "./DeleteCustomerOrder";
export * from "./DeleteWorkOrderDraft";
export * from "./GetAppointmentGroups";
export * from "./GetUserOrganizations";
export * from "./CreateCompanyAddress";
export * from "./CreatePriceCondition";
export * from "./UpdatePriceCondition";
export * from "./DeletePriceCondition";
export * from "./CreateWorkOrderDraft";
export * from "./UpdateWorkOrderDraft";
export * from "./CreateAccountSetting";
export * from "./UpdateAccountSetting";
export * from "./GetCustomerOrderById";
export * from "./SearchWorkOrderDrafts";
export * from "./UpdateWorkOrderStatus";
export * from "./GetSuspendedWorkOrders";
export * from "./DeleteWorkOrderProduct";
export * from "./CreateWorkOrderProduct";
export * from "./CreateAppointmentGroup";
export * from "./UpdateAppointmentGroup";
export * from "./UpdateWorkOrderProduct";
export * from "./DeleteAppointmentGroup";
export * from "./TransformWorkOrderDraft";
export * from "./UpdateAppointmentStatus";
export * from "./RequestUserRegistration";
export * from "./UpdateWorkOrderRelations";
export * from "./ValidateRegistrationCode";
export * from "./DeleteAccount";
export * from "./GetDeleteRequests";
export * from "./ReSendSmsITP";
export * from "./CloseTyreHotel";
export * from "./GenerateReportsExcel";
export * from "./CloseCustomerOrder";
export * from "./SearchAppointments";
export * from "./ExportWorkOrders";
export * from "./GetGuestOrganizations";
export * from "./GetGuestOrganizationAppointments";
export * from "./GetGuestGeneralInfo";
export * from "./CreateGuestAppointment";
export * from "./ConfirmGuestAppointment";
export * from "./GetGuestOrganization";
export * from "./DeleteAccountSetting";
