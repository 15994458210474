import { isAnyOf } from "@reduxjs/toolkit";

/**
 * Imports action creators
 */
import { reportsActionCreators } from "./reports.slice";

/**
 * Imports types
 */
import { AppStartListening } from "../../store";

/**
 * Imports utils
 */
import { saveState } from "../../utils";

/**
 * Imports constants
 */
import { DETAILED_REPORTS } from "../../../constants";

const { updateReportsConfig } = reportsActionCreators;
const reportsMatcher = isAnyOf(updateReportsConfig);

export const addReportsListener = (startListening: AppStartListening) => {
  startListening({
    matcher: reportsMatcher,
    effect: (action, listenerApi) => {
      if (action.type === updateReportsConfig.type) {
        saveState(DETAILED_REPORTS, action.payload);
      }
    },
  });
};
