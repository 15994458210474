/**
 * Defines the paths
 */
export enum Paths {
  /**
   * Temporary
   */
  Playground = "/playground",

  /**
   * Public routes
   */
  Public = "/",
  Login = "/login",
  SignIn = "/sign-in",
  SignUp = "/sign-up",
  PublicAppointments = "/programari",
  PublicAppointmentsOrganization = "/programari/:slug",
  // ForgotPassword = '/forgot-password',

  /**
   * Private routes
   */
  Dashboard = "/dashboard",
  Suspended = "/dashboard/suspended",
  Appointments = "/dashboard/appointments",
  ClientOrder = "/dashboard/client-order",
  DashboardOverview = "/dashboard/overview",
  Notifications = "/dashboard/notifications",
  TyreHotels = "/dashboard/tyre-service/tyre-hotels",
  DownloadWorkOrderPDF = "/dashboard/download-work-order-pdf/:id",
  SuspendedAccount = "/dashboard/suspended-account",

  /**
   * Reports
   */
  DailyReports = "/dashboard/reports/daily",
  WasteReports = "/dashboard/reports/waste",
  MonthlyReports = "/dashboard/reports/monthly",
  WorkersReports = "/dashboard/reports/workers",
  CompanyReports = "/dashboard/reports/company",
  AdvancedReports = "/dashboard/reports/advanced",
  SuspendedWoReports = "/dashboard/reports/suspended-work-orders",
  TimesheetReports = "/dashboard/reports/timesheet",
  AppointmentsReports = "/dashboard/reports/appointments",
  SMSReports = "/dashboard/reports/sms",
  WorkOrderProductsReports = "/dashboard/reports/work-order-products",

  /**
   * Workstation settings
   */
  Clients = "/dashboard/workstation-settings/clients",
  Products = "/dashboard/workstation-settings/products",
  Services = "/dashboard/workstation-settings/services",
  Contracts = "/dashboard/workstation-settings/contracts",
  WorkOrders = "/dashboard/workstation-settings/work-orders",
  LoyaltyCards = "/dashboard/workstation-settings/loyalty-cards",
  Guaranties = "/dashboard/workstation-settings/guaranties",
  CreateWorkOrder = "/dashboard/workstation-settings/work-orders/create",
  DraftWorkOrder = "/dashboard/workstation-settings/work-orders/drafts/:draftId",
  EditWorkOrder = "/dashboard/workstation-settings/work-orders/edit/:workOrderId",
  EditWorkOrderITP = "/dashboard/workstation-settings/work-orders/edit-itp/:workOrderId",
  CreateWorkOrderITP = "/dashboard/workstation-settings/work-orders/create-itp",

  /**
   * Account settings
   */
  Users = "/dashboard/account-settings/users",
  Workers = "/dashboard/account-settings/workers",
  Timesheet = "/dashboard/account-settings/timesheet",
  CarTypes = "/dashboard/account-settings/car-types",
  Organizations = "/dashboard/account-settings/organizations",
  PaymentMethods = "/dashboard/account-settings/payment-methods",
  AppointmentGroups = "/dashboard/account-settings/appointment-groups",
  SMSMessages = "/dashboard/account-settings/sms-messages",

  /**
   * Profile
   */
  Management = "/dashboard/profile/management",
  Orders = "/dashboard/profile/orders",
  Update = "/dashboard/profile/update",
  Suspend = "/dashboard/profile/suspend",

  /**
   * Admin only
   */
  LiveTimesheet = "/dashboard/live-timesheet",
}
