/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import { SearchUsersApiCall, SearchUsersResponse } from "./SearchUsers.types";

/**
 * Handles making the api call
 */
export const SearchUsers: SearchUsersApiCall =
  (api) => async (body, onSuccess, onError) => {
    try {
      const response = await api.post<SearchUsersResponse>(
        "/users/search",
        body
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
