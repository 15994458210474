/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  DeletePriceConditionApiCall,
  DeletePriceConditionResponse
} from "./DeletePriceCondition.types";

/**
 * Handles making the api call
 */
export const DeletePriceCondition: DeletePriceConditionApiCall =
  (api) => async (conditionId, onSuccess, onError) => {
    try {
      const response = await api.delete<DeletePriceConditionResponse>(
        `/product-conditions/${conditionId}`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
