/**
 * Imports types
 */
import { ApolloInstance } from "../useApi.types";

/**
 * Imports graphQL queries
 */
import { GetClientQuery } from "./GetClientQuery";
import { GetWorkers } from "./GetWorkers";
import { GetClients } from "./GetClients";
import { GetProducts } from "./GetProducts";
import { GetReportHistory } from "./GetReportHistory";
import { GetTimeSheet } from "./GetTimeSheet";
import { CreateTimeSheet } from "./CreateTimeSheet";
import { GetGuaranties } from "./GetGuaranties";
import { GetGuaranty } from "./GetGuaranty";
import { GetWorkOrdersList } from "./GetWorkOrdersList";
import { UpdateTimesheetByAdmin } from "./UpdateTimesheetByAdmin";

/**
 * Defines the queries
 */
export const queries = (apolloClient: ApolloInstance) => {
  return {
    getClientQuery: GetClientQuery(apolloClient),
    getWorkers: GetWorkers(apolloClient),
    getClients: GetClients(apolloClient),
    getProducts: GetProducts(apolloClient),
    getReportHistory: GetReportHistory(apolloClient),
    getTimeSheet: GetTimeSheet(apolloClient),
    createTimeSheet: CreateTimeSheet(apolloClient),
    getGuaranties: GetGuaranties(apolloClient),
    getGuaranty: GetGuaranty(apolloClient),
    getWorkOrdersList: GetWorkOrdersList(apolloClient),
    updateTimesheetByAdmin: UpdateTimesheetByAdmin(apolloClient)
  };
};

/**
 * Exports types
 */
export * from "./GetWorkers";
export * from "./GetClientQuery";
export * from "./GetClients";
export * from "./GetProducts";
export * from "./GetReportHistory";
export * from "./GetTimeSheet";
export * from "./CreateTimeSheet";
export * from "./GetGuaranties";
export * from "./GetGuaranty";
export * from "./GetWorkOrdersList";
export * from "./UpdateTimesheetByAdmin";
