import { apiService } from "../../services";

import type { GetOverviewWorkOrdersResponse } from "./workOverview.types";

const appointmentsApi = apiService.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Query - Gets the daily work orders
     */
    getDailyWorkOrders: builder.query<GetOverviewWorkOrdersResponse, void>({
      query: () => ({
        url: "/service-controls/work-orders/daily",
        method: "get",
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }),
    }),
    /**
     * Query - Gets suspended work orders
     */
    getSuspendedWorkOrders: builder.query<GetOverviewWorkOrdersResponse, void>({
      query: () => ({
        url: "/service-controls/work-orders/suspended",
        method: "get",
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }),
    }),
  }),
});

export const {
  useGetDailyWorkOrdersQuery,
  useLazyGetDailyWorkOrdersQuery,
  useGetSuspendedWorkOrdersQuery,
  useLazyGetSuspendedWorkOrdersQuery,
} = appointmentsApi;
export const {
  endpoints: { getDailyWorkOrders, getSuspendedWorkOrders },
} = appointmentsApi;
