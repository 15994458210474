import { useState, useEffect } from "react";

/**
 * Defines the useDebounce hook
 */
export const useDebounce = () => {
  /**
   * Initializes the interval
   */
  const [intervalId, setIntervalId] = useState<any>();

  /**
   * Resets the timeout
   */
  useEffect(() => {
    return () => {
      if (intervalId) {
        clearTimeout(intervalId);
        setIntervalId(undefined);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (func: any, wait: number) => {
    if (intervalId) clearTimeout(intervalId);

    setIntervalId(setTimeout(func, wait));
  };
};
