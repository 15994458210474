/**
 * Imports types
 */
import { UseRulesProps, Rules, CustomRuleMessages } from "./useRules.types";

/**
 * Imports hooks
 */
import { useTranslation, useFormContext } from "..";

/**
 * Defines the default custom rule messages
 */
const defaultCustomMessages: CustomRuleMessages = {
  errors: {
    required: "",
    min: "",
    max: "",
    minLength: "",
    maxLength: "",
    hasToMatch: "",
    isEmail: "",
    isStrongPassword: ""
  }
};

/**
 * Defines the hook
 */
export function useRules<F extends UseRulesProps>(props: F) {
  const {
    required,
    min,
    max,
    minLength,
    maxLength,
    hasToMatch,
    matchLabel,
    matchError,
    validations
  } = props;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the form state
   */
  const { getValues } = useFormContext();

  /**
   * Handles adding the "required" rule
   */
  const addRequiredRule = (rules: Rules, message?: string) => {
    rules["required"] = {
      value: true,
      message: message || t("RequiredField")
    };

    rules["validate"] = (fieldValue: any) => {
      if (typeof fieldValue === "string") {
        return !!fieldValue.trim() || t("RequiredField");
      }
    };

    return rules;
  };

  /**
   * Handles adding the "min" rule
   */
  const addMinRule = (rules: Rules, message?: string) => {
    rules["min"] = {
      value: min,
      message: message || t("At least [min]", { min })
    };
    return rules;
  };

  /**
   * Handles adding the "max" rule
   */
  const addMaxRule = (rules: Rules, message?: string) => {
    rules["min"] = {
      value: max,
      message: message || t("At most [max]", { max })
    };
    return rules;
  };

  /**
   * Handles adding the "minLength" rule
   */
  const addMinLengthRule = (rules: Rules, message?: string) => {
    rules["minLength"] = {
      value: minLength,
      message: message || t("At least [minLength]", { minLength })
    };
    return rules;
  };

  /**
   * Handles adding the "maxLength" rule
   */
  const addMaxLengthRule = (rules: Rules, message?: string) => {
    rules["maxLength"] = {
      value: maxLength,
      message: message || t("At most [maxLength]", { maxLength })
    };
    return rules;
  };

  /**
   * Handles adding the "hasToMatch" rule
   */
  const addHasToMatchRule = (rules: Rules, message?: string) => {
    rules["validate"] = (fieldValue: any) => {
      const valueToMatch = getValues(hasToMatch!);

      return (
        fieldValue === valueToMatch ||
        message ||
        matchError ||
        t("[fieldValue] not equal with [hasToMatch]", {
          fieldValue,
          hasToMatch: matchLabel ? matchLabel : hasToMatch
        })
      );
    };
    return rules;
  };

  /**
   * Handles adding the "isEmail" rule
   */
  const addIsEmailRule = (rules: Rules, message?: string) => {
    const emailRegex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    rules["pattern"] = {
      value: emailRegex,
      message: message || t("InvalidEmail")
    };
    return rules;
  };

  /**
   * Handles adding the "isNumber" rule
   */
  const addIsNumberRule = (rules: Rules, message?: string) => {
    const regex = /^-?\d+(?:\.\d+)?$/;

    rules["pattern"] = {
      value: regex,
      message: message || t("InvalidNumber")
    };
    return rules;
  };

  /**
   * Handles adding the "isPlateNumber" rule
   */
  const addIsPlateNumberRule = (rules: Rules) => {
    rules["minLength"] = {
      value: 9,
      message: t("InvalidPlateNumber")
    };
    return rules;
  };

  /**
   * Handles adding the "isStrongPassword" rule
   */
  const addStrongPasswordRule = (rules: Rules) => {
    rules["validate"] = (fieldValue: any) => {
      if (!required && fieldValue.length < 1) return true;

      const passwordRegex = {
        lowerCase: /[a-z]/,
        upperCase: /[A-Z]/,
        number: /[0-9]/,
        special: /[$@$!#^%*?&]/,
        minLength: 8,
        maxLength: 64
      };

      const failedValidations: string[] = [];

      /**
       * Checks for lower case
       */
      if (!fieldValue.match(passwordRegex.lowerCase)) {
        failedValidations.push("a");
      }

      /**
       * Checks for upper case
       */
      if (!fieldValue.match(passwordRegex.upperCase)) {
        failedValidations.push("A");
      }

      /**
       * Checks for number
       */
      if (!fieldValue.match(passwordRegex.number)) {
        failedValidations.push("0-9");
      }

      /**
       * Checks for special char
       */
      if (!fieldValue.match(passwordRegex.special)) {
        failedValidations.push("#");
      }

      /**
       * Checks for min valid length
       */
      if (fieldValue.length < passwordRegex.minLength) {
        failedValidations.push(">=");
      }

      return (
        failedValidations.length === 0 || JSON.stringify(failedValidations)
      );
    };
    return rules;
  };

  /**
   * Handles creating validation rules
   */
  const createRules = (mapErrors?: CustomRuleMessages) => {
    const errors = mapErrors ? mapErrors.errors : defaultCustomMessages.errors;

    /**
     * Initializes the rules
     * @see https://react-hook-form.com/api/useform/register
     */
    const rules: Rules = {};

    /**
     * Adds the required rule
     */
    if (required) addRequiredRule(rules, errors.required);

    /**
     * Adds the minimum value to accept for this input.
     */
    if (min) addMinRule(rules, errors.min);

    /**
     * Adds the maximum value to accept for this input.
     */
    if (max) addMaxRule(rules, errors.max);

    /**
     * Adds the minimum length of the value to accept for this input.
     */
    if (minLength) addMinLengthRule(rules, errors.minLength);

    /**
     * Adds maximum length of the value to accept for this input.
     */
    if (maxLength) addMaxLengthRule(rules, errors.maxLength);

    /**
     * Adds the has to match rule
     */
    if (hasToMatch) addHasToMatchRule(rules, errors.hasToMatch);

    /**
     * Adds more complex validation rules
     */
    validations &&
      validations.forEach((validation) => {
        /**
         * Validate email
         */
        if (validation === "isEmail") {
          addIsEmailRule(rules, errors.isEmail);
        }

        /**
         * Validate strong password
         */
        if (validation === "isStrongPassword") {
          addStrongPasswordRule(rules);
        }

        /**
         * Validate number
         */
        if (validation === "isNumber") {
          addIsNumberRule(rules);
        }

        /**
         * Validate plate number
         */
        if (validation === "isPlateNumber") {
          addIsPlateNumberRule(rules);
        }
      });

    return rules;
  };

  return {
    createRules
  };
}
