/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  ConfirmGuestAppointmentApiCall,
  ConfirmGuestAppointmentResponse,
} from "./ConfirmGuestAppointment.types";

/**
 * Handles making the api call
 */
export const ConfirmGuestAppointment: ConfirmGuestAppointmentApiCall =
  (api) => async (appointmentId, body, onSuccess, onError) => {
    try {
      const response = await api.put<ConfirmGuestAppointmentResponse>(
        `/appointments/${appointmentId}/confirm`,
        fromCamelToSnake(body),
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
