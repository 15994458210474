import Echo from "laravel-echo";
import Pusher from "pusher-js";

declare global {
  interface Window {
    Pusher: typeof Pusher;
    Echo: Echo;
  }
}

window.Pusher = Pusher;

/**
 * Creates the socket connection if doesnt exists
 */
export const createSocketConnection = () => {
  if (!window.Echo) {
    const {
      REACT_APP_DEFAULT_APP_KEY,
      REACT_APP_SOCKET_HOST,
      REACT_APP_SOCKET_PORT,
      REACT_APP_SOCKET_CLUSTER,
    } = process.env;

    window.Echo = new Echo({
      authEndpoint: "/broadcasting/auth",
      broadcaster: "pusher",
      key: REACT_APP_DEFAULT_APP_KEY,
      wsHost: REACT_APP_SOCKET_HOST,
      wsPort: REACT_APP_SOCKET_PORT,
      wssPort: REACT_APP_SOCKET_PORT,
      forceTLS: false,
      encrypted: false,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      auth: {
        headers: {},
      },
      cluster: REACT_APP_SOCKET_CLUSTER,
    });
  }
};
