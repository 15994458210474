import React, {useMemo} from "react";

/**
 * Imports the graphQL client
 */
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as InternalApolloProvider,
} from "@apollo/client";
import { useSelector } from "../useSelector";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Temporary Apollo provider
 *
 */
export const ApolloProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the auth state
   */
  const { accessToken } = useSelector((state) => state.auth);
  /**
   * Defines the apollo graphQL client
   */
  const apollo = useMemo(() => {
    return new ApolloClient({
      uri: window.origin + "/graphql",
      cache: new InMemoryCache(),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }, [accessToken]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    apollo,
  };

  return (
    <Provider value={providerValue}>
      <InternalApolloProvider client={apollo}>
        {children}
      </InternalApolloProvider>
    </Provider>
  );
};
