/**
 * External imports
 */
import { uniqBy } from "lodash";

/**
 * Imports types
 */
import {
  // HandleSocketEventsProps,
  HandleSocketEventsProps,
} from "./useSocketUtils.types";

/**
 * Imports hooks
 */
// import { useSocket } from "..";

/**
 * Provides helper functions to update state after socket events
 */
export const useSocketUtils = () => {
  /**
   * Gets the socket state
   */
  // const { setEvents } = useSocket();

  /**
   * Checks if the resource exists
   */
  // const checkIfResourceExists = (resources: any[], id: number) => {
  //   return resources.find((item) => item.id === id) ? true : false;
  // };

  /**
   * Handles formatting the state
   */
  const formatState = (state: any[], forceUniqueById?: boolean) => {
    // console.log("state:", state);
    if (!forceUniqueById) return state;
    return uniqBy(state, "id");
  };

  const handleSocketEvents = (props: HandleSocketEventsProps) => {
    const { payload, forceUniqueById, modelName, setState, format } = props;

    if (payload.modelName === modelName) {
      const { event, model } = payload;

      if (event === "CREATED") {
        setState((prevState: any[]) =>
          formatState(
            [...prevState, format ? format({ ...model }) : { ...model }],
            forceUniqueById,
          ),
        );
      }

      if (event === "UPDATED") {
        setState((prevState: any[]) =>
          prevState.map((item) => {
            if (item.id === model.id)
              return format ? format({ ...model }) : { ...model };

            return { ...item };
          }),
        );
      }

      if (event === "DELETED") {
        setState((prevState: any[]) =>
          prevState.filter((item) => item.id !== model.id),
        );
      }
    }
  };

  /**
   * Handles updating the state based on incoming socket events
   */
  // const handleSocketEvents = (props: HandleSocketEventsProps) => {
  //   const { events, type, format, modelName, state, setState } = props;

  //   /**
  //    * Filters out the events that do not match the provided resource
  //    */
  //   const resourceEvents = events.filter((event) => event.type.includes(type));

  //   if (resourceEvents.length > 0) {
  //     resourceEvents.forEach((event) => {
  //       if (event.data.modelName === modelName) {
  //         /**
  //          * Checks if the resource exists
  //          */
  //         const exists = checkIfResourceExists(state, event.data.model.id);

  //         /**
  //          * Handles CREATE events
  //          */
  //         if (event.data.event === "CREATED" && !exists) {
  //           setState((prevState: any[]) => [
  //             ...prevState,
  //             format ? format({ ...event.data.model }) : { ...event.data.model }
  //           ]);
  //         }

  //         /**
  //          * Handles UPDATE events
  //          */
  //         if (event.data.event === "UPDATED" && exists) {
  //           setState((prevState: any[]) =>
  //             prevState.map((item) => {
  //               if (item.id === event.data.model.id)
  //                 return format
  //                   ? format({ ...event.data.model })
  //                   : { ...event.data.model };

  //               return { ...item };
  //             })
  //           );
  //         }

  //         /**
  //          * Handles DELETE events
  //          */
  //         if (event.data.event === "DELETED" && exists) {
  //           setState((prevState: any[]) =>
  //             prevState.filter((item) => item.id !== event.data.model.id)
  //           );
  //         }

  //         setEvents((prevEvents) =>
  //           prevEvents.filter((event) => !event.type.includes(type))
  //         );
  //       }
  //     });
  //   }
  // };

  return {
    handleSocketEvents,
    // handleSocketEvents2
  };
};
