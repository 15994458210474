import { createSlice } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { ReduxSlice } from "../../features";
import { PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "./auth.types";
import { Auth } from "../../../types";
import { AppThunk } from "../../../redux";

/**
 * Imports utils
 */
import { preloadAuth } from "./auth.utils";
import { logoutUser } from "./auth.api";

/**
 * Defines the auth initial state
 */
const initialState: AuthState = {
  ...preloadAuth(),
  loading: false,
  authenticated: false,
};

/**
 * Handles creating the a redux state slice
 *
 * - This will automatically generate action-creators and action-types that correspond
 * to the reducers and state.
 * - The internal usage of Immer allows for writing 'mutating' update logic that becomes
 * correct immutable updates.
 */
export const authSlice = createSlice({
  name: ReduxSlice.Auth,
  initialState,
  reducers: {
    updateAuth: (state, action: PayloadAction<Auth>) => {
      state.accessToken = action.payload.accessToken;
      state.expiresIn = action.payload.expiresIn;
      state.tokenType = action.payload.tokenType;
      state.user = action.payload.user;
    },
    authenticate: (state) => {
      state.authenticated = true;
    },
    logout: (state) => {
      state.authenticated = false;
      state.accessToken = "";
      state.expiresIn = 0;
      state.tokenType = "";
      state.user = undefined;
    },
  },
});

/**
 * Checks if the user is authenticated
 */
export const checkIfAuthenticated = (): AppThunk => (dispatch, getState) => {
  const auth = getState().auth;
  const { accessToken, expiresIn, user } = auth;
  const time = new Date();
  const expiresTime = new Date();
  expiresTime.setMilliseconds(expiresIn);
  const isTokenExpired = expiresTime < time;

  if (accessToken.length > 0 && isTokenExpired) {
    return dispatch(logoutUser.initiate());
  }

  if (!user || expiresIn === 0) {
    return;
  }

  dispatch(authSlice.actions.authenticate());
};

/**
 * Exports the action-creators
 */
export const authActionCreators = authSlice.actions;

/**
 * Exports the reducer
 */
export const authReducer = authSlice.reducer;
