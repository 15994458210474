import { isAnyOf } from "@reduxjs/toolkit";

import { saveState } from "../../utils";
import { appointmentsActionCreators } from "./appointments.slice";

import type { AppStartListening } from "../../store";

import {
  APPOINTMENTS_VIEW_KEY,
  APPOINTMENTS_RESOURCES_KEY,
  APPOINTMENTS_ACTIVE_ORG_KEY,
  APPOINTMENTS_ACTIVE_GROUPS_KEY,
  APPOINTMENTS_WITH_GROUPING_KEY,
  APPOINTMENTS_FILTER_MODELS_KEY,
  APPOINTMENTS_INCLUDE_GROUPLESS_KEY,
} from "../../../constants";

const {
  updateAppointmentsView,
  updateAppointmentsResources,
  updateAppointmentsWithGrouping,
  updateAppointmentsActiveGroups,
  updateAppointmentsFilterModels,
  updateAppointmentsActiveOrganization,
  updateAppointmentsToIncludeGroupless,
} = appointmentsActionCreators;
const appointmentsMatcher = isAnyOf(
  updateAppointmentsView,
  updateAppointmentsResources,
  updateAppointmentsWithGrouping,
  updateAppointmentsActiveGroups,
  updateAppointmentsFilterModels,
  updateAppointmentsActiveOrganization,
  updateAppointmentsToIncludeGroupless,
);

export const addAppointmentsListener = (startListening: AppStartListening) => {
  startListening({
    matcher: appointmentsMatcher,
    effect: (action, listenerApi) => {
      if (action.type === updateAppointmentsFilterModels.type) {
        saveState(APPOINTMENTS_FILTER_MODELS_KEY, action.payload);
      }

      if (action.type === updateAppointmentsView.type) {
        saveState(APPOINTMENTS_VIEW_KEY, action.payload);
      }

      if (action.type === updateAppointmentsActiveOrganization.type) {
        saveState(APPOINTMENTS_ACTIVE_ORG_KEY, action.payload);
      }

      if (action.type === updateAppointmentsResources.type) {
        saveState(APPOINTMENTS_RESOURCES_KEY, action.payload);
      }

      if (action.type === updateAppointmentsWithGrouping.type) {
        saveState(APPOINTMENTS_WITH_GROUPING_KEY, action.payload);
      }

      if (action.type === updateAppointmentsActiveGroups.type) {
        saveState(APPOINTMENTS_ACTIVE_GROUPS_KEY, action.payload);
      }

      if (action.type === updateAppointmentsToIncludeGroupless.type) {
        saveState(APPOINTMENTS_INCLUDE_GROUPLESS_KEY, action.payload);
      }
    },
  });
};
