import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import {
  FilterModel,
  SortOrder,
  SearchDefaults,
  InputSelectOption
} from "../../types";

/**
 * Defines the Provider props interface
 */
export interface ProviderProps {
  defaults?: SearchDefaults;
}

/**
 * Defines the Provider values interface
 */
export interface ProviderValues {
  defaultFilters: FilterModel[];
  activeFilters: FilterModel[];
  order: SortOrder;
  orderBy: string | number;
  orderByOptions: InputSelectOption[];
  pageCount: number;
  pageSize: number;
  setOrderByOptions: Dispatch<SetStateAction<InputSelectOption[]>>;
  setDefaultFilters: Dispatch<SetStateAction<FilterModel[]>>;
  setActiveFilters: Dispatch<SetStateAction<FilterModel[]>>;
  setOrder: Dispatch<SetStateAction<SortOrder>>;
  setOrderBy: Dispatch<SetStateAction<string | number>>;
  setPageCount: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  defaultFilters: [],
  activeFilters: [],
  order: "desc",
  orderBy: "created_at",
  orderByOptions: [],
  pageCount: 1,
  pageSize: 15,
  setOrderByOptions: () => {},
  setDefaultFilters: () => {},
  setActiveFilters: () => {},
  setOrder: () => {},
  setOrderBy: () => {},
  setPageCount: () => {},
  setPageSize: () => {}
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
