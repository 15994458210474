import { useMemo } from "react";

/**
 * Imports redux utils
 */
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { AppDispatch } from "../../redux";

/**
 * Imports action-creators
 */
import { actionCreators } from "../../redux";

/**
 * Defines the action dispatcher hook
 */
export const useActions = () => {
  /**
   * Gets the Redux dispatcher
   */
  const dispatch: AppDispatch = useDispatch();

  /**
   * Handles wrapping each action-creator with the Redux dispatcher
   * - This way an action can be invoked directly instead of dispatch(someAction())
   */
  return useMemo(() => {
    return bindActionCreators(actionCreators, dispatch);
  }, [dispatch]);
};
