/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetGuestOrganizationApiCall,
  GetGuestOrganizationResponse,
} from "./GetGuestOrganization.types";

/**
 * Handles making the api call
 */
export const GetGuestOrganization: GetGuestOrganizationApiCall =
  (api) => async (slug, onSuccess, onError) => {
    try {
      const response = await api.get<GetGuestOrganizationResponse>(
        `/guest/organizations/${slug}`,
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
