import { useEffect, useState } from "react";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports hooks
 */
import {
  useApi,
  useActions,
  useNavigation,
  useTranslation,
  useRegisterUtils,
} from "../../hooks";

/**
 * Imports API types
 */
import {
  RequestOnError,
  RegisterUserOnSuccess,
  RequestUserRegistrationOnSuccess,
  ValidateRegistrationCodeOnSuccess,
} from "../../hooks";

/**
 * Imports types
 */
import {
  RequestUserRegistrationBody,
  ValidateRegistrationCodeBody,
} from "../../hooks";
import { UserInfoData, CompanyInfoData } from "./useRegister.types";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const RegisterProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the register utils
   */
  const { buildRequestBody, getApiErrorMessage } = useRegisterUtils();

  /**
   * Gets the paths
   */
  const { Paths, goTo } = useNavigation();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the active step state
   */
  const [activeStep, setActiveStep] = useState(0);

  /**
   * Initializes the number of steps state
   */
  const [steps, setSteps] = useState<string[]>([]);

  /**
   * Initializes the request sent state
   */
  const [requestSent, setRequestSent] = useState(false);

  /**
   * Initializes the request validated state
   */
  const [requestValidated, setRequestValidated] = useState(false);

  /**
   * Initializes the validation data state
   */
  const [validationData, setValidationData] =
    useState<ValidateRegistrationCodeBody>();

  /**
   * Initializes the user info data state
   */
  const [userInfoData, setUserInfoData] = useState<UserInfoData>();

  /**
   * Handles the error of api calls
   */
  const onRequestError: RequestOnError = (error) => {
    /**
     * Gets the error message
     */
    const message = getApiErrorMessage(error);

    /**
     * Dispatches the error message
     */
    dispatchMessage({
      message,
      severity: "error",
      autoClose: 10000,
    });

    /**
     * Updates the loading state
     */
    setLoading(false);
  };

  /**
   * Handles requesting a new registration for a user
   */
  const requestRegistration = async (body: RequestUserRegistrationBody) => {
    /**
     * Updates the loading state
     */
    setLoading(true);

    /**
     * Defines the success of the api call
     */
    const onSuccess: RequestUserRegistrationOnSuccess = () => {
      /**
       * Updates the state
       */
      setRequestSent(true);
      setLoading(false);
    };

    await apiCalls.requestUserRegistration(body, onSuccess, onRequestError);
  };

  /**
   * Handles validating the user registration code
   */
  const validateRegistration = async (body: ValidateRegistrationCodeBody) => {
    /**
     * Updates the loading state
     */
    setLoading(true);

    /**
     * Updates the user data
     */
    setValidationData(body);

    /**
     * Defines the success of the api call
     */
    const onSuccess: ValidateRegistrationCodeOnSuccess = () => {
      /**
       * Updates the state
       */
      setRequestValidated(true);
      setLoading(false);
      setActiveStep(1);
    };

    await apiCalls.validateRegistrationCode(body, onSuccess, onRequestError);
  };

  /**
   * Handles registering a new user
   */
  const registerUser = async (data: CompanyInfoData) => {
    if (validationData && userInfoData) {
      /**
       * Updates the loading state
       */
      setLoading(true);

      /**
       * Builds the request body
       */
      const body = buildRequestBody(validationData, userInfoData, data);

      /**
       * Defines the success of the api call
       */
      const onSuccess: RegisterUserOnSuccess = () => {
        /**
         * Updates the state
         */
        setLoading(false);

        /**
         * Dispatches success message
         */
        dispatchMessage({
          message: t("SuccessfullyRegistered"),
          severity: "success",
          autoClose: 10000,
        });

        /**
         * Reroutes to sign in page
         */
        goTo(Paths.SignIn);
      };

      await apiCalls.registerUser(body, onSuccess, onRequestError);
    }
  };

  /**
   * Sets the steps
   */
  useEffect(() => {
    const steps = ["Validation", "UserInfo", "AddData"];

    setSteps(steps);
  }, []);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    steps,
    loading,
    activeStep,
    requestSent,
    requestValidated,
    setActiveStep,
    setUserInfoData,
    registerUser,
    requestRegistration,
    validateRegistration,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
