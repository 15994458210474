/**
 * External imports
 */
import lodash, { toSafeInteger, upperFirst, isObject } from "lodash";
import {
  format as _formatDate,
  formatDistance,
  differenceInHours,
  differenceInSeconds,
  differenceInMinutes,
  subMinutes,
  subSeconds,
} from "date-fns";
import { ro, hu } from "date-fns/locale";

/**
 * Imports hooks
 */
import { useTranslation, useTimerUtils, useLanguage } from "../index";

/**
 * Imports constants
 */
import {
  CURRENCY,
  UNIT_OF_MEASURE,
  DEFAULT_DATE_FORMAT,
} from "../../constants";

/**
 * Imports types
 */
import { HashMap } from "../../types";

/**
 * Provides utility functions
 */
export const useUtils = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the language state
   */
  const { activeLanguage } = useLanguage();

  /**
   * Gets timer utils
   */
  const { getTimeDifference, formatTimeUnit } = useTimerUtils();

  /**
   * Converts from camel case to snake case
   */
  const camelStringToSnake = (str: string) => {
    return lodash.snakeCase(str);
  };

  /**
   * Converts from  snake case to camel case
   */
  const snakeStringToCamel = (str: string) => {
    return lodash.camelCase(str);
  };

  /**
   * Convers from snake to camel case
   */
  const fromSnakeToCamel: (data: any) => any = (data) => {
    if (lodash.isArray(data)) {
      return lodash.map(data, fromSnakeToCamel);
    }

    if (lodash.isObject(data)) {
      return lodash(data)
        .mapKeys((v: any, k: any) => lodash.camelCase(k))
        .mapValues((v: any, k: any) => fromSnakeToCamel(v))
        .value();
    }

    return data;
  };

  /**
   * Convers from camel case to snake case
   */
  const fromCamelToSnake: (data: any) => any = (data) => {
    if (lodash.isArray(data)) {
      return lodash.map(data, fromCamelToSnake);
    }

    if (lodash.isObject(data)) {
      return lodash(data)
        .mapKeys((v: any, k: any) => lodash.snakeCase(k))
        .mapValues((v: any, k: any) => fromCamelToSnake(v))
        .value();
    }

    return data;
  };

  /**
   * Returns the date string separator
   */
  const getDateSeparator = (date: string) => {
    if (date && date.includes("-")) return "-";
    return "/";
  };

  /**
   * Returns the index of the date parts
   */
  const getDateIndexes = (separator: string) => {
    return {
      dayIndex: separator === "-" ? 2 : 0,
      monthIndex: 1,
      yearIndex: separator === "-" ? 0 : 2,
    };
  };

  /**
   * Handles generating a card number
   */
  const generateCardNumber: (digits: number) => any = (digits) => {
    let add = 1;
    let max = 12 - add;

    if (digits > max) {
      return generateCardNumber(max) + generateCardNumber(digits - max);
    }

    max = Math.pow(10, digits + add);
    let min = max / 10;
    let number = Math.floor(Math.random() * (max - min + 1) + min);

    return ("" + number).substring(add);
  };

  /**
   * Handles formatting a card number
   */
  const formatCardNumber = (number: string | number) => {
    const cardNumber = number.toString();
    const result = cardNumber.match(/.{1,4}/g);

    return result?.join("-") || cardNumber;
  };

  /**
   * Handles normalizing the provided date-like string
   */
  const normalizeDate = (date: string) => {
    /**
     * Gets the date string separator - "/" or "-"
     * Eg: 12/22/2020 or 12-22-2020
     */
    const separator = getDateSeparator(date);

    /**
     * Splits the date into day / month / year
     */
    const dateParts = date.split(separator);

    /**
     * Determines the index for each part based on separator
     */
    const { dayIndex, monthIndex, yearIndex } = getDateIndexes(separator);

    /**
     * Defines the date parts that can be re-arranged in the proper order
     */
    const day = dateParts[dayIndex];
    const month = dateParts[monthIndex];
    const year = dateParts[yearIndex];

    return new Date(`${month}/${day}/${year}`);
  };

  /**
   * Handles formatting a date
   */
  const dateToString = (date: string) => {
    return _formatDate(normalizeDate(date), "d MMM yyyy");
  };

  /**
   * Handles formatting a date range
   */
  const dateRangeToString = (range: string[]) => {
    if (range.length < 1) return "";

    /**
     * Defines the start date
     */
    const startDate = _formatDate(normalizeDate(range[0]), "d MMM yyyy");

    /**
     * Defines the end date
     */
    const endDate = _formatDate(normalizeDate(range[1]), "d MMM yyyy");

    return `${startDate} → ${endDate}`;
  };

  /**
   * Checks if the provided date is valid
   */
  const isValidDate = (date: string | Date) => {
    const validDate = new Date(date);

    return (
      validDate &&
      validDate.getTime &&
      validDate instanceof Date &&
      !isNaN(validDate.getTime())
    );
  };

  /**
   * Returns the locale based on active language
   */
  const getDateLocale = () => {
    if (activeLanguage === "en_en") return undefined;
    if (activeLanguage === "en_hu") return hu;
    return ro;
  };

  /**
   * Handles formatting the date
   */
  const formatDate = (date: string | Date, format?: string) => {
    if (isValidDate(date)) {
      return _formatDate(new Date(date), format || DEFAULT_DATE_FORMAT, {
        locale: getDateLocale(),
      });
    }

    return "";
  };

  /**
   * @see https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
   */
  const addCommaSeparator = (value: string) => {
    // Remove iOs issue ?! Test - @TODO
    // return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return value.toLocaleString();
  };

  /**
   * Adds a currency suffix to the provided value
   */
  const toCurrency = (value: any, currency?: string) => {
    if (typeof value === "string") {
      const baseValue = parseFloat(value).toFixed(2);
      const finalValue = addCommaSeparator(baseValue);
      return `${finalValue} ${currency || CURRENCY}`;
    } else if (value) {
      const baseValue = value.toFixed(2);
      const finalValue = addCommaSeparator(baseValue);
      return `${finalValue} ${currency || CURRENCY}`;
    }

    return `0 ${currency || CURRENCY}`;
  };

  /**
   * Wraps a string with a custom quantity suffix string
   */
  const toQuantity = (value: number, unit?: string) => {
    const baseValue = value.toString();
    const finalValue = addCommaSeparator(baseValue);

    return `${finalValue} ${unit || UNIT_OF_MEASURE}`;
  };

  /**
   * Wraps a string with a % suffix string
   */
  const toPercentage = (value: number | string) => {
    if (typeof value === "string") {
      return `${value}%`;
    }

    const baseValue = Math.abs(value).toString();

    return `${baseValue}%`;
  };

  /**
   * Converts the value to integer
   */
  const toInteger = (value: any) => {
    if (!value) return 0;

    return toSafeInteger(value);
  };

  /**
   * Converts the value to float
   */
  const toFloat = (value: any) => {
    if (!value) return 0;

    if (typeof value === "string") {
      return parseFloat(value);
    }

    return parseFloat(value.toFixed(2));
  };

  /**
   * Converts array to hash map
   */
  const toHashMap = (array: any[], indexKey: string) => {
    /**
     * Initializes the map
     */
    const map: HashMap<any> = {};

    array.forEach((item) => (map[item[indexKey]] = item));

    return map;
  };

  /**
   * Returns the difference in percentage between the provided values
   */
  const percentDiff = (first: any, second: any) => {
    const a = toFloat(first);
    const b = toFloat(second);
    const result = toFloat((100 * (b / a)).toFixed(0));

    return toPercentage(result > 100 ? 100 : result);
  };

  /**
   * Returns the sort order options
   */
  const getSortOrderOptions = [
    { label: t("Ascendent"), value: "asc" },
    { label: t("Descendent"), value: "desc" },
  ];

  /**
   * Truncates the provided string
   */
  const truncateString = (str: string, length: number) => {
    return lodash.truncate(str, {
      length,
      separator: /,? +/,
    });
  };

  /**
   * Handles chuncking an array
   */
  const chunckArray = (array: any[], chunkSize: number) => {
    return array.reduce((resultArray, item, index) => {
      /**
       * Defines the chunk index
       */
      const chunkIndex = Math.floor(index / chunkSize);

      /**
       * Starts a new chunk
       */
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []) as any[][];
  };

  /**
   * Handles formatting the payment type name
   */
  const formatPaymentTypeName = (value: string) => {
    if (!value) return "";

    const words = value.split(" ");
    if (words.length === 1 && words[0].length > 2) {
      return lodash.upperFirst(lodash.toLower(words[0]));
    }

    if (words.length > 1) {
      let finalWord = "";

      words.forEach((word, index) => {
        finalWord += lodash.upperFirst(lodash.toLower(word));
        if (index !== words.length - 1) {
          finalWord += " ";
        }
      });

      return finalWord;
    }

    return value;
  };

  /**
   * Handles removing any empty values from the provided data
   */
  const removeEmpty: (data: any) => any = (data) => {
    for (var propName in data) {
      if (
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName] === ""
      ) {
        delete data[propName];
      } else if (typeof data[propName] === "object") {
        removeEmpty(data[propName]);
      }
    }
    return data;
  };

  /**
   * Checks if item is the last in the provided list
   */
  const isLastItemInList = (itemIndex: number, list: any[]) => {
    return itemIndex === list.length - 1;
  };

  /**
   * Returns the distance between the provided date and now
   */
  const getTimePassed = (date: Date) => {
    return formatDistance(new Date(date), new Date(), {
      locale: getDateLocale(),
      addSuffix: true,
    });
  };

  /**
   * Returns the duration between dates
   */
  const getDurationBetweenDates = (start: Date, end: Date) => {
    const result = getTimeDifference(start, end);
    const { days, hours, minutes, seconds } = result;

    const d = days && days >= 1 ? days + t("DayShorten") : "";
    const h = formatTimeUnit(hours);
    const m = formatTimeUnit(minutes);
    const s = formatTimeUnit(seconds);

    return `${d} ${h}:${m}:${s}`;
  };

  /**
   * Handles adding an index property on the provided object
   */
  const applyIndex: (obj: any, index?: number) => any = (obj, index) => {
    if (index === undefined || index < 0) return obj;

    return { ...obj, index };
  };

  /**
   * Handles offsetting the timezone
   */
  const offsetTime = (dateStr: string) => {
    const timezoneOffsetDate = new Date();
    const offset = Math.abs(timezoneOffsetDate.getTimezoneOffset());

    return subMinutes(new Date(dateStr), offset);
  };

  /**
   * Returns the date range for the current week
   */
  const getWorkWeekDateRange = (today?: Date) => {
    const date = today || new Date();
    const startOfWeek = date.getDate() - date.getDay() + 1;
    const endOfWeek = startOfWeek + 5;
    const startDate = new Date(date.setDate(startOfWeek));
    const endDate = new Date(date.setDate(endOfWeek));

    return { startDate, endDate };
  };

  /**
   * Handles formatting the appointment print date
   */
  const formatAppointmentPrintDate = (
    startDate: Date | string,
    endDate?: Date | string,
  ) => {
    const day = formatDate(new Date(startDate), "d");
    const rest = formatDate(new Date(startDate), "MMMM Y");

    if (endDate) {
      const endDay = formatDate(new Date(endDate), "d");
      const endRest = formatDate(new Date(endDate), "MMMM Y");

      return `${day} - ${endDay} ${upperFirst(endRest)}`;
    }

    return `${day} ${upperFirst(rest)}`;
  };

  /**
   * Handles formatting the time interval (HH:mm - HH:mm)
   */
  const formatTimeInterval = (startDateStr: string, endDateStr: string) => {
    const start = formatDate(new Date(offsetTime(startDateStr)), "HH:mm");
    const end = formatDate(new Date(offsetTime(endDateStr)), "HH:mm");

    return `${start} - ${end}`;
  };

  /**
   * Handles formatting numbers with comma separator
   */
  const formatNumberSeparator = (value: string) => {
    if (!value) return "";

    let _value = value;

    if (typeof value === "number") {
      _value = (value as number).toString();
    }

    const numericValue = _value.replace(/\D/g, "");

    if (numericValue) {
      return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",").trim();
    }

    return "";
  };

  /**
   * Handles removing the comma separator from the provided value
   */
  const removeNumberSeparator = (value: string) => {
    if (!value) return "";
    return value.replace(/,/g, "");
  };

  return {
    toFloat,
    isObject,
    toInteger,
    toHashMap,
    applyIndex,
    formatDate,
    toCurrency,
    toQuantity,
    subMinutes,
    subSeconds,
    upperFirst,
    offsetTime,
    isValidDate,
    chunckArray,
    percentDiff,
    removeEmpty,
    dateToString,
    toPercentage,
    normalizeDate,
    getTimePassed,
    truncateString,
    fromSnakeToCamel,
    fromCamelToSnake,
    isLastItemInList,
    formatCardNumber,
    dateRangeToString,
    addCommaSeparator,
    differenceInHours,
    formatTimeInterval,
    camelStringToSnake,
    snakeStringToCamel,
    generateCardNumber,
    getSortOrderOptions,
    differenceInMinutes,
    differenceInSeconds,
    getWorkWeekDateRange,
    formatPaymentTypeName,
    getDurationBetweenDates,
    formatAppointmentPrintDate,
    formatNumberSeparator,
    removeNumberSeparator,
    getDateLocale,
  };
};
