/**
 * Imports components
 */
import { ThemeProvider, ApolloProvider, LanguageProvider } from "../../hooks";
import { DeviceController } from "../DeviceController";

export const Providers: React.FC = (props) => {
  const { children } = props;

  return (
    <LanguageProvider>
      <ThemeProvider>
        <ApolloProvider>
          <DeviceController>{children}</DeviceController>
        </ApolloProvider>
      </ThemeProvider>
    </LanguageProvider>
  );
};
