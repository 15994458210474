import { useCallback, useState, useRef, useLayoutEffect } from "react";

/**
 * Imports types
 */
import { Dispatch, SetStateAction } from "react";
import {
  LocalStorageParserOptions,
  LocalStorageHookReturn
} from "./useLocalStorage.types";

/**
 * Checks if the window exists
 */
const isBrowser = typeof window !== "undefined";

/**
 * Defines a default state setter
 */
const noop = () => {};

/**
 * Defines the main hook
 */
export const useLocalStorage = <T>(
  key: string,
  initialValue?: T,
  options?: LocalStorageParserOptions<T>
): LocalStorageHookReturn<T> => {
  /**
   * Defines the deserializer (custom or JSON.parse)
   */
  const deserializer = options
    ? options.raw
      ? (value: any) => value
      : options.deserializer
    : JSON.parse;

  /**
   * Handles initializing the parsing of the key
   */
  const initializer = useRef((key: string) => {
    try {
      /**
       * Defines the serializer (custom | string | JSON.stringify)
       */
      const serializer = options
        ? options.raw
          ? String
          : options.serializer
        : JSON.stringify;

      /**
       * Stores the item from local storage
       */
      const localStorageValue = localStorage.getItem(key);

      /**
       * Attempt to deserialize if the value exists
       */
      if (localStorageValue !== null) {
        return deserializer(localStorageValue);
      } else {
        /**
         * Serialize if there is an init value
         */
        initialValue && localStorage.setItem(key, serializer(initialValue));
        return initialValue;
      }
    } catch {
      /**
       * If user is in private mode or has storage restriction
       * localStorage can throw. JSON.parse and JSON.stringify
       * can throw, too.
       */
      return initialValue;
    }
  });

  /**
   * Initializes the internal state
   */
  const [state, setState] = useState<T | undefined>(() =>
    initializer.current(key)
  );

  /**
   * Initializes the state once the layout has mounted.
   * This runs after useEffect
   */
  useLayoutEffect(() => setState(initializer.current(key)), [key]);

  /**
   * Defines the state setter
   */
  const set: Dispatch<SetStateAction<T | undefined>> = useCallback(
    (valOrFunc) => {
      try {
        /**
         * Gets the new state
         */
        const newState =
          typeof valOrFunc === "function"
            ? (valOrFunc as Function)(state)
            : valOrFunc;

        /**
         * Handles edgecase
         */
        if (typeof newState === "undefined") return;

        /**
         * Initializes the value
         */
        let value: string;

        /**
         * Serializes the value
         */
        if (options)
          if (options.raw)
            if (typeof newState === "string") value = newState;
            else value = JSON.stringify(newState);
          else if (options.serializer) value = options.serializer(newState);
          else value = JSON.stringify(newState);
        else value = JSON.stringify(newState);

        /**
         * Updates the item in local storage
         */
        localStorage.setItem(key, value);

        /**
         * Sets the internal state
         */
        setState(deserializer(value));
      } catch {}
    },
    // eslint-disable-next-line
    [key, setState]
  );

  /**
   * Handles removing the item from local storage
   */
  const remove = () => {
    try {
      localStorage.removeItem(key);
      setState(initialValue);
    } catch {}
  };

  /**
   * If not in a browser send back the initial value, and no state setter.
   */
  if (!isBrowser) return [initialValue as T, noop, noop];

  /**
   * Handles edgecase when the key is falsy
   */
  if (!key) throw new Error("useLocalStorage key may not be falsy");

  return [state, set, remove];
};
