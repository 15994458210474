import { isAnyOf } from "@reduxjs/toolkit";

/**
 * Imports action creators
 */
import { workOrderActionCreators } from "./workOrder.slice";

/**
 * Imports types
 */
import { AppStartListening } from "../../store";

/**
 * Imports utils
 */
import { saveState } from "../../utils";

/**
 * Imports constants
 */
import {
  // WORKOORDER_DRAFT_KEY,
  DEFAULT_WORKORDER_TYPE_KEY,
} from "../../../constants";

const { updateDefaultWorkOrderType } = workOrderActionCreators;

const workOrderMatcher = isAnyOf(updateDefaultWorkOrderType);

export const addWorkOrderListener = (startListening: AppStartListening) => {
  startListening({
    matcher: workOrderMatcher,
    effect: (action, listenerApi) => {
      if (action.type === updateDefaultWorkOrderType.type) {
        const defaultWorkOrderType = action.payload;

        saveState(DEFAULT_WORKORDER_TYPE_KEY, defaultWorkOrderType);
      }

      // if (action.type === updateWorkOrderDraft.type) {
      //   if (action.payload === undefined) {
      //     removeState(WORKOORDER_DRAFT_KEY);
      //   } else {
      //     saveState(WORKOORDER_DRAFT_KEY, action.payload);
      //   }
      // }
    },
  });
};
