/**
 * Imports auth utils
 */
import { loadState } from "../../utils";

/**
 * Imports types
 */
import {
  Account,
  AppointmentGroup,
  AvatarOption,
  CarModel,
  User,
} from "../../../types";

/**
 * Imports theme
 */
import { palette } from "../../../themes/Javasea/palette";

/**
 * Imports constants
 */
import {
  LOCKED_KEY,
  ACCOUNT_DELETED_KEY,
  AVATAR_CONFIG_KEY,
} from "../../../constants";

export const preloadDeletedState = () => {
  const storageState = loadState(ACCOUNT_DELETED_KEY) as boolean;

  if (storageState) return storageState;
  return false;
};

export const preloadLockedState = () => {
  const storageState = loadState(LOCKED_KEY) as boolean;

  if (storageState) return storageState;
  return false;
};

export const preloadAvatarConfigState = () => {
  const storageState = loadState(AVATAR_CONFIG_KEY) as AvatarOption;

  if (storageState) return storageState;
  return { color: palette.scheduled[100], hover: palette.scheduled[60] };
};

/**
 * Handles building the user account
 */
export const buildUserAccountData = (user: User, account: Account) => {
  const { users, organizations } = account;

  /**
   * Gets the user data from the account users
   */
  const foundUser = users.find((_user) => _user.username === user.username);

  /**
   * Gets the user's organization
   */
  const organization = organizations.find(
    //@ts-ignore
    (_organization) => _organization.id === user.organizationId,
  );

  return {
    id: account.id,
    name: account.name,
    uuid: account.uuid,
    status: account.status,
    username: user.username,
    activeTo: account.activeTo,
    createdAt: account.createdAt,
    activeFrom: account.activeFrom,
    description: account.description,
    email: foundUser ? foundUser.email : "",
    lastName: foundUser ? foundUser.lastName : "",
    firstName: foundUser ? foundUser.firstName : "",
    organization: organization,
    organizations: organizations,
    avatarConfig: foundUser ? foundUser.avatarConfig : undefined,
    lastTimeSheet: foundUser ? foundUser.lastTimeSheet : undefined,
  };
};

/**
 * Handles getting the appointment groups
 */
export const getAppointmentGroups = (account: Account) => {
  const { organizations } = account;

  /**
   * Initializes the appt groups empty array
   */
  const appointmentGroups: AppointmentGroup[] = [];

  organizations.forEach((organization) => {
    organization.appointmentGroups.forEach((appointmentGroup) =>
      appointmentGroups.push(appointmentGroup),
    );
  });

  return appointmentGroups;
};

/**
 * Handles formatting the car models
 */
export const formatCarModels = (carModels: CarModel[]): CarModel[] =>
  carModels?.map((carModel, idx) => ({ ...carModel, id: idx + 1 }));
