/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  ReSendSmsITPApiCall,
  ReSendSmsITPResponse,
} from "./ReSendSmsITP.types";

/**
 * Handles making the api call
 */
export const ReSendSmsITP: ReSendSmsITPApiCall =
  (api) => async (workOrderId, onSuccess, onError) => {
    try {
      const response = await api.post<ReSendSmsITPResponse>(
        `work-orders/${workOrderId}/resend-sms-itp`
      );

      if (response) {
        const { data } = response;

        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
