/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  DeleteOrganizationApiCall,
  DeleteOrganizationResponse
} from "./DeleteOrganization.types";

/**
 * Handles making the api call
 */
export const DeleteOrganization: DeleteOrganizationApiCall =
  (api) => async (organizationId, onSuccess, onError) => {
    try {
      const response = await api.delete<DeleteOrganizationResponse>(
        `organizations/${organizationId}`
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
