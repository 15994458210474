/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  SearchByPlateNumberApiCall,
  SearchByPlateNumberResponse
} from "./SearchByPlateNumber.types";

/**
 * Handles making the api call
 */
export const SearchByPlateNumber: SearchByPlateNumberApiCall =
  (api) => async (plateNumber, onSuccess, onError) => {
    try {
      const response = await api.get<SearchByPlateNumberResponse>(
        `/work-orders/search/plate-number?value=${plateNumber}`
      );

      if (response) {
        const { data } = response;
        onSuccess(data.data);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
