import { useEffect, useState } from "react";

/**
 * Imports hooks
 */
import {
  useFormUtils,
  useUserUtils,
  useTranslation,
  useFormContext,
  useProductUtils,
} from "..";

/**
 * Provides utility functions for products in a work order form
 */
export const useProductFormUtils = (productIndex: number) => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Initializes the product
   */
  const [isCountPrice, setIsCountPrice] = useState(true);

  /**
   * Gets form utils
   */
  const { isNumber } = useFormUtils();

  /**
   * Gets utility functions for user related data
   */
  const { getProductById } = useUserUtils();

  /**
   * Gets the form state
   */
  const { setValue, getValues } = useFormContext();

  /**
   * Gets product utils
   */
  const { getProductType, applyPriceConditions } = useProductUtils();

  /**
   * Returns the value
   */
  const getValue = (key: string) => {
    return getValues(`products.${productIndex}.${key}` as any);
  };

  /**
   * Returns the input name
   */
  const getInputName = (key: string) => {
    return `products.${productIndex}.${key}`;
  };

  /**
   * Handles updating the value
   */
  const updateValue = (key: string, value: any) => {
    return setValue(`products.${productIndex}.${key}`, value);
  };

  /**
   * Returns the product type
   */
  const getWorkOrderProductType = () => getValue("type");

  /**
   * Returns the product id
   */
  const getProductId = () => getValue("productId");

  /**
   * Returns the placeholder message of the product / service select
   */
  const getSelectPlaceholder = () => {
    const type = getWorkOrderProductType();
    if (!type) return "";

    return type === "service" ? t("SelectAService") : t("SelectAProduct");
  };

  /**
   * Handles formatting the quantity
   */
  const formatQuantity = (value: string | number) => {
    return parseInt(value?.toString() || "1");
  };

  /**
   * Handles formatting the price
   */
  const formatPrice = (value: string | number) => {
    return parseFloat(value?.toString() || "0");
  };

  /**
   * Handles updating the total when the quantity changes
   */
  const handleQuantityChange = (value: string) => {
    if (!value) return;

    const quantity = formatQuantity(value);
    const price = formatPrice(getValue("price"));
    const total = (quantity * price).toFixed(2);

    updateValue("total", total);
  };

  /**
   * Handles updating the row inputs when the product changes
   */
  const handleProductChange = (productId: number) => {
    if (productId === null) return;
    const product = getProductById(productId);

    if (product) {
      const quantity = formatQuantity(getValue("quantity"));
      const { price } = applyPriceConditions(product, getValues("tyreService"));
      const total = (quantity * price).toFixed(2);

      updateValue("quantity", quantity);
      updateValue("price", price);
      updateValue("total", total);
      updateValue("productId", productId);
      updateValue("name", product.name);

      if (product.discountOnNewTyre) {
        updateValue("discountOnNewTyre", product.discountOnNewTyre);
      }

      setIsCountPrice(product.isCountPrice);
    }
  };

  const isCustomProduct = getValue("isCustomProduct");
  const countPrice = getValue("isCountPrice");

  /**
   * Handles initializing the count price
   */
  useEffect(() => {
    if (countPrice !== undefined) {
      setIsCountPrice(countPrice);
    }
    // eslint-disable-next-line
  }, [countPrice]);

  /**
   * Handles changes of isCustomProduct and set up form data
   */
  useEffect(() => {
    if (isCustomProduct) {
      updateValue("productId", "");
      const price = getValue("price");
      if (!price) {
        updateValue("price", 0);
        setIsCountPrice(false);
      } else {
        setIsCountPrice(true);
      }
    }
    // eslint-disable-next-line
  }, [isCustomProduct]);

  /**
   * Handles updating the total when the price changes
   */
  // const handlePriceChange = (value: string, validateTabs?: boolean) => {
  //   if (!value) return;

  //   const quantity = formatQuantity(getValue("quantity"));
  //   const price = formatPrice(value);
  //   const total = (quantity * price).toFixed(2);

  //   if (validateTabs) {
  //     const workers = getValues("workers");
  //     const validPrice = isNumber(value);

  //     if (!validPrice && validatedTabs.includes(2)) {
  //       setValidatedTabs(validatedTabs.filter((t) => t !== 2));
  //     }

  //     if (validPrice && workers.length > 0 && !validatedTabs.includes(2)) {
  //       setValidatedTabs((prevTabs) => uniq([...prevTabs, 2]));
  //     }
  //   }

  //   updateValue("total", total);
  // };

  /**
   * Handles resetting any existing data of the product
   * if the type is not the same as the product
   */
  const handleTypeChange = (type: string) => {
    const productId = getValue("productId");

    if (productId) {
      const product = getProductById(productId);

      if (product) {
        if (type !== getProductType(product)) {
          updateValue("quantity", "");
          updateValue("price", "");
          updateValue("total", "");
          updateValue("productId", "");
          updateValue("priceBase", "");
          setIsCountPrice(true);
        }
      }
    }
  };

  /**
   * Handles resetting the quantity if it's invalid
   */
  const handleQuantityBlur = (value: string | number) => {
    if (!value) {
      updateValue("quantity", 1);
    }
  };

  return {
    isCountPrice,
    isNumber,
    setValue,
    getValue,
    getValues,
    updateValue,
    formatPrice,
    getInputName,
    getProductId,
    formatQuantity,
    setIsCountPrice,
    handleTypeChange,
    handleQuantityBlur,
    handleProductChange,
    getSelectPlaceholder,
    handleQuantityChange,
    // handlePriceChange,
    getWorkOrderProductType,
  };
};
