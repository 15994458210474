import { useCallback } from "react";

/**
 * Imports hooks
 */
import { useAccountSettings } from "../useAccountSettings";

export const useVat = () => {
  const { withVat, vatPercentage } = useAccountSettings();

  const calculateVat = useCallback(
    (subtotal: number, discount: number) => {
      if (withVat && vatPercentage > 0) {
        return (subtotal - discount) * (vatPercentage / 100);
      }

      return 0;
    },
    [withVat, vatPercentage],
  );

  return {
    calculateVat,
  };
};
