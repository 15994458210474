import { createSlice } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { ReduxSlice } from "../../features";
import { PayloadAction } from "@reduxjs/toolkit";
import { ClientsState } from "./clients.types";
import { Client } from "../../../types";

/**
 * Imports APIs
 */
import { searchClients } from "../../features";

/**
 * Imports utils
 */
import { toHashMap } from "../../utils";

/**
 * Defines the clients initial state
 */
const initialState: ClientsState = {
  loading: false,
  clients: [],
  clientsHashMap: {},
};

/**
 * Handles creating the a redux state slice
 */
export const clientsSlice = createSlice({
  name: ReduxSlice.Clients,
  initialState,
  reducers: {
    updateClients: (state, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
    },
    updateClientsHashMap: (state, action: PayloadAction<Client[]>) => {
      state.clientsHashMap = toHashMap(action.payload, "id");
    },
    resetClientsState: (state) => {
      state.clients = [];
      state.clientsHashMap = {};
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(searchClients.matchPending, (state) => {
      state.loading = true;
    });
    builder.addMatcher(searchClients.matchFulfilled, (state, { payload }) => {
      state.loading = false;
      state.clients = payload.data.items;
      state.clientsHashMap = toHashMap(payload.data.items, "id");
    });
  },
});

/**
 * Exports the action-creators
 */
export const clientsActionCreators = clientsSlice.actions;

/**
 * Exports the reducer
 */
export const clientsReducer = clientsSlice.reducer;
