/**
 * Imports utils
 */
import { uniqBy, orderBy } from "lodash";
import { fromSnakeToCamel } from "../useApi";

/**
 * Imports types
 */
import {
  Client,
  Product,
  Guaranty,
  HistoryReportItem,
  Timesheet
} from "../../../types";
import { ApolloQueryResult } from "@apollo/client";
import {
  GetWorkersResponse,
  GetClientsResponse,
  GetProductsResponse,
  GetTimeSheetResponse,
  CreateTimeSheetResponse,
  GetGuarantiesResponse,
  GetGuarantyResponse
} from "..";
import { GetReportHistoryResponse } from "../queries/GetReportHistory";

/**
 * Handles ordering by date
 */
const orderByDate = <T>(data: T[]) => orderBy(data, "createdAt", "desc");

/**
 * Handles ordering by name
 */
const orderByName = <T>(data: T[]) => orderBy(data, "name");

/**
 * Gets unique values by name
 */
const getUniqueByName = <T>(data: T[]) => uniqBy(data, "name");

/**
 * Handles mapping the status to a translation key
 */
const mapStatusToTransKey = (status: string) => {
  switch (status) {
    case "WORKING":
      return "Working";
    case "GONE_HOME":
      return "GoneHome";
    case "LUNCH":
      return "Lunch";
    case "BREAK":
      return "Break";
    case "ON_HOLIDAY":
      return "OnHoliday";
    case "AWAY":
      return "Away";
    default:
      return status;
  }
};

/**
 * Handles transforming the workers response
 */
export const transformWorkersResponse = (
  response: ApolloQueryResult<GetWorkersResponse>
) => {
  const { data } = response;

  return { workers: orderByName(data.workers) } as GetWorkersResponse;
};

/**
 * Handles transforming the create timesheet response
 */
export const transformCreateTimesheetResponse = (
  response: ApolloQueryResult<CreateTimeSheetResponse>
) => {
  const { data } = response;

  /**
   * Handles converting from snake to camel case
   */
  const camelCased: Timesheet = fromSnakeToCamel(data.createTimeSheet);

  /**
   * Formats and defines the createTimeSheet response
   */
  const createTimeSheet = {
    ...camelCased,
    status: data.createTimeSheet.status
  };

  return { createTimeSheet } as CreateTimeSheetResponse;
};

/**
 * Handles transforming the timesheet response
 */
export const transformTimesheetResponse = (
  response: ApolloQueryResult<GetTimeSheetResponse>
) => {
  const { data } = response;

  /**
   * Handles converting from snake to camel case
   */
  const camelCased: Timesheet[] = fromSnakeToCamel(data.timesheets);

  /**
   * Formats and defines the timesheets
   */
  const timesheets = camelCased
    .filter((t) => t.endDate === null)
    .map((timesheet) => ({
      ...timesheet,
      status: mapStatusToTransKey(timesheet.status)
    }));

  return { timesheets } as GetTimeSheetResponse;
};

/**
 * Handles transforming the clients response
 */
export const transformClientsResponse = (
  response: ApolloQueryResult<GetClientsResponse>
) => {
  const { data } = response;

  /**
   * Handles converting from snake to camel case
   */
  const camelCased: Client[] = fromSnakeToCamel(data.clients);

  /**
   * Filters out duplicated clients
   */
  const uniqueClients = getUniqueByName(camelCased);

  return { clients: orderByName(uniqueClients) } as GetClientsResponse;
};

/**
 * Handles transforming the products response
 */
export const transformProductsResponse = (
  response: ApolloQueryResult<GetProductsResponse>
) => {
  const { data } = response;

  /**
   * Handles converting from snake to camel case
   */
  const camelCased: Product[] = fromSnakeToCamel(data.products);

  return { products: orderByName(camelCased) } as GetProductsResponse;
};

/**
 * Handles transforming the report history response
 */
export const transformReportHistory = (
  response: ApolloQueryResult<GetReportHistoryResponse>
) => {
  const { data } = response;
  const { reports } = data;

  /**
   * Handles converting from snake to camel case
   */
  const camelCased: HistoryReportItem[] = (
    fromSnakeToCamel(reports) as typeof reports
  ).map((report) => {
    const stringOptions: unknown = report.options;
    const parsedOptions = fromSnakeToCamel(JSON.parse(stringOptions as string));

    return {
      id: report.id,
      accountId: report.accountId,
      organizationId: report.organizationId,
      clientName: report.clientName,
      createdBy: report.createdBy,
      createdAt: report.createdAt,
      from: report.from,
      to: report.to,
      options: {
        models: parsedOptions.models,
        orderBy: parsedOptions.orderBy,
        orderDir: parsedOptions.orderDir
      }
    };
  });

  return {
    reports: camelCased
  };
};

/**
 * Handles transforming the guaranties response
 */
export const transformGuarantiesResponse = (
  response: ApolloQueryResult<GetGuarantiesResponse>
) => {
  const { data } = response;

  /**
   * Handles converting from snake to camel case
   */
  const guaranties: Guaranty[] = fromSnakeToCamel(data.guaranties);

  return { guaranties: orderByDate(guaranties) } as GetGuarantiesResponse;
};

/**
 * Handles transforming the guaranty response
 */
export const transformGuarantyResponse = (
  response: ApolloQueryResult<GetGuarantyResponse>
) => {
  const { data } = response;

  /**
   * Handles converting from snake to camel case
   */
  const guaranty = fromSnakeToCamel(data);

  return { guaranty: guaranty.guaranty } as GetGuarantyResponse;
};
