import { useRef, useEffect } from "react";

/**
 * External Imports
 */
import { VariableSizeList } from "react-window";

/**
 * Defines the main hook
 */
export const useResetCache = (data: any) => {
  /**
   * Initializes the list ref
   */
  const ref = useRef<VariableSizeList>(null);

  /**
   * Clears cached offsets and measurements for each index for all items after (and including) the specified index.
   */
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);

  return ref;
};
