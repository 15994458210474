/**
 * Imports Material UI Components
 */
import MuiList from "@mui/material/List";
import MuiListItem from "@mui/material/ListItem";
import MuiListItemIcon from "@mui/material/ListItemIcon";
import MuiListItemText from "@mui/material/ListItemText";

/**
 * Imports styled
 */
import { styled } from "@mui/material";

/**
 * Styles the List
 */
export const List = styled(MuiList)(() => {
  return {
    "&.MuiListItem-root": {
      width: "100%"
    },
    "&.MuiListItem-dense": {
      paddingTop: 0,
      paddingBottom: 0
    }
  };
});

/**
 * Styles the List item
 */
export const ListItem = styled(MuiListItem)(() => {
  return {
    "&.MuiListItem-gutters": {
      paddingLeft: 5,
      paddingRight: 5,
      alignItems: "flex-start"
    }
  };
});

/**
 * Styles the List item icon
 */
export const ListItemIcon = styled(MuiListItemIcon)(() => {
  return {
    "&.MuiListItemIcon-root": {
      minWidth: 25,
      "& svg": {
        fontSize: "1rem",
        marginTop: 7
      }
    }
  };
});

/**
 * Styles the list item text
 */
export const ListItemText = styled(MuiListItemText)((props) => {
  const { theme } = props;

  return {
    "&.MuiListItemText-root": {
      fontSize: "inherit",
      "& .MuiTypography-root": {
        color: theme.palette.text.primary
      }
    }
  };
});
