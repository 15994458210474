/**
 * Imports components
 */
import { SmartTableCell } from "../../components/SmartTableCell";

/**
 * External imports
 */
import { isEqual } from "lodash";

/**
 * Imports hooks
 */
import { useUtils } from "../index";

/**
 * Imports constants
 */
import {
  DEFAULT_SORT_ORDER,
  DEFAULT_ORDER_BY,
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE
} from "../../constants";

/**
 * Import types
 */
import { UseTableProps } from "../useTable";
import { ComparatorKey, GetPaginatedRowsProps } from "./useTableUtils.types";
import { SortOrder } from "../../types";

/**
 * Returns utility functions to easily build filter models
 * Provides state management for filters
 */
export const useTableUtils = () => {
  /**
   * Gets utility functions
   */
  const { formatDate, isValidDate } = useUtils();

  /**
   * Handles comparing row values based on orderBy field
   */
  const descendingComparator = (a: any, b: any, orderBy: keyof any) => {
    const isADate = isValidDate(a[orderBy]);
    const isBDate = isValidDate(b[orderBy]);

    if (isADate && isBDate) {
      const aDate = new Date(a[orderBy]).getTime();
      const bDate = new Date(b[orderBy]).getTime();

      if (bDate < aDate) return -1;
      if (bDate > aDate) return 1;
      return 0;
    }

    if (b[orderBy] === null && a[orderBy]) return 1;
    if (a[orderBy] === null && b[orderBy]) return -1;
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  /**
   * Handles getting the comparator value based on sort order
   */
  const getComparator = (order: SortOrder, orderBy: keyof any) => {
    return order === "desc"
      ? (a: ComparatorKey, b: ComparatorKey) =>
          descendingComparator(a, b, orderBy)
      : (a: ComparatorKey, b: ComparatorKey) =>
          -descendingComparator(a, b, orderBy);
  };

  /**
   * Defines a stable sorting fn for cross-browser compatibility
   */
  const stableSort = (array: any[], comparator: (a: any, b: any) => number) => {
    const stableArray = array.map((el, index) => [el, index] as [any, number]);
    stableArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stableArray.map((el) => el[0]);
  };

  /**
   * Returns the rows paginated
   */
  const getPaginatedRows = (props: GetPaginatedRowsProps) => {
    const { rows, order, orderBy, page, rowsPerPage } = props;

    return stableSort(rows, getComparator(order, orderBy)).slice(
      (page - 1) * rowsPerPage,
      (page - 1) * rowsPerPage + rowsPerPage
    );
  };

  /**
   * Checks if the sorting is enabled
   */
  const isSortingEnabled = (sorting: UseTableProps["sorting"]) =>
    sorting ? sorting.enabled : false;

  /**
   * Checks if the pagination is enabled
   */
  const isPaginationEnabled = (pagination: UseTableProps["pagination"]) =>
    pagination ? pagination.enabled : false;

  /**
   * Returns the default sort order
   */
  const getDefaultSortOrder = (sorting: UseTableProps["sorting"]) => {
    if (
      !sorting ||
      !sorting.enabled ||
      !sorting.defaults ||
      !sorting.defaults.order
    )
      return DEFAULT_SORT_ORDER;

    return sorting.defaults.order;
  };

  /**
   * Returns the default orderBy
   */
  const getDefaultOrderBy = (sorting: UseTableProps["sorting"]) => {
    if (
      !sorting ||
      !sorting.enabled ||
      !sorting.defaults ||
      !sorting.defaults.orderBy
    )
      return DEFAULT_ORDER_BY;

    return sorting.defaults.orderBy;
  };

  /**
   * Returns the default page
   */
  const getDefaultPage = (pagination: UseTableProps["pagination"]) => {
    if (
      !pagination ||
      !pagination.enabled ||
      !pagination.defaults ||
      !pagination.defaults.page
    )
      return DEFAULT_PAGE;

    return pagination.defaults.page;
  };

  /**
   * Returns the default rows per page
   */
  const getDefaultRowsPerPage = (pagination: UseTableProps["pagination"]) => {
    if (
      !pagination ||
      !pagination.enabled ||
      !pagination.defaults ||
      !pagination.defaults.rowsPerPage
    )
      return DEFAULT_ROWS_PER_PAGE;

    return pagination.defaults.rowsPerPage;
  };

  /**
   * Handles generating empty cells
   */
  const generateEmptyCells = (numberOfCells: number) => {
    /**
     * Creates an array based on the number of cells
     */
    const cells: number[] = Array.from(Array(numberOfCells).keys());

    return cells.map((cell) => <SmartTableCell key={cell} />);
  };

  /**
   * Handles getting hte previous row
   */
  const getPreviousRow = (rows: any[], key?: number) => {
    return key && key > 0 ? rows[key - 1] : {};
  };

  /**
   * Handles getting the next row
   */
  const getNextRow = (rows: any[], key?: number) => {
    return key !== undefined && key >= 0 ? rows[key + 1] : {};
  };

  /**
   * Checks if the date of the current row is different from the date of the previous row
   */
  const isPreviousDateDifferent = (
    currentDate: string,
    previousDate: string
  ) => {
    if (!previousDate) return true;

    return (
      formatDate(new Date(currentDate)) !== formatDate(new Date(previousDate))
    );
  };

  /**
   * Checks if the previous row should be displayed
   */
  const shouldDisplayPrevious = (row: any, previousRow: any, key: string) => {
    if (!previousRow[key]) return true;

    return !isEqual(row[key], previousRow[key]);
  };

  /**
   * Checks if the date row should be displayed
   */
  const shouldDisplayDateRow = (row: any, previousRow: any) => {
    return isPreviousDateDifferent(row.createdAt, previousRow.createdAt);
  };

  /**
   * Handles calculating the total pages count
   */
  const calculateTotalPages = (total: number, rowsPerPage: number) => {
    return Math.ceil(total / rowsPerPage);
  };

  return {
    getComparator,
    isSortingEnabled,
    isPaginationEnabled,
    getDefaultOrderBy,
    getDefaultSortOrder,
    getDefaultPage,
    getDefaultRowsPerPage,
    generateEmptyCells,
    getPaginatedRows,
    stableSort,
    shouldDisplayDateRow,
    getPreviousRow,
    getNextRow,
    shouldDisplayPrevious,
    calculateTotalPages
  };
};
