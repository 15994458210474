/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  CreateAppointmentApiCall,
  CreateAppointmentResponse
} from "./CreateAppointment.types";

/**
 * Handles making the api call
 */
export const CreateAppointment: CreateAppointmentApiCall =
  (api) => async (body, onSuccess, onError) => {
    try {
      const response = await api.post<CreateAppointmentResponse>(
        "/appointments",
        fromCamelToSnake(body)
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
