/**
 * Imports transformers
 */
import { transformReportHistory } from "../../transformers";

/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetReportHistoryQueryVariables,
  GetReportHistoryResponse,
  GetReportHistoryApiCall
} from "./GetReportHistory.types";

/**
 * Imports queries
 */
import { getReportHistoryQuery } from "../queries";

/**
 * Handles making the api call
 */
export const GetReportHistory: GetReportHistoryApiCall =
  (api) => async (queryVariables, onSuccess, onError) => {
    try {
      const response = await api.query<
        GetReportHistoryResponse,
        GetReportHistoryQueryVariables
      >({
        query: getReportHistoryQuery,
        variables: queryVariables,
        fetchPolicy: "no-cache"
      });

      if (response) {
        onSuccess(transformReportHistory(response), response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
