import { useState, useEffect } from "react";

/**
 * External imports
 */
import { findIndex, cloneDeep } from "lodash";

/**
 * Imports hooks
 */
import {
  useForm,
  useApi,
  useTranslation,
  useProducts,
  useActions,
  useDebounce,
  useUtils,
} from "..";

/**
 * Imports types
 */
import { FormBody, UsePriceConditionsProps } from "./usePriceConditions.types";
import { Product, SelectOption, PriceCondition } from "../../types";
import {
  RequestOnError,
  CreatePriceConditionBody,
  CreatePriceConditionOnSuccess,
  UpdatePriceConditionBody,
  UpdatePriceConditionOnSuccess,
  DeletePriceConditionOnSuccess,
  GetProductOnSuccess,
} from "../../hooks/useApi";

/**
 * Provides utility functions and state management for price conditions
 */
export const usePriceConditions = (props: UsePriceConditionsProps) => {
  const { open, onClose } = props;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the work orders state
   */
  const { products, setProducts } = useProducts();

  /**
   * Initializes the product state
   */
  const [product, setProduct] = useState<Product>(props.product);

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the delete loading state
   */
  const [deleteLoading, setDeleteLoading] = useState(false);

  /**
   * Initializes the condition to edit
   */
  const [condition, setCondition] = useState<PriceCondition>();

  /**
   * Initializes the lower price conditions
   */
  const [lowerPriceConditions, setLowerPriceConditions] = useState<
    PriceCondition[]
  >([]);

  /**
   * Initializes the same price conditions
   */
  const [samePriceConditions, setSamePriceConditions] = useState<
    PriceCondition[]
  >([]);

  /**
   * Initializes the higher price conditions
   */
  const [higherPriceConditions, setHigherPriceConditions] = useState<
    PriceCondition[]
  >([]);

  /**
   * Gets general utils
   */
  const { toFloat } = useUtils();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets the default values
   */
  const getDefaultValues = {
    condition: "",
    field: "",
    newPrice: "",
    value: "",
  } as FormBody;

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Initializes the form
   */
  const methods = useForm<FormBody>({
    defaultValues: getDefaultValues,
    mode: "onChange",
    criteriaMode: "all",
  });

  /**
   * Gets the form methods
   */
  const { reset, handleSubmit, getValues } = methods;

  /**
   * Handles categorizing the price conditions
   */
  const categorizeConditions = (product: Product) => {
    const { priceConditions } = product;

    if (!priceConditions) return;

    /**
     * Initializes the lower price conditions
     */
    const lowerPriceConditions: PriceCondition[] = [];

    /**
     * Initializes the same price conditions
     */
    const samePriceConditions: PriceCondition[] = [];

    /**
     * Initializes the higher price conditions
     */
    const higherPriceConditions: PriceCondition[] = [];

    priceConditions.forEach((condition) => {
      const newPrice = toFloat(condition.newPrice);
      const standardPrice = toFloat(product.price);

      if (newPrice < standardPrice) return lowerPriceConditions.push(condition);

      if (newPrice > standardPrice)
        return higherPriceConditions.push(condition);

      samePriceConditions.push(condition);
    });

    setLowerPriceConditions(lowerPriceConditions);
    setSamePriceConditions(samePriceConditions);
    setHigherPriceConditions(higherPriceConditions);
  };

  /**
   * Handles updating the product conditions state
   */
  const updateProductConditions = () => {
    /**
     * Build the conditions array
     */
    const conditions = [
      ...lowerPriceConditions,
      ...samePriceConditions,
      ...higherPriceConditions,
    ];

    const clonedProduct = cloneDeep(product);
    const clonedProducts = cloneDeep(products);
    const productIndex = findIndex(products, { id: product.id });

    clonedProduct.priceConditions = conditions;

    if (productIndex > -1) {
      clonedProducts[productIndex] = clonedProduct;
      setProducts(clonedProducts);
    }
  };

  /**
   * Handles closing the price conditions modal
   */
  const closeModal = () => {
    updateProductConditions();
    onClose();
  };

  /**
   * Handles resetting the condition form
   */
  const resetCondition = () => {
    setCondition(undefined);
    reset({
      condition: "",
      field: "",
      newPrice: "",
      value: "",
    });
  };

  /**
   * Returns the field options
   */
  const getFieldOptions = [
    { label: t("TyreWidth"), value: "tyre_width" },
    { label: t("TyreHeight"), value: "tyre_height" },
    { label: t("TyreRim"), value: "tyre_rim" },
  ] as SelectOption[];

  /**
   * Returns the condition options
   */
  const getConditionOptions = [
    { label: t("Equals"), value: "=" },
    { label: t("LessThan"), value: "<" },
    { label: t("LessThanOrEqual"), value: "<=" },
    { label: t("BiggerThan"), value: ">" },
    { label: t("BiggerThanOrEqual"), value: ">=" },
  ] as SelectOption[];

  /**
   * Handles the api request error
   */
  const handleError: RequestOnError = () => {
    setLoading(false);
    dispatchMessage({
      message: "Error",
      severity: "error",
    });
  };

  /**
   * Handles getting the product by id
   */
  const getProductById = async (productId: number) => {
    /**
     * Handles the success of the api call
     */
    const onSuccess: GetProductOnSuccess = ({ data }) => {
      setProduct(data);
      categorizeConditions(data);
    };

    /**
     * Handles making the api call
     */
    await apiCalls.getProduct(productId, onSuccess, handleError);
  };

  /**
   * Handles creating the price condition
   */
  const createPriceCondition = async (body: CreatePriceConditionBody) => {
    setLoading(true);

    /**
     * Handles the success of the api call
     */
    const onSuccess: CreatePriceConditionOnSuccess = async ({ data }) => {
      debounce(() => {
        setLoading(false);
        reset(getDefaultValues);
        getProductById(product.id);
      }, 800);
    };

    await apiCalls.createPriceCondition(body, onSuccess, handleError);
  };

  /**
   * Handles updating the price condition
   */
  const updatePriceCondition = async (
    id: number,
    body: UpdatePriceConditionBody,
  ) => {
    setLoading(true);

    /**
     * Handles the success of the api call
     */
    const onSuccess: UpdatePriceConditionOnSuccess = () => {
      debounce(() => {
        setLoading(false);
        reset(getValues());
        getProductById(product.id);
      }, 800);
    };

    await apiCalls.updatePriceCondition(id, body, onSuccess, handleError);
  };

  /**
   * Handles deleting the currently active condition
   * Resets the form
   */
  const handleDelete = () => {
    if (condition) {
      return deletePriceCondition(condition.id, true);
    }
  };

  /**
   * Handles deleting the price condition
   */
  const deletePriceCondition = async (id: number, resetForm?: boolean) => {
    if (resetForm) {
      setDeleteLoading(true);
    }

    /**
     * Handles the success of the api call
     */
    const onSuccess: DeletePriceConditionOnSuccess = () => {
      debounce(
        () => {
          if (resetForm) reset(getValues());

          setDeleteLoading(false);
          getProductById(product.id);
          resetCondition();
        },
        resetForm ? 100 : 800,
      );
    };

    await apiCalls.deletePriceCondition(id, onSuccess, handleError);
  };

  /**
   * Handles the form submission
   */
  const onFormSubmit = handleSubmit((data: FormBody) => {
    if (condition) return updatePriceCondition(condition.id, data);
    createPriceCondition({ ...data, organizationProductId: product.id });
  });

  /**
   * Updates the form values on edit mode for a condition
   */
  const onConditionEdit = (priceCondition: PriceCondition) => {
    setCondition(priceCondition);
    reset({
      condition: priceCondition.condition,
      field: priceCondition.field,
      newPrice: priceCondition.newPrice.toString(),
      value: priceCondition.value.toString(),
    });
  };

  /**
   * Handles categorizing the price conditions
   */
  useEffect(() => {
    if (open) {
      categorizeConditions(product);
      setProduct(props.product);
      getProductById(props.product.id);
    }
    // eslint-disable-next-line
  }, [open]);

  return {
    loading,
    methods,
    condition,
    deleteLoading,
    lowerPriceConditions,
    samePriceConditions,
    higherPriceConditions,
    onConditionEdit,
    onFormSubmit,
    getConditionOptions,
    deletePriceCondition,
    getFieldOptions,
    resetCondition,
    closeModal,
    handleDelete,
  };
};
