import React, { useState, useEffect } from "react";

/**
 * Imports the graphQL client
 */
// import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

/**
 * Imports the context
 */
import { context, defaultValues, ProviderValues } from "./Context";

/**
 * Imports hooks
 */
import { useTranslation } from "react-i18next";
import { useDebounce, useLocalStorage, useActions } from "../index";

/**
 * Imports types
 */
import { Auth } from "../../types";

/**
 * Imports services
 */
import { createSocketConnection } from "../../services/socketService";

/**
 * Imports constants
 */
import { AUTH_KEY } from "../../constants";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const AuthProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the is logging out state
   */
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  /**
   * Initializes the reset user state flag
   */
  const [resetUserState, setResetUserState] = useState(false);

  /**
   * Initializes the isAuthenticated flag
   */
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  /**
   * Initializes the token
   */
  const [auth, setAuth] = useLocalStorage<Auth>(AUTH_KEY, defaultValues.auth);

  /**
   * Handles updating the auth state
   */
  const updateAuth = (auth: Auth) => {
    setAuth(auth);
  };

  /**
   * Checks if the user is authenticated
   */
  const checkAuth = (auth?: Auth) => {
    if (!auth) return false;

    const { accessToken, expiresIn, user } = auth;
    const time = new Date();

    const expiresTime = new Date();
    expiresTime.setMilliseconds(expiresIn);

    if (!user || expiresTime < time) return false;
    return accessToken.length > 0 && user.uuid.length > 0;
  };

  /**
   * Handles cleaning up state after logout
   */
  const handleCleanup = () => {
    localStorage.removeItem("liveWorkOrderFilters");
    localStorage.removeItem("liveWorkOrderFilterModels");
    localStorage.removeItem("appointmentsActiveOrg");
    localStorage.removeItem("appointmentsActiveGroups");
    localStorage.removeItem("account-deleted");

    debounce(() => {
      setAuth(defaultValues.auth);
      setResetUserState(true);
    }, 100);
  };

  /**
   * Handles logging out
   */
  const logout = (message?: string) => {
    if (message) {
      dispatchMessage({
        message: t(message),
        severity: "error",
      });
    }
    handleCleanup();
    setIsAuthenticated(false);
    setIsLoggedOut(true);
  };

  /**
   * Returns the authorization header
   */
  // const getAuthorization = () => {
  //   const authData = auth || defaultValues.auth;

  //   return `Bearer ${authData.accessToken}`;
  // };

  /**
   * Defines the apollo graphQL client
   */
  // const apollo = new ApolloClient({
  //   uri: window.origin + "/graphql",
  //   cache: new InMemoryCache(),
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: getAuthorization()
  //   }
  // });

  /**
   * Handles logging out
   */
  useEffect(() => {
    if (isLoggedOut) {
      updateAuth(defaultValues.auth);
      localStorage.removeItem(AUTH_KEY);
    }
    // eslint-disable-next-line
  }, [isLoggedOut]);

  /**
   * Updates the authenticated flag
   */
  useEffect(() => {
    const isAuthenticated = checkAuth(auth);
    setIsAuthenticated(isAuthenticated);
  }, [auth]);

  /**
   * Handles creating connection
   */
  useEffect(() => {
    createSocketConnection();
    // eslint-disable-next-line
  }, []);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    // apollo,
    auth: auth || defaultValues.auth,
    logout,
    updateAuth,
    resetUserState,
    setResetUserState,
    isAuthenticated,
    isLoggedOut,
    loading,
    setLoading,
    setIsLoggedOut,
  };

  return (
    <Provider value={providerValue}>
      {/* <ApolloProvider client={apollo}> */}
      {children}
      {/* </ApolloProvider> */}
    </Provider>
  );
};
