import { useState, useEffect } from "react";

/**
 * External imports
 */
import { uniq } from "lodash";

/**
 * Imports hooks
 */
import {
  useApi,
  useForm,
  useTabs,
  useSelector,
  useActions,
  useDebounce,
  useUserUtils,
  useTranslation,
  useProductsForm,
  useProductUtils,
  useWorkOrderUtils,
  useLocalStorage,
  useTyreDimensionsForm,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { FormBody } from "./Context";
import { SelectOption, BreadcrumbPath, WorkOrder } from "../../types";
import {
  RequestOnError,
  CreateWorkOrderDraftOnSuccess,
  CreateWorkOrderOnSuccess,
} from "../useApi";

/**
 * Imports constants
 */
import { WORKOORDER_DRAFT_KEY } from "../../constants";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const CreateWorkOrderProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);
  const { appointmentData, customerOrderData } = useSelector(
    (state) => state.workOrder,
  );
  const { dispatchMessage, updateAppointmentData, updateCustomerData } =
    useActions();

  const [workOrderDraft, setWorkOrderDraft, deleteWorkOrderDraft] =
    useLocalStorage<FormBody>(WORKOORDER_DRAFT_KEY);

  /**
   * Initializes the start date
   */
  const [startDate] = useState(new Date());

  /**
   * Initializes the loading flag
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the saving flag
   */
  const [saving, setSaving] = useState(false);

  /**
   * Initializes the draft loading flag
   */
  const [draftLoading, setDraftLoading] = useState(false);

  /**
   * Initializes the prefill hotel products flag
   */
  const [prefillHotelProducts, setPrefillHotelProducts] = useState(false);

  /**
   * Initializes the products list
   */
  const [productsList, setProductsList] = useState<SelectOption[]>([]);

  /**
   * Initializes the services list
   */
  const [servicesList, setServicesList] = useState<SelectOption[]>([]);

  /**
   * Initializes the breadcrumb paths
   */
  const [breadcrumbPaths, setBreadcrumbPaths] = useState<BreadcrumbPath[]>([]);

  /**
   * Initialzies the newly created workorder state
   */
  const [newWorkOrder, setNewWorkOrder] = useState<WorkOrder>();

  /**
   * Initializes the print workorder state
   */
  const [workOrderPrint, setWorkOrderPrint] = useState(false);

  /**
   * Initializes the data prefilled flag
   * The InputSearchWorkOrder keeps searchings when re-rendering due to the products/services tab changing the state in the context, this flag will prevent that
   */
  const [dataPrefilled, setDataPrefilled] = useState(false);

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets work order utils
   */
  const {
    getErrorTabs,
    createBreadcrumb,
    getDefaultValues,
    getWorkOrderTabs,
    getValidatedTabs,
    checkTabForErrors,
    shouldValidateTab,
    shouldInvalidateTab,
    createWorkOrderBody,
    getDefaultTabsState,
    getAppointmentValues,
    getCustomerOrderValues,
    createWorkOrderDraftBody,
  } = useWorkOrderUtils();

  /**
   * Initializes the tabs state
   */
  const {
    activeTab,
    errorTabs,
    disabledTabs,
    validatedTabs,
    activateTab,
    setActiveTab,
    setErrorTabs,
    setDisabledTabs,
    setValidatedTabs,
  } = useTabs({ defaults: getDefaultTabsState });

  /**
   * Gets products utils
   */
  const { calculatePricing, updatePricing, getSelectOptions } =
    useProductUtils();

  /**
   * Gets user utils
   */
  const { getUserProducts } = useUserUtils();

  /**
   * Gets the user products
   */
  const userProducts = getUserProducts;

  /**
   * Initializes the form
   */
  const methods = useForm<FormBody>({
    defaultValues: getDefaultValues(),
    mode: "all",
    criteriaMode: "all",
  });

  /**
   * Gets the form methods
   */
  const {
    control,
    formState,
    watch,
    reset,
    setValue,
    getValues,
    handleSubmit,
  } = methods;

  /**
   * Gets the form state
   */
  const { isDirty } = formState;

  /**
   * Gets the products state
   */
  const {
    products,
    updateRow,
    addProductRow,
    addNewProductRow,
    removeProductRow,
    updateProductRows,
  } = useProductsForm({ control, watch });

  const ownProductWatch = watch("workOrder.metaData.ownProduct");

  /**
   * Gets the tyre dimensions state
   */
  const {
    tyreDimensions,
    addNewDimensionsRow,
    removeDimensionsRow,
    replaceDimensionsRows,
  } = useTyreDimensionsForm({ control, watch });

  /**
   * Handles initializing the breadcrumb
   */
  const initializeBreadcrumb = () => {
    const baseBreadcrumb = createBreadcrumb("create");
    setBreadcrumbPaths(baseBreadcrumb);
  };

  /**
   * Handles resetting the form
   */
  const resetWorkOrder = () => {
    setActiveTab(0);
    setErrorTabs([]);
    setValidatedTabs([1]);
    setDisabledTabs([3]);
    setWorkOrderDraft(undefined);
    deleteWorkOrderDraft();
    reset(getDefaultValues());

    debounce(() => {
      setDataPrefilled(false);
    }, 100);
  };

  /**
   * Handles the tab change
   */
  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    validateTab(activeTab);
    activateTab(event, newValue);
  };

  /**
   * Handles the form submission
   */
  const onFormSubmit = handleSubmit((data: FormBody) => {
    if (userProducts.length < 1) {
      setActiveTab(2);
      return dispatchMessage({
        message: t("StillFetchingProductsPleaseTryAgain"),
        severity: "warning",
      });
    }

    if (loading) return;

    /**
     * Handles creating a work order
     */
    setLoading(true);
    createWorkOrder(data);
  });

  /**
   * Handles updating the draft in the local storage
   */
  const syncLocalStorage = () => {
    const formBody = getValues();
    const validatedTabs = getValidatedTabs(formBody);

    if (validatedTabs.length > 1) {
      setWorkOrderDraft(formBody);
    }
  };

  /**
   * Handles validating a tab
   */
  const validateTab = (index: number) => {
    const formBody = getValues();
    const shouldValidate = shouldValidateTab(index, formState, formBody);
    const shouldInvalidate = shouldInvalidateTab(index, formState, formBody);
    const hasErrors = checkTabForErrors(index, formState);

    if (shouldValidate) {
      setValidatedTabs((prevTabs) => uniq([...prevTabs, index]));
    }

    if (shouldInvalidate) {
      setValidatedTabs(validatedTabs.filter((tab) => tab !== index));
    }

    if (errorTabs.includes(index) && !hasErrors) {
      setErrorTabs(errorTabs.filter((tab) => tab !== index));
    }
  };

  /**
   * Handles creating a new work order draft
   */
  const createDraft = async () => {
    const formBody = getValues();

    /**
     * Creates the request body
     */
    const data = createWorkOrderDraftBody(formBody);

    if (data) {
      setDraftLoading(true);

      /**
       * Defines the api call success callback
       */
      const onSuccess: CreateWorkOrderDraftOnSuccess = ({ data }) => {
        debounce(() => {
          dispatchMessage({
            title: data.uuid,
            message: t("DraftCreated"),
            severity: "success",
            autoClose: 5000,
          });

          setDraftLoading(false);
          resetWorkOrder();
        }, 350);
      };

      /**
       * Defines the api call error callback
       */
      const onError: RequestOnError = () => {
        setDraftLoading(false);
        dispatchMessage({
          message: "Error",
          severity: "error",
        });
      };

      await apiCalls.createWorkOrderDraft(data, onSuccess, onError);
    }
  };

  /**
   * Handles creating a new work order
   */
  const createWorkOrder = async (formBody: FormBody) => {
    /**
     * Creates the request body
     */
    const data = createWorkOrderBody(formBody);

    /**
     * Defines the api call success callback
     */
    const onSuccess: CreateWorkOrderOnSuccess = ({ data }) => {
      resetWorkOrder();
      setLoading(false);
      setNewWorkOrder(data);
      dispatchMessage({
        title: data.uuid,
        message: t("WorkOrderCreated"),
        severity: "success",
        autoClose: 5000,
      });
    };

    /**
     * Defines the api call error callback
     */
    const onError: RequestOnError = () => {
      setLoading(false);
      dispatchMessage({
        message: "Error",
        severity: "error",
      });
    };

    await apiCalls.createWorkOrder(data, onSuccess, onError);
  };

  /**
   * Handles saving the work order locally (runs every 5 seconds)
   */
  const saveWorkOrderLocally = async () => {
    setSaving(true);
    syncLocalStorage();
    debounce(() => setSaving(false), 300);
  };

  /**
   * Gets the initial services and products
   */
  useEffect(() => {
    if (userProducts.length > 0) {
      const { services, products } = getSelectOptions(userProducts);

      setServicesList(services);
      setProductsList(products);
    }
    // eslint-disable-next-line
  }, [userProducts]);

  /**
   * Checks the tabs for errors when the active tab changes
   */
  useEffect(() => {
    setErrorTabs(getErrorTabs(formState));
    // eslint-disable-next-line
  }, [activeTab]);

  /**
   * Enabled or disables the summary tab
   */
  useEffect(() => {
    setDisabledTabs(validatedTabs.length > 2 ? [] : [3]);
    // eslint-disable-next-line
  }, [validatedTabs]);

  /**
   * Handles calculating the subtotal / discount and total based on products / services
   */
  useEffect(() => {
    if (products.length > 0) {
      const {
        subtotal,
        subtotalWithoutHotel,
        newTyreDiscountValue,
        totalDiscount,
        total,
      } = updatePricing({
        // @ts-ignore
        orderProducts: products,
        carTypeId: getValues("workOrder.carTypeId"),
        ownProduct: ownProductWatch,
      });

      setValue("workOrder.discount", totalDiscount!);
      setValue("workOrder.subtotal", subtotal);
      setValue("workOrder.metaData.subtotalWithoutHotel", subtotalWithoutHotel);
      setValue("workOrder.metaData.newTyreDiscountValue", newTyreDiscountValue);
      setValue("workOrder.total", total);
    }
    // eslint-disable-next-line
  }, [products, ownProductWatch]);

  /**
   * Handles initializing the state from local storage
   */
  useEffect(() => {
    if (userInitialized) {
      const formBody = getDefaultValues(workOrderDraft);
      reset(formBody);
      setValidatedTabs(getValidatedTabs(formBody));

      return () => {
        /**
         * Gets the default form values
         */
        const defaultValues = getDefaultValues();
        reset(defaultValues);
        setActiveTab(0);
        setValidatedTabs([1]);
        setErrorTabs([]);
        setDisabledTabs([3]);
      };
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Listens to refresh / navigating away
   */
  useEffect(() => {
    if (validatedTabs.length > 0) {
      window.addEventListener("beforeunload", syncLocalStorage);
    }

    return () => {
      syncLocalStorage();
      window.removeEventListener("beforeunload", syncLocalStorage);
    };

    // eslint-disable-next-line
  }, [validatedTabs]);

  /**
   * Initializes the breadcrumb path
   */
  useEffect(() => {
    initializeBreadcrumb();
    // eslint-disable-next-line
  }, []);

  /**
   * Handles prefilling the form with the appointment data
   */
  useEffect(() => {
    if (userInitialized && appointmentData) {
      const formBody = getAppointmentValues(appointmentData);
      reset(formBody);
      setValidatedTabs(getValidatedTabs(formBody));
      updateAppointmentData(undefined);

      // if (formBody.tyreHotelEnabled) {
      //   setPrefillHotelProducts(true);
      // }
    }
    // eslint-disable-next-line
  }, [appointmentData, userInitialized]);

  /**
   * Handles prefilling the form with the customer order data
   */
  useEffect(() => {
    if (userInitialized && customerOrderData) {
      const formBody = getCustomerOrderValues(customerOrderData);

      reset(formBody);
      setValidatedTabs(getValidatedTabs(formBody));
      updateCustomerData(undefined);
    }
    // eslint-disable-next-line
  }, [customerOrderData, userInitialized]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    methods,
    saving,
    loading,
    products,
    startDate,
    newWorkOrder,
    activeTab,
    errorTabs,
    isDirty,
    disabledTabs,
    servicesList,
    productsList,
    draftLoading,
    validatedTabs,
    workOrderPrint,
    breadcrumbPaths,
    tyreDimensions,
    dataPrefilled,
    prefillHotelProducts,
    setPrefillHotelProducts,
    updateRow,
    createDraft,
    activateTab,
    validateTab,
    setErrorTabs,
    setActiveTab,
    onFormSubmit,
    resetWorkOrder,
    handleTabChange,
    setDraftLoading,
    setDisabledTabs,
    setNewWorkOrder,
    setWorkOrderPrint,
    getWorkOrderTabs,
    setValidatedTabs,
    addNewProductRow,
    addProductRow,
    setDataPrefilled,
    addNewDimensionsRow,
    removeProductRow,
    removeDimensionsRow,
    updateProductRows,
    replaceDimensionsRows,
    saveWorkOrderLocally,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
