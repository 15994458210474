import { useState, useEffect } from "react";

/**
 * External imports
 */
import fileDownload from "js-file-download";

/**
 * Imports hooks
 */
import {
  useApi,
  useForm,
  useUtils,
  useSearch,
  useReports,
  useActions,
  useSelector,
  useDebounce,
  useTranslation,
  useFilterModelsUtils,
} from "../index";

/**
 * Imports types
 */
import { UseReportsProps } from "../useReports";
import { FormBody } from "./useAppointmentsReport.types";
import { FilterModel } from "../../types";

/**
 * Imports API types
 */
import {
  RequestOnError,
  GenerateReportsPDFBody,
  GenerateReportsPDFOnSuccess,
  GenerateReportsPDFParams,
  GenerateReportsExcelBody,
  GenerateReportsExcelOnSuccess,
  GenerateReportsExcelParams,
} from "../useApi";

/**
 * Provides state management for the AppointmentsReport component
 */
export const useAppointmentsReport = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Gets the snackbar and message dispatcher
   */
  const { dispatchSnackbar, dispatchMessage } = useActions();

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Initializes the form
   */
  const methods = useForm<FormBody>({
    defaultValues: {
      detailed: true,
    },
    mode: "onChange",
    criteriaMode: "all",
  });

  /**
   * Gets the filter model utility hook
   */
  const { formatActiveFilters, createFilter, addOrReplaceFilter } =
    useFilterModelsUtils();

  /**
   * Gets the filter models from the search provider
   */
  const { order, orderBy, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  /**
   * Defines the use reports props
   */
  const useReportsProps: UseReportsProps = {
    type: "appointment",
    format: "normal",
    order,
    orderBy,
  };

  /**
   * Gets the reports hook data
   */
  const {
    loading,
    report,
    triggerSearch,
    setTriggerSearch,
    generateReport,
    setReport,
    setLoading,
    deleteFilter,
    resetFilters,
  } = useReports(useReportsProps);

  /**
   * Gets utility functions
   */
  const { formatDate } = useUtils();

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Initializes the models initialized flag
   */
  const [modelsInitialized, setModelsInitialized] = useState(false);

  /**
   * Handles creating a report based on search value
   */
  const handleSearch = (searchValue: string) => {
    /**
     * Defines the search filter model
     */
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });

    /**
     * Updates the active filters
     */
    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    generateReport(filterModels, {
      reportType: "appointment",
      reportFormat: "normal",
    });
    setActiveFilters(filterModels);
  };

  /**
   * Defines the api call error callback
   */
  const onRequestPdfError: RequestOnError = (error) => {
    dispatchSnackbar({
      title: t("AppointmentsReportPdf"),
      description: t("GeneratePdfReportError"),
      variant: "error",
      closeAfter: 7000,
    });
  };

  /**
   * Defines the api call error callback
   */
  const onRequestExcelError: RequestOnError = (error) => {
    dispatchSnackbar({
      title: t("AppointmentsReportExcel"),
      description: t("GenerateExcelReportError"),
      variant: "error",
      closeAfter: 7000,
    });
  };

  /**
   * Handles generating and downloading the pdf report
   */
  const downloadPdfReport = async (includeServices?: boolean) => {
    dispatchSnackbar({
      title: t("AppointmentsReportPdf"),
      description: t("GenerateAppointmentsReportPdf"),
      variant: "loading",
    });

    /**
     * Defines the request body
     */
    const reqBody: GenerateReportsPDFBody = {
      models: activeFilters,
      order_by: orderBy,
      order_dir: order,
    };

    /**
     * Defines the params
     */
    const params: GenerateReportsPDFParams = {
      type: "appointment",
      format: "normal",
      interval: "custom",
    };

    /**
     * Includes services in the PDF report
     */
    if (includeServices) {
      reqBody.with_services = true;
    }

    /**
     * Defines the api call success callback
     */
    const onSuccess: GenerateReportsPDFOnSuccess = (data, response) => {
      const fileName = `appointments_report_${formatDate(
        new Date(),
        "yyyy-MM-dd",
      )}.pdf`;

      fileDownload(data, fileName);

      dispatchSnackbar({
        title: t("AppointmentsReportPdf"),
        description: t("GenerateAppointmentsReportSuccess"),
        variant: "success",
        closeAfter: 5000,
      });
    };

    await apiCalls.generateReportsPDF(
      params,
      reqBody,
      onSuccess,
      onRequestPdfError,
    );
  };

  /**
   * Handles generating and downloading the excel report
   */
  const downloadExcelReport = async () => {
    dispatchSnackbar({
      title: t("AppointmentsReportExcel"),
      description: t("GenerateAppointmentsReportExcel"),
      variant: "loading",
    });

    /**
     * Defines the request body
     */
    const reqBody: GenerateReportsExcelBody = {
      models: activeFilters,
      order_by: orderBy,
      order_dir: order,
    };

    /**
     * Defines the params
     */
    const params: GenerateReportsExcelParams = {
      type: "appointment",
      format: "normal",
      interval: "custom",
    };

    /**
     * Defines the api call success callback
     */
    const onSuccess: GenerateReportsExcelOnSuccess = (data, response) => {
      const fileName = `appointments_report_${formatDate(
        new Date(),
        "yyyy-MM-dd",
      )}.xls`;

      fileDownload(data, fileName);

      dispatchSnackbar({
        title: t("AppointmentsReportExcel"),
        description: t("GenerateAppointmentsReportExcelSuccess"),
        variant: "success",
        closeAfter: 5000,
      });
    };

    await apiCalls.generateReportsExcel(
      params,
      reqBody,
      onSuccess,
      onRequestExcelError,
    );
  };

  /**
   * Handles submitting the filters modal form
   */
  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      generateReport(filters, {
        reportType: "appointment",
        reportFormat: "normal",
      });
    }, 500);
  };

  /**
   * Gets the default date range filter model value
   */
  const getDefaultDateRange = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() - 1;

    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    return [formatDate(startDate), formatDate(endDate)];
  };

  /**
   * Handles initializing the filter models
   */
  const initializeFilterModels = () => {
    /**
     * Initializes the filter models
     */
    const models: FilterModel[] = [];

    /**
     * Defines the date range filter model
     */
    const dateRangeFilterModel = createFilter({
      field: "from",
      selected: getDefaultDateRange(),
      type: "range",
    });

    models.push(dateRangeFilterModel);

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  /**
   * Handles getting the time interval for the report
   */
  const getReportTimeInterval = () => {
    const dateFilter = activeFilters.find((filter) => filter.field === "from");

    /**
     * Defines the date format
     */
    const format = "yyyy.MM.dd";

    return {
      startDate: formatDate(
        dateFilter ? dateFilter.selected[0] : new Date(),
        format,
      ),
      endDate: formatDate(
        dateFilter ? dateFilter.selected[1] : new Date(),
        format,
      ),
    };
  };

  /**
   * Handles triggering a search after filter deletion
   */
  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
      if (activeFilters.length < 1) {
        setReport(undefined);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
      } else {
        generateReport(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Initializes the filter models
   */
  useEffect(() => {
    if (userInitialized) initializeFilterModels();
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles generating the initial report based on default filters
   */
  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        generateReport(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  return {
    report,
    loading,
    methods,
    activeFilters,
    modelsInitialized,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    formatActiveFilters,
    getReportTimeInterval,
    downloadPdfReport,
    downloadExcelReport,
  };
};
