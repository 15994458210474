/**
 * Imports components
 */
import { Transition } from "../Transition";

/**
 * Imports types
 */
import { TypographyProps } from "./Typography.types";

/**
 * Imports hooks
 */
import { useUtils } from "../../hooks";

/**
 * Imports styled components
 */
import { StyledTypography } from "./Typography.styles";

/**
 * Displays the component`
 */
export const Typography: React.FC<TypographyProps> = (props) => {
  const {
    variant,
    className,
    weight,
    color,
    gutterBottom,
    transition,
    children,
    label,
    italic,
    sx,
    size,
    title,
    limit,
    onClick
  } = props;

  /**
   * Gets utils
   */
  const { truncateString } = useUtils();

  /**
   * Handles mapping the variant to Mui variant
   */
  const mapVariantToMui = () => (variant === "p" ? undefined : variant);

  /**
   * Returns the text to be displayed
   */
  const getTextDisplay = () => {
    if (label) {
      if (limit) return truncateString(label, limit);
      return label;
    }

    if (typeof children === "string") {
      if (limit) return truncateString(children, limit);

      return children;
    }

    return children;
  };

  /**
   * Returns the title
   * Used to show the full value of the text string if the display is truncated
   */
  const getTitle = () => {
    if (title) return title;

    if (limit) {
      if (label && label.length > limit) return label;
      if (typeof children === "string" && children.length > limit)
        return children;
    }

    return "";
  };

  /**
   * Handles rendering the typography component
   */
  const renderTypography = () => (
    <StyledTypography
      sx={sx}
      size={size}
      italic={italic}
      onClick={onClick}
      variant={mapVariantToMui()}
      displayVariant={variant}
      themeColor={color}
      weight={weight}
      title={getTitle()}
      className={className || "Typography-root"}
      gutterBottom={gutterBottom}
    >
      {getTextDisplay()}
    </StyledTypography>
  );

  if (transition) {
    return <Transition {...transition}>{renderTypography()}</Transition>;
  }

  return renderTypography();
};
