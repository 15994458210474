import { useEffect, forwardRef, useMemo } from "react";

/**
 * Imports components
 */
import { Typography } from "../Typography";

/**
 * Imports react feather icons
 */
import { X, CheckCircle, AlertCircle } from "react-feather";

/**
 * Imports styled components
 */
import {
  Snackbar,
  MessageBox,
  ProgressContainer,
} from "./SnackbarPopup.styles";

/**
 * Imports MUI Components
 */
import { Zoom, CircularProgress, IconButton } from "@mui/material";

/**
 * Imports hooks
 */
import { useSelector, useActions } from "../../hooks";

/**
 * Imports types
 */
import { TransitionProps } from "@mui/material/transitions";

/**
 * Defines the zoom transition
 */
const ZoomTransition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Zoom in ref={ref} {...props} />;
});

/**
 * Displays the component
 */
export const SnackbarPopup: React.FC = () => {
  /**
   * Gets the snackbar state
   */
  const { open, config } = useSelector((state) => state.snackbar);

  /**
   * Gets the snackbar actions
   */
  const { resetSnackbar } = useActions();

  /**
   * Gets the config
   */
  const { title, variant, description, closeAfter } = config;

  /**
   * Renders the Snackbar icon
   */
  const renderSnackbarIcon = useMemo(() => {
    if (variant === "loading") return <CircularProgress size={22} />;
    if (variant === "success")
      return <CheckCircle className="FeatherIcon" size={22} />;
    if (variant === "error")
      return <AlertCircle className="FeatherIcon" size={22} />;
  }, [variant]);

  /**
   * Handles closing the snackbar
   */
  const closeSnackbar = () => resetSnackbar();

  /**
   * Handles rendering the snackbar content
   */
  const renderSnackbarContent = () => (
    <MessageBox>
      <ProgressContainer>
        {renderSnackbarIcon}
        <div className="InfoContainer">
          <Typography
            color="common.white"
            variant="caption"
            size={14}
            weight={500}
            className="InfoContainer-title"
          >
            {title}
          </Typography>
          <Typography
            color="common.white"
            variant="caption"
            size={12}
            weight={500}
          >
            {description}
          </Typography>
        </div>
      </ProgressContainer>
      <IconButton onClick={closeSnackbar}>
        <X size={20} />
      </IconButton>
    </MessageBox>
  );

  /**
   * Handles resetting the snackbar
   */
  useEffect(() => {
    if (open && closeAfter) {
      let timer: NodeJS.Timeout;
      timer = setTimeout(() => resetSnackbar(), closeAfter);

      return () => clearTimeout(timer);
    }
  }, [open, closeAfter, resetSnackbar]);

  return (
    <Snackbar
      type={variant}
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transitionDuration={250}
      TransitionComponent={ZoomTransition}
      onClose={closeSnackbar}
      message={renderSnackbarContent()}
    />
  );
};
