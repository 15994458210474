/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  UpdateOrganizationBody,
  UpdateOrganizationApiCall,
  UpdateOrganizationResponse,
} from "./UpdateOrganization.types";

/**
 * Transforms the request body
 * All occurances of timesheet are written like so, but the api call
 * requires it to be time_sheet, therefore we need to transform the request body.
 */
const transformRequestBody = (body: UpdateOrganizationBody) => {
  return fromCamelToSnake({
    ...body,
    timeSheet: body.timeSheet,
  }) as UpdateOrganizationBody;
};

/**
 * Handles making the api call
 */
export const UpdateOrganization: UpdateOrganizationApiCall =
  (api) => async (organizationId, body, onSuccess, onError) => {
    try {
      const response = await api.put<UpdateOrganizationResponse>(
        `/organizations/${organizationId}`,
        transformRequestBody(body),
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
