/**
 * Imports transformers
 */
import { transformWorkersResponse } from "../../transformers";

/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import { GetWorkersResponse, GetWorkersApiCall } from "./GetWorkers.types";

/**
 * Imports queries
 */
import { getWorkersQuery } from "../queries";

/**
 * Handles making the api call
 */
export const GetWorkers: GetWorkersApiCall =
  (api) => async (onSuccess, onError) => {
    try {
      const response = await api.query<GetWorkersResponse>({
        query: getWorkersQuery
      });

      if (response) {
        onSuccess(transformWorkersResponse(response), response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
