/**
 * Imports Material UI components
 */
import { Typography as MuiTypography } from "@mui/material";

/**
 * Imports types
 */
import {
  StyledTypographyProps,
  StyledTypographyVariants
} from "./Typography.types";

/**
 * Imports styled
 */
import { styled } from "@mui/material";

/**
 * Defines the styled options
 */
const StyledTypographyOptions = {
  shouldForwardProp: (propName: string) =>
    propName !== "displayVariant" &&
    propName !== "themeColor" &&
    propName !== "size" &&
    propName !== "italic"
};

/**
 * Styles the Typography component
 */
export const StyledTypography = styled(
  MuiTypography,
  StyledTypographyOptions
)<StyledTypographyProps>((props) => {
  const { theme, size, italic, displayVariant, weight, themeColor } = props;

  let typographyColor = theme.palette.base.text;

  if (themeColor) {
    if (themeColor.includes(".")) {
      const palette = themeColor.split(".")[0];
      const subPalette = themeColor.split(".")[1];

      typographyColor = (theme.palette as any)[palette][subPalette];
    } else {
      switch (themeColor) {
        case "inherit":
          typographyColor = "inherit";
          break;
        case "secondary":
          typographyColor = theme.palette.secondary.main;
          break;
        case "success":
          typographyColor = theme.palette.success.main;
          break;
        case "error":
          typographyColor = theme.palette.error.main;
          break;
        default:
          typographyColor = theme.palette.primary.main;
          break;
      }
    }
  }

  /**
   * Defines the button variant styling
   */
  const variants: StyledTypographyVariants = {
    h1: {
      fontSize: size || 61,
      fontWeight: weight || 700,
      [theme.breakpoints.down("md")]: {
        fontSize: " 35px !important"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: " 35px !important"
      }
    },
    h2: {
      fontSize: size || 49,
      fontWeight: weight || 600,
      [theme.breakpoints.down("md")]: {
        fontSize: " 31px !important"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: " 31px !important"
      }
    },
    h3: {
      fontSize: size || 39,
      fontWeight: weight || 600,
      [theme.breakpoints.down("md")]: {
        fontSize: " 24px !important"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: " 24px !important"
      }
    },
    h4: {
      fontSize: size || 31,
      fontWeight: weight || 600,
      [theme.breakpoints.down("md")]: {
        fontSize: " 21px !important"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: " 21px !important"
      }
    },
    h5: {
      fontSize: size || 25,
      fontWeight: weight || 700,
      [theme.breakpoints.down("md")]: {
        fontSize: " 18px !important"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: " 18px !important"
      }
    },
    h6: {
      fontSize: size || 20,
      fontWeight: weight || 700,
      [theme.breakpoints.down("md")]: {
        fontSize: " 14px !important"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: " 14px !important"
      }
    },
    p: {
      fontSize: size || 16,
      fontWeight: weight || 400,
      [theme.breakpoints.down("md")]: {
        fontSize: " 14px !important"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: " 14px !important"
      }
    },
    caption: {
      fontSize: size || 12,
      fontWeight: weight || 400,
      lineHeight: 1.2
    }
  };

  return {
    fontFamily: theme.typography.fontFamily,
    color: typographyColor,
    ...variants[displayVariant],
    ...(italic && { fontStyle: "italic" })
  };
});
