import { useState, useEffect } from "react";

/**
 * External imports
 */
import { format, subDays } from "date-fns";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports hooks
 */
import {
  useApi,
  useSearch,
  useActions,
  useDebounce,
  useSelector,
  useTranslation,
  useFilterModelsUtils,
} from "../index";

/**
 * Imports constants
 */

/**
 * Imports types
 */
import { FilterModel } from "../index";
import { TableData, TimesheetReport, TimesheetReportItem } from "../../types";
import { MetadataColumn } from "../../components/ReportMetadata";

/**
 * Imports API types
 */
import {
  RequestOnError,
  GetTimesheetReportsBody,
  GetTimesheetReportsOnSuccess,
} from "../index";
import { upperFirst } from "lodash";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const TimesheetReportsProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the translations
   */
  const { t } = useTranslation();

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets the account state
   */
  const { userInitialized, accountInformation } = useSelector(
    (state) => state.account,
  );

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets filter model utils
   */
  const {
    createFilter,
    removeFilter,
    addOrReplaceFilter,
    formatActiveFilters,
  } = useFilterModelsUtils();

  /**
   * Gets the filter models from the search provider
   */
  const { defaultFilters, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  /**
   * Initializes the trigger search flag
   */
  const [triggerSearch, setTriggerSearch] = useState(false);

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(true);

  /**
   * Initializes the table rows loading
   */
  const [tableRowsLoading, setTableRowsLoading] = useState(false);

  /**
   * Initializes the total user reports state
   */
  const [totalUserReports, setTotalUserReports] = useState(0);

  /**
   * Initializes the models initialized flag
   */
  const [modelsInitialized, setModelsInitialized] = useState(false);

  /**
   * Initializes the page count
   */
  const [pageCount, setPageCount] = useState(1);

  /**
   * Initializes the order by state
   */
  const [orderBy, setOrderBy] = useState("start_date");

  /**
   * Initializes the order dir state
   */
  const [orderDir, setOrderDir] = useState<"asc" | "desc">("asc");

  /**
   * Initializes the user timesheet reports state
   */
  const [userReports, setUserReports] = useState<TimesheetReportItem[]>([]);

  /**
   * Initializes the users timesheet totalization state
   */
  const [timesheetTotal, setTimesheetTotal] = useState<MetadataColumn[]>([]);

  /**
   * Defines the api call error callback
   */
  const onRequestError: RequestOnError = (error) => {
    dispatchMessage({
      message: error.errorMessage ? error.errorMessage : "Unknown Error",
      severity: "error",
      autoClose: 10000,
    });

    /**
     * Updates the loading state
     */
    setLoading(false);
    setTableRowsLoading(false);
  };

  /**
   * Handles building the timesheet totalization array
   */
  const buildTimesheetTotalization = (data: TimesheetReport) => {
    const { total } = data;

    /**
     * Gets the rows
     */
    const rows = Object.entries(total).map(([label, value]) => ({
      label,
      value,
    }));

    /**
     * Rebuilds the labels and keys
     */
    const updatedRows = rows.map((row) => {
      /**
       * Capitalizes the label
       */
      const label = t(upperFirst(row.label));

      /**
       * Transforms the value
       */
      const value = new Date(row.value * 1000).toISOString().slice(11, 19);

      return {
        label,
        value,
      };
    });

    /**
     * Builds the column
     */
    const column: MetadataColumn[] = [
      {
        id: "timesheet-total",
        rows: updatedRows,
      },
    ];

    return column;
  };

  /**
   * Handles initializing the filter models
   */
  const initializeFilterModels = () => {
    /**
     * Initializes the filter models
     */
    const models: FilterModel[] = [];

    /**
     * Gets the selected start date by substracting a week
     */
    const selectedDate = format(subDays(new Date(), 7), "yyy-MM-dd");

    if (accountInformation) {
      /**
       * Creates model for start date
       */
      models.push(
        createFilter({
          field: "start_date",
          selected: selectedDate,
          type: "gte",
        }),
      );

      /**
       * Created model from account id
       */
      models.push(
        createFilter({
          field: "account_id",
          selected: accountInformation.id,
          type: "dropdown",
        }),
      );
    }

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  /**
   * Handles getting the timesheet reports
   */
  const getTimesheetReports = async (filters: FilterModel[], page?: number) => {
    /**
     * Updates the loading state
     */
    setLoading(true);

    /**
     * Defines the request body
     */
    const reqBody: GetTimesheetReportsBody = {
      models: filters.filter((model) => !model.displayOnly),
      order_by: orderBy,
      order_dir: orderDir,
      // page_count: page || pageCount,
      // page_size: 15
    };

    /**
     * Defines the success of the api call
     */
    const onSuccess: GetTimesheetReportsOnSuccess = (response) => {
      if (response && response.data) {
        const { data } = response;

        /**
         * Gets the user reports
         */
        const userReports = data.items;

        /**
         * Gets the total user reports
         */
        const totalUserReports = userReports.length;

        /**
         * Builds the timesheet totalization
         */
        const timesheetTotal = buildTimesheetTotalization(data);

        /**
         * Sets the state
         */
        setTotalUserReports(totalUserReports);
        setTimesheetTotal(timesheetTotal);
        setUserReports(userReports);
        setTableRowsLoading(false);
        setLoading(false);
      }
    };

    await apiCalls.getTimesheetReports(reqBody, onSuccess, onRequestError);
  };

  /**
   * Handles creating a report based on search value
   */
  const handleSearch = (searchValue: string) => {
    /**
     * Defines the search filter model
     */
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });

    /**
     * Updates the active filters
     */
    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    setLoading(true);
    getTimesheetReports(filterModels, 1);
    setActiveFilters(filterModels);
  };

  /**
   * Handles submitting the filters modal form
   */
  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      getTimesheetReports(filters, 1);
    }, 500);
  };

  /**
   * Handles deleting a filter
   */
  const deleteFilter = (filter: FilterModel) => {
    const updatedFilters = removeFilter(filter, activeFilters);
    setActiveFilters(updatedFilters);

    debounce(() => {
      setPageCount(1);
      setLoading(true);
      setTriggerSearch(true);
    }, 1500);
  };

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    setLoading(true);
    setActiveFilters(defaultFilters);
    setPageCount(1);

    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  /**
   * Handles sorting, updates states
   */
  const handleSort = (e: React.MouseEvent<any>, property: keyof TableData) => {
    const isAsc = orderBy === property && orderDir === "asc";

    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property as string);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Handles updating the current page state
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPageCount(page);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Initializes the filter models
   */
  useEffect(() => {
    if (userInitialized) {
      initializeFilterModels();
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles searching for workers once filter models are set
   */
  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        getTimesheetReports(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  /**
   * Handles triggering a search after filter deletion
   */
  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);

      if (activeFilters.length < 1) {
        setLoading(false);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
        initializeFilterModels();
      } else {
        getTimesheetReports(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    loading,
    orderBy,
    orderDir,
    pageCount,
    userReports,
    activeFilters,
    timesheetTotal,
    totalUserReports,
    tableRowsLoading,
    modelsInitialized,
    setPageCount,
    setUserReports,
    setTotalUserReports,
    handleSort,
    handleSearch,
    handleSubmit,
    deleteFilter,
    resetFilters,
    handlePageChange,
    formatActiveFilters,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
